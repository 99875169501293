import {
    Divider, Text
} from '@chakra-ui/react';
import React from 'react';

import { isDefined } from '../../../../helpers/utils';

interface MidMilestoneScreenProps {
    hasMilestoneSummary?: boolean
    header?: string
    header2?: string
    milestoneDescription?: string
    milestoneHeader?: string
    nextStageLabel?: string
    noPointsEarnTxt?: string
    numMilestoneQuestions?: number
    questionsTxt?: string
    questName?: string
    totalMilestonePoints?: number
    totalPointsTxt?: string
}

export const MidMilestoneScreen = ({
    hasMilestoneSummary,
    header,
    header2,
    milestoneDescription,
    milestoneHeader,
    nextStageLabel,
    noPointsEarnTxt,
    numMilestoneQuestions,
    questionsTxt,
    questName,
    totalMilestonePoints,
    totalPointsTxt
}: MidMilestoneScreenProps) => (
    <>
        <div className={'header-tab'} data-testid={'header-tab'}>
            <div className={'uow-topic'}>{questName}</div>
        </div>
        <div className={'message-content'}>
            <div className={'message-body'}>
                {(isDefined(header)) &&
                    <div className={'header'} data-testid={'header'}>{header}</div>
                }
                <div className={'header'} data-testid={'header2'}>{header2}</div>
                {nextStageLabel && <div className={'next-stage'}>{nextStageLabel}</div>}
                <div className={'milestone-header'}>{milestoneHeader}</div>
                <div className={'milestone-description'}>{milestoneDescription}</div>
                {hasMilestoneSummary &&
                <div className={'milestone-summary'}>
                    <div className={'num-stack'}>
                        <div className={'num-stack_content'} data-testid={'questions'}>
                            <div className={'num-label'}>{numMilestoneQuestions}</div>
                            <div className={'num-description'}>{questionsTxt}</div>
                        </div>
                    </div>
                    <Divider
                        borderColor={'neutral.100'}
                        h={'calc(100% - 2 * 20px)'}
                        my={'20px'}
                        opacity={1}
                        orientation={'vertical'}
                    />
                    <div className={'num-stack'}>
                        <div className={'num-stack_content'} data-testid={'total-points'}>
                            <div className={'num-label'}>{totalMilestonePoints}</div>
                            <div className={'num-description'}>{totalPointsTxt}</div>
                        </div>
                    </div>
                </div>
                }
                {noPointsEarnTxt &&
                    <Text color={'primary.600'} fontSize={18} mb={'4px'}>{noPointsEarnTxt}</Text>
                }
            </div>
        </div>
    </>
);
