/* eslint-disable @typescript-eslint/naming-convention */
import { ButtonProps } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';

export const Button = {
    baseStyle: ({ theme }: StyleFunctionProps) => ({
        boxSizing: 'border-box',
        _focus: { boxShadow: `0 0 0 3px ${theme.colors.primary['500']};` }
    }),
    variants: {
        solid: (props: StyleFunctionProps) => {
            const {
                colorScheme,
                theme
            } = props;

            let overrides: ButtonProps = {};

            if (colorScheme === 'primary') {
                overrides = {
                    _active: { bg: 'linear-gradient(98.39deg, #0063A7 0%, #01437D 100%)' },
                    _hover: { bg: 'linear-gradient(102.49deg, #007ACC 0%, #014B8B 100%)' },
                    bg: 'linear-gradient(103.65deg, #00ABE8 -2.03%, #007ACC 43.7%, #0063A7 105.46%)'
                };
            } else if (colorScheme === 'correct') {
                overrides = {
                    _hover: { bg: 'linear-gradient(134.98deg, #148235 0%, #024C1F 100%)' },
                    bg: 'linear-gradient(135.76deg, #29B539 0%, #148235 76.36%, #0D6830 159.36%)'
                };
            } else if (colorScheme === 'incorrect') {
                overrides = {
                    _hover: { bg: 'linear-gradient(137.41deg, #DC501F 0%, #AE2F07 104.34%)' },
                    bg: 'linear-gradient(134.14deg, #FA7E46 0%, #CC461B 101.55%)'
                };
            } else {
                overrides = {
                    bg: theme.app.whiteAlpha800,
                    border: `1px solid ${theme.app.whiteAlpha800}`
                };
            }
            const disabledOverrides = {
                bg: theme.app.neutral100,
                color: theme.app.fontGrey01,
                opacity: 1
            };

            overrides = {
                ...overrides,
                _disabled: disabledOverrides,
                _hover: {
                    ...overrides._hover,
                    _disabled: disabledOverrides
                }
            };

            return {
                _disabled: disabledOverrides,
                fontWeight: 'normal',
                ...overrides
            };
        }
    }
};
