// eslint-disable-next-line  @typescript-eslint/naming-convention
export enum PROGRESS_STATE {
    fluency,
    reasoning,
    problemSolving
}

export enum MilestoneState {
    notStarted,
    inProgress,
    completed
}

// eslint-disable-next-line  @typescript-eslint/naming-convention
export enum STUDENT_STATES {
    intro = 'intro',
    fluencyIntro = 'fluencyIntro',
    progress = 'progress',
    question = 'question',
    resume = 'resume',
    summary = 'summary'
}

export enum TransitionScreen {
    startScreen,
    resumeScreen,
    startFluency,
    startReasoning,
    startProblemSolving,
    summaryScreen,
    finishScreen,
    exceededAttemptsFinishScreen,
    feedbackScreen,
    diagnosticsStartScreen,
    diagnosticsSummaryScreen,
    unitTestIntroScreen,
    unitTestSummaryScreen
}

export enum QuestionSetType {
    questiconActivity,
    diagnostics,
    unitTest
}

export enum QuestionProgressState {
    notCurrent = 'notCurrent',
    nextUnanswered = 'nextUnanswered',
    resetUnanswered = 'resetUnanswered',
    correctAnswered = 'correctAnswered',
    incorrectAnswered = 'incorrectAnswered'
}

export enum ExitType {
    normalProgress,
    normalExit,
    fluencyExit
}

export enum ServerErrorType {
    other,
    timeout
}
