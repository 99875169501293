/**
 * A basic theme for the application.
 *
 * @see https://emotion.sh/docs/theming
 */
import {
    defaultTheme, ThemeProps as qclThemeProps
} from '@3plearning/question-components-library';
import { mergeThemeOverride } from '@chakra-ui/react';

import {
    Button, Container, Modal, Progress, Tooltip, Popover
} from './components';
import {
    flex, FlexMixins
} from './flex-mixins';
import {
    mixins, Mixins
} from './mixins';
import {
    typography, Typography
} from './typography';
import {
    app, ThemeVariables
} from './variables';

export const APP_THEME = {
    typography,
    app,
    flex,
    mixins
};

export const appTheme = mergeThemeOverride(
    defaultTheme,
    APP_THEME,
    {
        breakpoints: {
            tabletLandscape: app.breakTabletLandscape,
            breakPoint: app.breakPoint,
            phonePortrait: app.breakTabletPortraitPhone,
            break680: app.break680,
            phoneLandscape: app.phoneLandscape,
            break1270: app.break1270
        },
        components: {
            Button,
            Container,
            Modal,
            Progress,
            Tooltip,
            Popover
        }
    }
);

export type AppThemeType =
    qclThemeProps
    & { app: ThemeVariables }
    & { flex: FlexMixins }
    & { mixins: Mixins }
    & { typography: Typography };

export type ThemeProps = { theme: AppThemeType };

// Alias for use in @emotion/styled to pick an app theme var
export const pickThemeVar = (
    key: keyof ThemeProps['theme']['app']
) => (props: ThemeProps) => {
    return props.theme.app[key];
};

export const pickThemeFlex = (
    key: keyof ThemeProps['theme']['flex']
) => (props: ThemeProps) => {
    return props.theme.flex[key];
};

