/* istanbul ignore file */
/*
* There are 2 lottie animation types - half star and full star.
* There are up to 3 gold Rating Stars to display depending on the number of total stars earned,
* after answering the total number of questions. They increment by half a star form half to 3 stars. Even a zero score
* will earn half a star.
*
* Refer to the summary > util > utils.ts for the hard coded values.
*
* There is a Grey Rating Star at each of the 3 positions.
* The 2 lottie animation files are positioned together starting at position 1 above the grey Rating Star.
* They both begin with opacity = 0 so the grey Rating Star underneath is visible. Only one of the animations will
* appear and then play at any one position.
*
* At each position only one of these 2 animations may run as a starburst animation.
* On completion of the animation currently being played, the animations are moved to the next position if required. The
* showStarType function is also triggered so a static svg appears beneath the completed position by affecting
* the styling of the relevant ProgressStar component.
*
* After the last animation completes, the lottie animations are destroyed.
* */
import {
    Circle, HStack
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useLottie } from 'lottie-react';
import React, {
    useCallback,
    useEffect, useState
} from 'react';

import FullStar from '../../../../../../../assets/lottie/StarBurstFull.json';
import HalfStar from '../../../../../../../assets/lottie/StarBurstHalf.json';
import { useWindowSize } from '../../../../../../hooks/use-window-size';
import { app } from '../../../../../../styles/theme/variables';
import {
    getStarRatingNum, getStarType
} from '../../utils/util';
import { StarRatingType } from '../progress-bar/progress-bar.styled';
import {
    AnimProps, ProgressStarAnimProps
} from './star-rating.types';

const ProgressStarAnimStyled = styled.div<AnimProps>((props) => {
    const {
        type, showStarType
    } = props;
    const opacity = type && showStarType && type === showStarType ? 1 : 0;

    return css`
      opacity: ${opacity};
      position: absolute;

      svg {
        width: 210px !important;
        height: unset !important;
      }

      @media (min-width: ${app.break1270}) {
        svg {
          width: 285px !important;
        }
      }
    `;
});

export const ProgressStarAnim = ({
    totalStars, setPlayStarNum
}: ProgressStarAnimProps) => {
    const [leftPos, setLeftPos] = useState<string>();
    const [showStarType, setShowStarType] = useState<StarRatingType>(undefined);
    const [animCycleNum, setAnimCycleNum] = useState<number>(1);
    const {
        numRatingStars, numRatingStarAnims
    } = getStarRatingNum(totalStars);
    const completeCycleCallback = () => {
        setAnimCycleNum(animCycleNum => animCycleNum + 1);
    };
    const baseOptions = {
        loop: false,
        autoplay: false
    };
    const fullStarOptions = {
        // workaround for lottie-react bug https://github.com/airbnb/lottie-web/issues/1702#issuecomment-508553873
        animationData: JSON.parse(JSON.stringify(FullStar)),
        onComplete: completeCycleCallback,
        ...baseOptions
    };
    const halfStarOptions = {
        animationData: JSON.parse(JSON.stringify(HalfStar)),
        onComplete: completeCycleCallback,
        ...baseOptions
    };
    const fullStarLottie = useLottie(fullStarOptions);
    const halfStarLottie = useLottie(halfStarOptions);
    const { viewWidth } = useWindowSize();
    const isXLWindow = viewWidth > parseInt(app.break1270);
    const isMDWindow = viewWidth > parseInt(app.breakTabletPortrait);
    const isBreak680window = viewWidth > parseInt(app.break680);
    const setNextPos = useCallback((starNum: number) => {
        let pos = starNum === 2 ? '87px' : '175px';

        if (starNum === 2) {
            if (isXLWindow) {
                pos = '115px';
            } else if (isMDWindow) {
                pos = '108px';
            } else if (isBreak680window) {
                pos = '126px';
            }
        } else {
            if (isXLWindow) {
                pos = '205px';
            } else if (isMDWindow) {
                pos = '186px';
            } else if (isBreak680window) {
                pos = '214px';
            }
        }

        setLeftPos(pos);
        setPlayStarNum(starNum);
    }, [isMDWindow, isXLWindow, setPlayStarNum, isBreak680window]);

    /* BEGIN CYCLE */
    useEffect(() => {
        if (numRatingStars) {
            let pos;

            if (isXLWindow) {
                pos = '25px';
            } else if (isMDWindow) {
                pos = '30px';
            } else if (isBreak680window) {
                pos = '38px';
            } else {
                pos = '0px';
            }

            if (viewWidth) {
                setLeftPos(pos);
            }

            const type = numRatingStars > 0.5 ? 'Full' : 'Half';
            const animType = type === 'Half' ? halfStarLottie : fullStarLottie;

            setShowStarType(type);
            setPlayStarNum(1);

            animType.goToAndPlay(1, true);
        }
    }, [isMDWindow, isXLWindow, isBreak680window, numRatingStars, setPlayStarNum, halfStarLottie, fullStarLottie, viewWidth]);
    const playNextAnim = useCallback((pos: number) => {
        const starType = getStarType({
            totalStars,
            index: pos - 1
        });
        const animType = starType === 'Half' ? halfStarLottie : fullStarLottie;

        setShowStarType(starType);
        setNextPos(pos);
        animType.goToAndPlay(1, true);
    }, [fullStarLottie, halfStarLottie, setNextPos, totalStars]);

    useEffect(() => {
        if (numRatingStarAnims &&
                numRatingStars &&
                halfStarLottie &&
                fullStarLottie
        ) {
            if (animCycleNum === 2 || animCycleNum == 3) {
                const numCyclesLeft = numRatingStarAnims - animCycleNum + 1;

                if (numCyclesLeft) {
                    playNextAnim(animCycleNum);
                }
            }
        }
    }, [animCycleNum, fullStarLottie, halfStarLottie, numRatingStarAnims, numRatingStars, playNextAnim]);

    useEffect(() => {
        if (numRatingStarAnims && animCycleNum > numRatingStarAnims) {
            halfStarLottie.destroy();
            fullStarLottie.destroy();
        }
    }, [animCycleNum, numRatingStarAnims, halfStarLottie, fullStarLottie]);

    useEffect(() => {
        return () => {
            halfStarLottie.destroy();
            fullStarLottie.destroy();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HStack
            data-testid={'ProgressStarAnim H stack'}
            pos={'absolute'}
            top={'0px'}
            spacing={{
                base: '40px',
                md: '30px',
                break1270: '26px'
            }}
            h={'100%'}
            w={{
                base: '224px',
                break680: '303px',
                md: '264px',
                break1270: '296px'
            }}
            boxSizing={'border-box'}
            alignItems={'center'}
        >
            {leftPos && <Circle
                size={{
                    base: '48px',
                    break1270: '64px'
                }}
                boxSizing={'border-box'}
                justifyContent={'center'}
                alignItems={'center'}
                pos={'absolute'}
                left={leftPos}
            >
                <ProgressStarAnimStyled
                    type={'Full'}
                    showStarType={showStarType}
                >{fullStarLottie.View}
                </ProgressStarAnimStyled>
                <ProgressStarAnimStyled
                    type={'Half'}
                    showStarType={showStarType}
                >{halfStarLottie.View}
                </ProgressStarAnimStyled>
            </Circle>}
        </HStack>
    );
}
;
/*
* http://localhost:6006/?path=/story/routes-resultspage--progress-anim-90
* */
