import {
    Flex, Box, Center, Image, useTheme
} from '@chakra-ui/react';
import React from 'react';

import CorrectAnswer from './assets/Correct.svg';
import InCorrectAnswer from './assets/InCorrect.svg';

type Props = {
    answer: number
    questionNumber: number
};

export function Result ({
    answer, questionNumber
}: Props) {
    const theme = useTheme();
    const {
        green02,
        green05,
        orange01,
        orange03
    }= theme?.app;

    return (
        <Flex
            flexWrap={'wrap'}
            m={'10px 8px'}
            position={'relative'}
            width={{
                base: '22px',
                md: '52px',
                breakTabletLandscape: '70px'
            }}
            height={{
                base: '22px',
                md: '40px'
            }}
            justifyContent={'center'}
            alignItems={{
                base: 'baseline',
                md: 'center'
            }}
            borderRadius={'63.36px'}
            bg={answer===1 ? green02 : orange01}
        >
            <Center
                fontSize={{
                    base: '15px',
                    md: '20px'
                }}
                fontWeight={500}
                lineHeight={'normal'}
                letterSpacing={'1px'}
                color={answer===1 ? green05 : orange03}
            >
                {questionNumber}
            </Center>
            <Box
                display={{
                    base: 'none',
                    md: 'flex'
                }}
            >
                <Image
                    position={'absolute'}
                    top={'-8px'}
                    right={'0px'}
                    width={'20px'}
                    height={'20px'}
                    src={answer===1 ? CorrectAnswer : InCorrectAnswer}
                    alt={'question result icon'}
                />
            </Box>
        </Flex>
    );
}

