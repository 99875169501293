import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';

import { GlobalStyles } from './global-styles';
import {
    appTheme, AppThemeType
} from './theme/app-theme';

type Props = React.PropsWithChildren<{
    theme?: AppThemeType
}>;

/**
 * A basic provider to give emotion components a theme property through React Context.
 *
 * For Typescript support see the declaration override for AppTheme in `types/theme.d.ts`.
 *
 * @see https://emotion.sh/docs/theming
 */
export const AppThemeProvider = ({
    children, theme = appTheme
}: Props) => {
    return (
        <ChakraProvider theme={theme}>
            <GlobalStyles />
            {children}
        </ChakraProvider>
    );
};
