import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../../../app/store/store.types';
import { lokalisation } from '../../../../helpers/lokalisation';
import { transformSchema } from '../../../../helpers/utils';
import ConceptRefreshSlide from './concept-refresh-slide';
import {
    IntroScreenStyled,
    Section,
    HeaderTitle,
    QuestionSetTitle,
    ExampleHeading,
    ExampleQuestionWrapper,
    Content,
    Heading
} from './intro-screen.styled';
import { generateBlock } from './utils/intro-screen.utils';

export const IntroScreen = () => {
    /*
     * ACTIVITY STATE
     */
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const {
        studentName, questName, learningIntention, successCriteria, questionExample, conceptRefresh
    } = activityState;
    const { firstName } = studentName || {};
    const { introTxt } = lokalisation();
    const assetsBasePath = window._env_.ASSETS_CDN_BASE_PATH;

    return (
        <>{assetsBasePath && <IntroScreenStyled data-testid={'intro-modal'}>
            <HeaderTitle>
                <strong>{introTxt.hi} {(firstName)},</strong> {introTxt.goal}
            </HeaderTitle>
            <QuestionSetTitle>
                {questName}
            </QuestionSetTitle>
            <ExampleHeading>
                {introTxt.questionSubHeading}
                <ExampleQuestionWrapper>
                    {generateBlock(transformSchema(questionExample) as [], assetsBasePath)}
                </ExampleQuestionWrapper>
            </ExampleHeading>
            <Section>
                <div className={'column'}>
                    {learningIntention &&
                    <Content>
                        <Heading> {introTxt.intention}</Heading>
                        <div className={'copy'}> {generateBlock(transformSchema(learningIntention) as [], assetsBasePath)}</div>
                    </Content>
                    }
                    <Content>
                        <Heading> {introTxt.success}</Heading>
                        <div className={'copy'}> {generateBlock(transformSchema(successCriteria) as [], assetsBasePath)}</div>
                    </Content>
                </div>
                <Flex justifyContent={'flex-end'} className={'column'}>
                    <ConceptRefreshSlide conceptRefresh={transformSchema(conceptRefresh) as []} />
                </Flex>
            </Section>
        </IntroScreenStyled>}</>
    );
};

