import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { app } from '../../../../../../styles/theme/variables';
import { ProgressBarAndStarContainerProps } from '../progress-bar-and-star-container';

export type StarRatingType = 'Full' | 'Half' | undefined;

type ProgressBarProps = {
    isSummaryPage: boolean
};

export const ProgressInnerBarStyled = styled.div<Pick<ProgressBarAndStarContainerProps, 'totalCorrect' | 'totalQuestions'>>(props => {
    const {
        totalCorrect, totalQuestions
    } = props;
    const percentValue = totalCorrect / totalQuestions;

    return css`
      height: 100%;
      position: relative;

      .inner-bar {
        width: calc(100% * ${percentValue});
        height: 100%;
        border-radius: 32px;
        background: linear-gradient(270deg, #F8CD01 -18.89%, #58D25B 56.29%);
      }

      .inner-bar-gold {
        opacity: 0;
        width: calc(100% * ${percentValue});
        height: 100%;
        border-radius: 32px;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(133deg, #FEDF01 0%, #EAA400 100%);
      }

      .gold-bar-stars {
        position: absolute;
        transform: translateY(-62.5%);
        left: 0;
      }
    `;
});

export const ProgressTextStyled = styled.div<ProgressBarProps>(props => {
    const { isSummaryPage } = props;

    return css`
      display: flex;
      flex-direction: row;
      align-items: baseline;
      position: relative;
      bottom: 3px;
      width: ${isSummaryPage ? '85px' : 'auto'};

      .progress-text {
        font-size: 32px;
        line-height: 30px;
        color: ${app.neutral900};
        min-width: auto;

        @media (min-width: ${app.breakTabletLandscape}) {
          font-size: 44px;
        }
      }

      .progress-text-percent {
        font-size: 16px;

        @media (min-width: ${app.breakTabletLandscape}) {
          font-size: 24px;
        }
      }
    `;
});

export const ProgressHeading = styled.div`
    margin-bottom: 16px;
    height: 25px;
    
    .label {
        color: ${app.neutral900};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .info {
        color: ${app.neutral900};
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 10px;
    }
`;

export const ProgressImageStyled = styled.div<ProgressBarProps>(props => {
    const { isSummaryPage } = props;

    return css`
          flex-shrink: 0;

          .grey-bar {
            margin: 0 10px
          }

          .gold-bar {
            width: ${isSummaryPage ? '80px' : '54px'};
          }
    `
    ;
});
