import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { TransitionScreen } from '../../../../helpers/enums';
import { MessageBoxProps } from './message-box';

export const MessageBoxStyled = styled.div<MessageBoxProps>((props) => {
    const {
        type, theme, hasGuide
    } = props;
    const isSummaryOrStartScreen = TransitionScreen.summaryScreen === type || TransitionScreen.startScreen === type;
    const isSummaryScreen = type === TransitionScreen.summaryScreen;
    const isUnitTestSummaryScreen = type === TransitionScreen.unitTestSummaryScreen;
    const {
        breakTabletLandscape,
        breakTabletPortraitPhone,
        guideWidthLandscape,
        guideHeightLandscape,
        guideWidthPortrait,
        guideHeightPortrait,
        guideWidthPortraitPhone,
        guideHeightPortraitPhone
    } = theme.app;

    return css`
      ${theme.flex.flexColumnCentered};
      width: 100%;
      height: 100%;

      .message-box-content {
        ${theme.flex.flexRowSpaced};
        height: 100%;
        ${isSummaryOrStartScreen && css`
          width: 95%;
          max-width: calc(${theme.app.messageBoxWidth} + ${theme.app.guideWidthAndSpacer});
        `}
        ${isSummaryScreen && css`
            max-width: calc(${theme.app.messageBoxSummaryWidth} + ${theme.app.guideWidthAndSpacer});
        `}
        ${isUnitTestSummaryScreen && css`
            max-width: ${theme.app.lg};
        `}
        /**
        LANDSCAPE
        */
        @media (max-width: ${theme.app.breakTabletLandscape}) {
          ${!isSummaryScreen && css`width: calc(${theme.app.messageBoxWidthLandscape} + ${theme.app.guideWidthLandscapeAndSpacer})`};
          ${!hasGuide && css`width: auto;`}
          ${isUnitTestSummaryScreen && css`width: calc(100% - 40px);`}
        }

        /**
        PORTRAIT
        */
        @media (max-width: ${theme.app.breakPoint}) {
          ${theme.flex.flexColumnCentered};
          width: 100%;
        }

        /**
        LESS THAN PORTRAIT
        */
        @media (max-width: ${theme.app.breakTabletPortraitPhone}) {
          ${theme.flex.flexColumnCentered};
          width: 100%;
        }
        
        .guide-holder-holder {
        /**
        PORTRAIT
        */
          @media (max-width: ${theme.app.breakPoint}) {
            width: calc(100% - 40px); 
          }

        /**
        LESS THAN PORTRAIT
        */
          @media (max-width: ${breakTabletPortraitPhone}) {
            width: calc(100% - 32px);
          }
        }

        .guide-holder {
          z-index: 0;
          border: 2px solid #ffffff66;
          border-radius: 40px 0 40px 40px;
          overflow: hidden;
          width: ${theme.app['guideWidth']};
          height: ${theme.app['guideHeight']};

          /**
           LANDSCAPE
            */
           @media (max-width: ${breakTabletLandscape}) {
             width: ${guideWidthLandscape};
             height: ${guideHeightLandscape};
            ${isUnitTestSummaryScreen && css`
                width: ${theme.app.lg});
            `}
           }
           
           /**
           PORTRAIT
            */
           @media (max-width: ${theme.app.breakPoint}) {
             border-radius: 20px 20px 20px 0;
             border-width: 1px;
             width: ${guideWidthPortrait};
             height: ${guideHeightPortrait};
             margin-bottom: 16px;
           }
       
         /**
          LESS THAN PORTRAIT
            */
         @media (max-width: ${breakTabletPortraitPhone}) {
           width: ${guideWidthPortraitPhone};
           height: ${guideHeightPortraitPhone};
           margin-bottom: 8px;
           margin-top: -15px;
         }
        }

        .message-holder {
          ${hasGuide ? 'margin-left: 24px;' : ''}
          width: ${isUnitTestSummaryScreen ? theme.app.lg : theme.app.messageBoxWidth};
          overflow: hidden;
          ${isSummaryOrStartScreen && css`
            max-width: ${theme.app.messageBoxSummaryWidth};
            width: 100%;
          `}

          /**
          LANDSCAPE
          */
          @media (max-width: ${theme.app.breakTabletLandscape}) {
            width: ${!isSummaryOrStartScreen && !isUnitTestSummaryScreen && theme.app.messageBoxWidthLandscape};
            ${isUnitTestSummaryScreen && css`width: 100%;`}
          }

          /**
          PORTRAIT
          */
          @media (max-width: ${theme.app.breakPoint}) {
            width: calc(100% - 40px);
            margin-left: 0;
          }

          /**
          LESS THAN PORTRAIT
          */
          @media (max-width: ${theme.app.breakTabletPortraitPhone}) {
            width: calc(100% - 32px);
            overflow: visible;
          }
        }
      }
    `;
});
