import {
    useEffect, useRef, useState
} from 'react';

import GoldBrick from '../../../../../../assets/lottie/GoldBar.json';
import GoldBarStars from '../../../../../../assets/lottie/GoldBarStars.json';
import { animateScore } from '../../../../../containers/question-container/anims/anims';
import { lokalisation } from '../../../../../helpers/lokalisation';
import { useLottieAnim } from '../../../../../hooks/use-lottie-anim';

type Options = {
    totalCorrect: number
    totalQuestions: number
    playStarAnimation?: boolean
};

export function useGetAnimationDetails ({
    totalCorrect, totalQuestions
}: Options) {
    const [playStarsAnim, setPlayStarsAnim] = useState<boolean>(false);
    const barRef = useRef<HTMLDivElement>(null);
    const barGoldRef = useRef<HTMLDivElement>(null);
    const totalCorrectRef = useRef<HTMLDivElement>(null);
    const percentValue = totalCorrect / totalQuestions;
    const scoreNum = Math.round(100 * percentValue);
    const showGold = scoreNum >= 80;
    const { summaryTxt: { score } } = lokalisation();
    const { View: goldBarStars } = useLottieAnim({ animationData: GoldBarStars });
    const { View: goldBrick } = useLottieAnim({ animationData: GoldBrick });

    useEffect(() => {
        if (
            barRef && barRef.current &&
            barGoldRef && barGoldRef.current &&
            totalCorrectRef && totalCorrectRef.current) {
            const callback = () => {
                setPlayStarsAnim(true);
            };

            animateScore({
                bar: barRef.current,
                barGold: barGoldRef.current,
                score: scoreNum,
                scoreNumTxt: totalCorrectRef.current,
                callback
            });
        }
    }, [scoreNum]);

    return {
        score,
        showGold,
        barRef,
        barGoldRef,
        goldBarStars,
        goldBrick,
        totalCorrectRef,
        scoreNum,
        playStarsAnim
    };
}
