import React, {
    useMemo, useState, useEffect
} from 'react';

import AppImage from '../../../../components/app-image/app-image';
import { PROGRESS_STATE } from '../../../../helpers/enums';
import { lokalisation } from '../../../../helpers/lokalisation';
import { isDefined } from '../../../../helpers/utils';
import { useProgressActivity } from '../../hooks/use-progress-activity';
import { ProgressActivityProps } from '../../question-container.types';
import { getStarsArray } from '../attempt-status/utils/attempt-status-util';
import { ProgressMilestone } from '../progress-milestone/progress-milestone';
import { ProgressStep } from '../progress-step/progress-step';
import { ProgressActivityStyled } from './progress-activity.styled';

export const ProgressActivity = ({ questionNumber }:ProgressActivityProps) => {
    const { labelTxt } = lokalisation();
    const [stepsAnswered, setStepsAnswered] = useState<number>();
    const [questionStars, setQuestionStars] = useState<number[]>();
    /*
     * USE PROGRESS ACTIVITY HOOK
     */
    const {
        progressData, showProgress
    } = useProgressActivity();
    /*
     * PROGRESS STEPS
     */
    const progressSteps = useMemo(() => {
        if (isDefined(stepsAnswered) && isDefined(questionStars)) {
            return questionStars.map((stars, index) => {
                return (
                    <ProgressStep
                        questionNumber={questionNumber}
                        stepsAnswered={stepsAnswered}
                        questionNumIndex={index}
                        numStars={stars}
                        key={index}
                    />
                );
            });
        }

        return null;
    }, [questionNumber, questionStars, stepsAnswered]);

    /*
     * SET NUM ANSWERS AND NUM STARS ARRAY
     */

    useEffect(() => {
        if (isDefined(progressData)) {
            const { stepsCompleted } = progressData;

            setStepsAnswered(stepsCompleted);
            setQuestionStars(getStarsArray(progressData));
        }
    }, [progressData]);

    return(
        <ProgressActivityStyled showProgress={showProgress}>
            <div className={'progress-content'} data-testid={'progress-content'}>
                <div className={'progress-activity-frame'} />
                <AppImage iconType={'progressPath'} imageType={'svg'} className={'progress-path'} />
                <div className={'summit'}>
                    <div className={'summit-label'}>{labelTxt.summit}</div>
                    <div className={'summit-line'} />
                </div>
                {Object.keys(PROGRESS_STATE).filter(key => isNaN(Number(key))).map(
                    (state, value) => (
                        <ProgressMilestone
                            stepsAnswered={stepsAnswered || 0}
                            key={state}
                            progressType={value}
                        />
                    )
                )}
                {progressSteps}
            </div>
        </ProgressActivityStyled>
    );
};
