/**
 * The main application route paths,
 * centralised here for use all over the application.
 */
export const ROUTES = {
    appInitialisation: '/',
    questionConsole: '/question',
    milestoneTransition: '/progress',
    diagnostics: '/diagnostics',
    singleVariant: '/single-variant',
    preview: '/preview'
};
