import {
    Flex, Divider
} from '@chakra-ui/react';
import React from 'react';

import { useProgressActivity } from '../../containers/question-container/hooks/use-progress-activity';
import { UnitTestQuestionAttemptPill } from './unit-test-question-attempt-pill';
import { UnitTestQuestionAttemptsPillProps } from './unit-test-question-attempts.types';

export const UnitTestQuestionAttempts = () => {
    const {
        progressData,
        questionsCount
    } = useProgressActivity();
    const stepsCompleted = progressData?.stepsCompleted || 0;

    return (
        <Flex alignItems={'center'}>
            {Array(questionsCount).fill(0)?.map((attempt, index) => {
                const attemptNum = index + 1;

                let validationStatus: UnitTestQuestionAttemptsPillProps['status'] = 'Prompted';

                if (index < stepsCompleted) {
                    validationStatus = 'Attempted';
                } else if (index > stepsCompleted) {
                    validationStatus = 'Unattempted';
                }

                return (
                    <React.Fragment key={attemptNum}>
                        <UnitTestQuestionAttemptPill
                            status={validationStatus}
                        />
                        {attemptNum < (questionsCount || 0) && <Divider
                            w={{
                                base: 0,
                                md: '6px',
                                lg: '12px'
                            }}
                            ml={{
                                base: '-2px',
                                md: 0
                            }}
                            bgColor={'neutral.100'}
                        />}
                    </React.Fragment>
                );
            })}
        </Flex>
    );
};
