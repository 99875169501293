import { QtcRenderer } from '@3plearning/question-components-library';
import {
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Text,
    Box,
    useMediaQuery
} from '@chakra-ui/react';
import { ErrorBoundary } from '@sentry/react';
import React, { FC } from 'react';

import AppImage from '../../components/app-image/app-image';
import { MultiDocs } from '../../containers/question-container/sub-components/question-activity/question-activity.types';
import { lokalisation } from '../../helpers/lokalisation';
import { app } from '../../styles/theme/variables';
import { useAudioEffectsContext } from '../audio/provider/audio-effects-provider';
import CorruptedData from '../corrupted-data/corrupted-data';
import {
    WorkedSolutionBody,
    WorkedSolutionContent,
    WorkedSolutionHeader,
    WorkedSolutionWrapper,
    LeftItem
} from './worked-solution.styled';
import { WorkedSolutionProps } from './worked-solution.types';

const WorkedSolution: FC<WorkedSolutionProps> = ({ multiDocs = [] }) => {
    const [breakMobile] = useMediaQuery(`(max-width: ${app.breakTabletPortrait})`);
    const {
        buttonTxt: { workedSolution },
        workedSolutionTxt: {
            solution,
            signPosts
        },
        questionComponentsLibraryTxt
    } = lokalisation();
    const { playClick } = useAudioEffectsContext();
    const renderTabList = () => {
        if (multiDocs.length > 1 && !breakMobile) {
            return (
                <TabList
                    display={'inline-flex'}
                    backgroundColor={app.neutral50}
                    height={'48px'}
                    borderRadius={'24px'}
                    border={`1px solid ${app.neutral100}`}
                    data-testid={'tab-list'}
                >
                    {
                        multiDocs.map((doc, ind) => (
                            <Tab
                                key={ind}
                                height={'40px'}
                                padding={'0 30px'}
                                margin={'4px 4px'}
                                fontWeight={'normal'}
                                color={app.primary700}
                                backgroundColor={'none'}
                                _selected={{
                                    boxShadow: '0px 1px 3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 6%)',
                                    backgroundColor: 'white'
                                }}
                                data-testid={`solution-${ind + 1}-button`}
                                onClick={playClick}
                            >
                                {solution(ind + 1)}
                            </Tab>
                        ))
                    }
                </TabList>
            );
        } else {
            return null;
        }
    };
    const renderQtcRenderer = (doc: MultiDocs[number], ind: number) => {
        return (
            <QtcRenderer
                key={ind}
                question={doc.content}
                callback={() => null}
                showCorrectAnswer={true}
                translations={questionComponentsLibraryTxt}
            />
        );
    };
    const renderTabPanels = () => {
        if (multiDocs.length > 0 && !breakMobile) {
            return (
                <TabPanels
                    padding={0}
                >
                    {
                        multiDocs.map((doc, ind) => (
                            <TabPanel
                                padding={0}
                                key={ind}
                                data-testid={`doc-${ind + 1}`}
                            >
                                {renderQtcRenderer(doc, ind)}
                            </TabPanel>
                        ))
                    }
                </TabPanels>
            );
        } else if (multiDocs.length === 1 && breakMobile) {
            return multiDocs.map((doc, ind) => (
                <Box
                    key={ind}
                >
                    {renderQtcRenderer(doc, ind)}
                </Box>
            ));
        } else if (multiDocs.length > 1 && breakMobile) {
            return multiDocs.map((doc, ind) => (
                <Box
                    key={ind}
                    marginBottom={'24px'}
                >
                    <Text
                        height={'36px'}
                        backgroundColor={'#007ACC'}
                        display={'inline-flex'}
                        color={'white'}
                        alignItems={'center'}
                        position={'relative'}
                        padding={'0 24px'}
                        borderRadius={'0 18px 18px 0'}
                        mb={'16px'}
                    >
                        {solution(ind + 1)}
                    </Text>
                    {renderQtcRenderer(doc, ind)}
                </Box>
            ));
        }
    };

    return (
        <WorkedSolutionWrapper className={'worked-solution-wrapper'}>
            <WorkedSolutionBody>
                <WorkedSolutionHeader>
                    <LeftItem>
                        <AppImage imageType={'svg'} iconType={'workedSolutionIcon'} />
                        <Text paddingLeft={'10px'}>{workedSolution}</Text>
                    </LeftItem>
                </WorkedSolutionHeader>
                <WorkedSolutionContent signPosts={signPosts}>
                    {
                        multiDocs && (
                            <Tabs variant={'soft-rounded'} colorScheme={'green'}>
                                <Box
                                    display={'flex'}
                                    justifyContent={'center'}
                                    marginBottom={'10px'}
                                >
                                    {renderTabList()}
                                </Box>
                                <ErrorBoundary
                                    fallback={<CorruptedData />}
                                >
                                    {renderTabPanels()}
                                </ErrorBoundary>
                            </Tabs>
                        )
                    }
                </WorkedSolutionContent>
            </WorkedSolutionBody>
        </WorkedSolutionWrapper>
    );
};

export default WorkedSolution;
