import { isDefined } from '../../../../../helpers/utils';
import { ThemeProps } from '../../../../../styles/theme/app-theme';
import { QuestionProgressStyleProps } from '../../../question-container.types';
import { Progress } from '../../question-activity/question-activity.types';

export const attemptStatusUtil = () => {
    /*
     * DETERMINE STAR STYLE
     */
    const getStarStyle = ({
        questionProgress, attemptNum
    }: QuestionProgressStyleProps) => {
        const { progressAttempt } = questionProgress;

        let greyStar = false;

        if(isDefined(attemptNum)) {
            if (attemptNum < progressAttempt) {
                greyStar = true;
            }
        }

        return { greyStar };
    };
    /*
     * DETERMINE SURROUNDING CAPSULE STYLE
     */
    const getCapsuleStyle = ({
        questionProgress, theme
    }: QuestionProgressStyleProps & ThemeProps) => {
        const {
            questionAnswered,
            questionCorrect,
            progressAttempt
        } = questionProgress;
        const { app } = theme;

        let borderColour = app.neutral200;
        let capsuleWidth, leftPos;

        if (isDefined(progressAttempt)){
            switch (progressAttempt) {
            case 1:
                capsuleWidth ='84px';
                leftPos = '0';
                break;
            case 2:
                capsuleWidth ='56px';
                leftPos = '28px';
                break;
            case 3:
                capsuleWidth ='28px';
                leftPos = '56px';
                break;
            }

            if (questionAnswered) {
                if (questionCorrect) {
                    borderColour = app.green01;
                } else {
                    borderColour = app.red02;
                }
            }
        }

        return {
            capsuleWidth,
            borderColour,
            leftPos
        };
    };

    return {
        getStarStyle,
        getCapsuleStyle
    };
};

export const getStarsArray = (progress: Progress) => {
    let stepArray:number[] = [];

    const {
        points, stepsCompleted
    } = progress;

    if (isDefined(points) && isDefined(stepsCompleted)) {
        stepArray = points.map((section) => section.stars).flat();
    }

    return stepArray;
};
