import styled from '@emotion/styled';

import { pickThemeVar } from '../../../../styles/theme/app-theme';

export const GuideStyled = styled.div`
    z-index: 0;
    background: linear-gradient(180deg, #0BB4F6 0%, #8EE0FE 100%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
        max-width: 98%;
    }

    /** PORTRAIT */
    @media (max-width: ${pickThemeVar('breakPoint')}) {
        background: linear-gradient(0deg, #0BB4F6 0%, #8EE0FE 195.83%);
    }

    /** LESS THAN PORTRAIT */
    @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
        background: linear-gradient(360deg, #0BB4F6 0%, #8EE0FE 191.44%);
    }
`;
