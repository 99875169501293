import {
    useEffect, useState
} from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../../app/store/store.types';
import { MAX_ATTEMPTS } from '../../../helpers/constants';
import {
    QuestionSetType, STUDENT_STATES
} from '../../../helpers/enums';
import { isDefined } from '../../../helpers/utils';
import { Progress } from '../sub-components/question-activity/question-activity.types';

export const useProgressActivity = () => {
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const {
        progress,
        updatedProgress,
        validation,
        studentState,
        questionSetType,
        questName,
        playCorrectStars
    } = activityState;
    /*
     * PROGRESS ATTEMPT NUM, QUESTION ANSWERED, IS CORRECT
     */
    const progressAttempt = progress?.attempt || 0;
    const [questionAnswered, setQuestionAnswered] = useState<boolean>(false);
    const [questionCorrect, setQuestionCorrect] = useState<boolean>(false);
    const [showProgress, setShowProgress] = useState<boolean>(false);
    const [progressData, setProgressData] = useState<Progress | undefined>();

    /*
     * DETERMINE PROGRESS DATA SOURCE
     */
    useEffect(() => {
        if (isDefined(progressAttempt)) {
            let useUpdatedData;

            const maxAttempts = questionSetType === QuestionSetType.questiconActivity ? MAX_ATTEMPTS : 1;

            if (isDefined(validation)) {
                setQuestionAnswered(true);
                setQuestionCorrect(validation.isCorrect);
                useUpdatedData = progressAttempt === maxAttempts || validation.isCorrect;
            } else {
                setQuestionAnswered(false);
                useUpdatedData = false;
            }

            setProgressData(useUpdatedData ? updatedProgress : progress);
        }
    }, [progress, progressAttempt, updatedProgress, validation, questionSetType]);

    /*
     * SHOW PROGRESS IN QUESTION STATE
     */
    useEffect(() => {
        if (isDefined(studentState)) {
            setShowProgress(studentState === STUDENT_STATES.question);
        }
    }, [studentState]);

    return {
        questionAnswered,
        questionCorrect,
        progressData,
        showProgress,
        progressAttempt,
        questName,
        playCorrectStars,
        questionSetType,
        questionsCount: activityState.questionsCount
    };
};
