import { useState } from 'react';

import { ExitType } from '../../helpers/enums';
import { getQueryParam } from '../../helpers/utils';
import { getURLLocale } from '../../i18n/utils/locale-utils';
import { Resources } from '../../resources/resources';

export const useRestartApp = () => {
    const [restarting, setRestarting] = useState(false);
    const restartApp = async (questionSetType?: number) => {
        const questionSetId = getQueryParam('questionSetId') || '';
        const assignmentId = getQueryParam('assignmentId');

        try {
            setRestarting(true);

            const response = await Resources.exitQuestionSet?.post().withBody({
                questionSetId,
                assignmentId: assignmentId != null ? parseInt(assignmentId) : 0,
                questionSetTypeId: questionSetType,
                progressType: ExitType.fluencyExit,
                locale: getURLLocale()
            }).send();

            if (response.status > 399) {
                setRestarting(false);
            }
        } catch (error) {
            setRestarting(false);
            console.log(error);
        }

        window.location.reload();
    };

    return {
        restarting,
        restartApp
    };
};
