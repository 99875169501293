import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { MilestoneState } from '../../../../helpers/enums';
import {
    pickThemeFlex, ThemeProps
} from '../../../../styles/theme/app-theme';
import {
    ProgressActivityProps,
    ProgressStepProps, ProgressStepStyledProps, RefType
} from '../../question-container.types';

const bgStepStyle = (props: ProgressStepStyledProps & ProgressActivityProps & ThemeProps) => {
    let bgColour, border, borderColour, color, fontSize, diameter;

    const {
        questionNumber, questionNumIndex, theme: { app }
    } = props;

    switch (props.stepState) {
    case MilestoneState.notStarted:
        borderColour = 'white';
        color = 'white';
        bgColour = 'rgba(0, 25, 55, 0.5)';
        border = 'solid 1px';
        diameter = '22px';
        fontSize = '14px';
        break;
    case MilestoneState.inProgress:
        borderColour = 'white';
        color = 'white';
        if (questionNumber === questionNumIndex + 1) {
            bgColour = app.blue02;
            border = 'solid 2px';
            fontSize = '18px';
            diameter = '28px';
        } else {
            bgColour = 'rgba(0, 25, 55, 0.5)';
            border = 'solid 1px';
            diameter = '22px';
            fontSize = '14px';
        }
        break;
    case MilestoneState.completed:
        bgColour = 'white';
        border = 'solid 1.5px';
        borderColour = props.numStars === 0 ? app.red03 : app.green02;
        color = props.numStars === 0 ? app.red04 : app.green04;
        diameter = '26px';
        fontSize = '15px';
        break;
    }

    return css`
      background: ${bgColour};
      border: ${border};
      border-color: ${borderColour};
      color: ${color};
      font-size: ${fontSize};
      width: ${diameter};
      height: ${diameter};
    `;
};
const stepPosition = (props: ProgressStepProps & ProgressStepStyledProps) => {
    const { questionNumIndex } = props;

    let leftPos: number | string = 0;
    let topPos: number | string = 0;

    switch (questionNumIndex + 1) {
    case 1:
        leftPos = 215;
        topPos = 462;
        break;
    case 2:
        leftPos = 120;
        topPos = 431;
        break;
    case 3:
        leftPos = 25;
        topPos = 393;
        break;
    case 4:
        leftPos = 62;
        topPos = 348;
        break;
    case 5:
        leftPos = 185;
        topPos = 336;
        break;
    case 6:
        leftPos = 41;
        topPos = 263;
        break;
    case 7:
        leftPos = 186;
        topPos = 213;
        break;
    case 8:
        leftPos = 94;
        topPos = 179;
        break;
    case 9:
        leftPos = 99;
        topPos = 62;
        break;
    case 10:
        leftPos = 155;
        topPos = 19;
        break;
    }
    leftPos = `${leftPos}px`;
    topPos = `${topPos}px`;

    return css`
      position: absolute;
      left: ${leftPos};
      top: ${topPos};
    `;
};
const bgDiscProps = (props: ProgressStepStyledProps) => {
    let width = '0', height = '0', background = 'rgba(0, 0, 0, 0.24)';

    const { stepState } = props;
    const {
        notStarted, completed, inProgress
    } = MilestoneState;

    switch (stepState) {
    case notStarted:
        width = '24px';
        height = '24px';
        break;
    case inProgress:
        width = '34px';
        height = '34px';
        background = 'rgba(255, 255, 255, 0.24)';
        break;
    case completed:
        width = '44px';
        height = '44px';
        break;
    }

    return {
        width,
        height,
        background
    };
};

type StarPosType = {
    numStars: number
    nthType: number
};
const starPos = ({
    nthType, numStars
}: StarPosType) => {
    let top = '0px', left = '0px';

    switch (numStars) {
    case 1:
        top = '-10px';
        left = '10px';
        break;
    case 2:
        switch (nthType) {
        case 1:
            top = '-9px';
            left = '0px';
            break;
        case 2:
            top = '-9px';
            left = '22px';
            break;
        }
        break;
    case 3:
        switch (nthType) {
        case 1:
            top = '-4px';
            left = '-7px';
            break;
        case 2:
            top = '-10px';
            left = '10px';
            break;
        case 3:
            top = '-4px';
            left = '28px';
            break;
        }
        break;
    }

    return {
        top,
        left
    };
};

export const ProgressStepStyled = styled.div<ProgressStepProps & ProgressStepStyledProps & ProgressActivityProps>`
  width: 44px;
  height: 44px;

  ${stepPosition};

  .step-content {
    ${pickThemeFlex('flexChildCentered')};
    position: relative;
    width: 100%;
    height: 100%;

    .bg-disc {
      ${pickThemeFlex('flexChildCentered')};
      ${bgDiscProps};
      border-radius: 50%;

      .label {
        ${pickThemeFlex('flexChildCentered')};
        ${bgStepStyle};
        z-index: 1;
        border-radius: 50%;

        > span {
          position: relative;
          bottom: ${props => props.stepState === MilestoneState.inProgress ? '2px' : '1px'};
        }
      }
    }

    .star-list {
      position: absolute;
      width: 44px;
      height: 44px;
    }
  }
`;

type StarHolderProps = {
    key: number
    ref: RefType
    isCurrentStep: boolean
    numStars: number
} ;

export const StarHolderStyled = styled.div<StarHolderProps>(props => {
    const {
        numStars, isCurrentStep
    } = props;

    return css`
      position: absolute;
      width: 22px;
      height: 19px;
      z-index: 1;
      opacity: ${isCurrentStep ? 0 : 1};

      .star {
        width: 22px;
        height: 19px;
        filter: drop-shadow(2px 2px 2px #525352);
      }

      :nth-of-type(1) {
        ${starPos({
        numStars,
        nthType: 1
    })}
      }

      :nth-of-type(2) {
        ${starPos({
        numStars,
        nthType: 2
    })}
      }

      :nth-of-type(3) {
        ${starPos({
        numStars,
        nthType: 3
    })}
      }
    `;
});
