import {
    Box, Stack, Text, useRadioGroup
} from '@chakra-ui/react';
import React from 'react';

import { useAudioEffectsContext } from '../../../../components/audio/provider/audio-effects-provider';
import { lokalisation } from '../../../../helpers/lokalisation';
import { FeedbackItem } from './feedback-item';

export const FeedbackScreen: React.FC<{
    handleChange: (value: string) => void
    questionsetTitle?: string
}> = ({
    handleChange,
    questionsetTitle
}) => {
    const { playSelfAssesmentEmojisAudio } = useAudioEffectsContext();
    const {
        feedbackTxt: {
            heading,
            feedback
        }
    } = lokalisation();
    const {
        getRadioProps, getRootProps
    } = useRadioGroup({
        onChange: (nextValue) => {
            playSelfAssesmentEmojisAudio(Number(nextValue));
            handleChange(nextValue);
        }
    });

    return (
        <Box className={'message-content'}>
            <Box className={'message-body'}>
                <Box
                    className={'header'}
                    mb={{
                        base: '32px',
                        md: '72px'
                    }}
                >
                    <Text>{heading}</Text>
                    <Text color={'primary.600'}>{questionsetTitle}?</Text>
                </Box>
                <Stack
                    direction={{
                        base: 'column',
                        md: 'row'
                    }}
                    justify={'space-between'}
                    spacing={{
                        base: '20px',
                        md: 0
                    }}
                    mb={{
                        base: 0,
                        md: '125px'
                    }}
                    {...getRootProps()}
                >
                    {Object.keys(feedback).map((feedbackItem, index) => (
                        <FeedbackItem
                            feedbackType={index}
                            key={feedbackItem}
                            {...getRadioProps({ value: index.toString() })}
                        >
                            {feedback[feedbackItem as keyof typeof feedback]}
                        </FeedbackItem>
                    ))}
                </Stack>
            </Box>
        </Box>
    );
};
