// TODO remove inlined regenerator-runtime requirement from 3p-resource.
// the new `.browserslistrc` allows us to handle this automatically with `babel-preset-env`
// this is not actually required by our supported browsers.
import 'regenerator-runtime/runtime.js';
import ConserveParameters from '@3plearning/routing-conserve-parameters';
import React from 'react';
import { createRoot } from 'react-dom/client';

/* eslint-disable etc/no-commented-out-code */
import { getQueryParams } from '../helpers/utils';
import { Resources } from '../resources/resources';
import { Credentials } from '../types/common.types';
import { AppProviders } from './app-providers';
import {
    getToken,
    resourceSetting
} from './entry-app.utils';

// eslint-disable-next-line jest/require-hook
ConserveParameters([
    'username',
    'password',
    'userToken',
    'env',
    'questionSetId',
    'locale',
    'authToken',
    'uow',
    'variantId',
    'courseId',
    'uowId',
    'questionSetType',
    'assignmentId',
    'classId',
    'backUrl',
    'keypadPreset'
]);

export default class EntryApp extends React.Component {
    static element: string;

    options?: Record<string, unknown>;

    constructor (element: string, dynamicOptions?: Record<string, unknown>) {
        super({});
        EntryApp.element = element;
        EntryApp.configure();
        this.options = dynamicOptions;
    }

    static render () {
        return <AppProviders />;
    }

    static renderElement () {
        const rootNode = window.document.querySelector(this.element);

        if (rootNode) {
            const root = createRoot(rootNode);

            root.render(this.render());
        }
    }

    static configure () {
        const params = getQueryParams(location.search);

        EntryApp.configureForRemoteHost(params);
    }

    static configureForDeveloperMachine (params: { env?: string } & Credentials) {
        let envName = params.env;

        if (!envName) {
            console.log(
                'this.configureForDeveloperMachine()' +
                ' - you did not provide an "env" query parameter, so I am assuming you want to talk to live.');

            envName = 'live';
        }

        const credentials = this.getCredentials(params);

        if (envName === 'local' || this.weHaveUsableCredentials(credentials)) {
            Resources.initialise(envName, credentials);
        }
    }

    static async configureForRemoteHost (params: Record<string, string>) {
        const config = window._env_;
        const envName = config.env || 'live';
        const credentials = this.getCredentials(params);

        if (params.username && params.password) {
            await getToken(credentials, envName, config);
        } else if (this.weHaveUsableCredentials(credentials)) {
            resourceSetting(credentials, envName, config);
        }
    }

    static getCredentials (params: Credentials) {
        const credentials: Credentials = {
            authToken: undefined,
            JWT: undefined,
            username: undefined,
            password: undefined
        };
        const items: (keyof Credentials)[] = ['authToken', 'JWT', 'username', 'password'];

        Object.keys(params).forEach((key) => {
            const value = params[key as keyof Credentials];

            for (const item of items) {
                if (key.toLowerCase() === item.toLowerCase()) {
                    credentials[item] = value;
                }
            }
        });

        return credentials;
    }

    static weHaveUsableCredentials (credentials: Credentials) {
        let usableCredentials = false;

        if (credentials.authToken) {
            usableCredentials = true;
        } else if (credentials.username && credentials.password) {
            usableCredentials = true;
        }

        if (!usableCredentials) {
            console.error(
                'EntryApp.configureForDeveloperMachine() - to execute, we need either an authToken or a username/' +
                'password pair. After combining data from window._env_ and the query parameters, I still do' +
                ' not have what I need. The app will probably not function correctly if it requires any 3P resources.');
        }

        return usableCredentials;
    }
}

export const app = new EntryApp('.application');

// @ts-ignore
window.EntryApp = EntryApp;
