import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ThemeProps } from '../../../../styles/theme/app-theme';

export const InfoWrapper = styled.span(({ theme }: ThemeProps) => {
    return css`
        .info-icon {
            :hover {
                cursor: pointer;

                path {
                    fill: ${theme.app.blue02};
                }
            }
        }
    `;
});
