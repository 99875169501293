import { useLottie } from 'lottie-react';

type Props = {
    loop?: boolean
    animationData: unknown
    onComplete?: () => void
};

export const useLottieAnim = ({
    loop=false,
    animationData,
    onComplete,
    ...restProps
}: Props) => {
    const options = {
        loop,
        animationData,
        onComplete,
        ...restProps
    };
    const {
        View, animationItem
    } = useLottie(options);

    return {
        View,
        animationItem
    };
};
