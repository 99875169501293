import {
    Box, Center, Fade, Flex, Image, SlideFade, Text
} from '@chakra-ui/react';
import Lottie, { LottieRef } from 'lottie-react';
import React, {
    RefObject, useEffect
} from 'react';

import { useAudioEffectsContext } from '../../../../components/audio/provider/audio-effects-provider';
import { lokalisation } from '../../../../helpers/lokalisation';
import coinAnimation from './Coin.json';
import {
    CoinTotalContainer, PointsContainer
} from './collect-coins.styled';
import mCoin from './m_coin_outlined.svg';
import wingsAnimation from './Wings.json';

export const CollectCoins: React.FC<{
    coins: number
    points: number
    coinAnimationRef: LottieRef
    coinsTotalRef: RefObject<HTMLDivElement>
    coinsTotalDisplayRef: RefObject<HTMLDivElement>
    pointsTotalRef: RefObject<HTMLDivElement>
    wingsAnimationRef: LottieRef
    onComplete: () => void
}> = ({
    coins,
    points,
    coinAnimationRef,
    coinsTotalRef,
    coinsTotalDisplayRef,
    pointsTotalRef,
    wingsAnimationRef,
    onComplete
}) => {
    const {
        labelTxt: {
            collectCoinsHeading,
            ...labelTxt
        }
    } = lokalisation();
    const { beforeCollectingCoins } = useAudioEffectsContext();

    useEffect(() => {
        beforeCollectingCoins.play();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box className={'message-content'}>
            <Box className={'message-body'}>
                <SlideFade in transition={{ enter: { duration: 1 } }} offsetY={'40px'}>
                    <Box className={'header'} mr={'20px'}>{collectCoinsHeading}</Box>
                </SlideFade>
                <CoinTotalContainer>
                    <Fade in initial={{ opacity: 0 }} transition={{ enter: { duration: 2 } }}><Center>
                        <Box
                            mt={{
                                base: '24px',
                                lg: '40px'
                            }}
                            w={{
                                base: '303px',
                                lg: '343px'
                            }}
                            h={{
                                base: '48px',
                                lg: '56px'
                            }}
                            mx={'20px'}
                            border={'2px solid transparent'}
                            borderRadius={'full'}
                            overflow={'hidden'}
                            // eslint-disable-next-line max-len
                            bg={'linear-gradient(270deg, #D99800 0%, #FFCE01 23.48%, #FFF501 48.03%, #FFCE01 78.25%, #FFC601 100%) border-box'}
                            ref={coinsTotalRef}
                        >
                            <Flex bg={'white'} w={'full'} h={'full'} alignItems={'center'} justifyContent={'center'}>
                                <Flex
                                    fontSize={{
                                        base: '24px',
                                        lg: '28px'
                                    }}
                                    lineHeight={'155%'}
                                    color={'neutral.900'}
                                    alignItems={'center'}
                                >
                                    <Image src={mCoin} mr={'20px'} />
                                    <div className={'coin-total'} ref={coinsTotalDisplayRef}>{coins}</div>
                                </Flex>
                            </Flex>
                        </Box>
                    </Center></Fade>
                </CoinTotalContainer>
                <Box
                    position={'relative'}
                >
                    <Box
                        display={'flex'}
                        position={'absolute'}
                        justifyContent={'center'}
                        w={'full'}
                        maxH={'100%'}
                        __css={{
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            '& > div': {
                                width: {
                                    base: '200px',
                                    phoneLandscape: '300px'
                                }
                            }
                        }}
                    >
                        <Lottie
                            lottieRef={coinAnimationRef}
                            animationData={coinAnimation}
                            initialSegment={[0, 50]}
                            loop={false}
                            onEnterFrame={(frame) => {
                                // @ts-ignore
                                if (frame.totalTime === 36 && frame.currentTime === 35) {
                                    onComplete();
                                }
                            }}
                        />
                    </Box>
                    <Box
                        display={'flex'}
                        alignItems={'flex-end'}
                        minH={{
                            base: '325px',
                            lg: '400px'
                        }}
                        maxH={{
                            md: '500px',
                            phoneLandscape: '425px'
                        }}
                        w={'full'}
                        __css={{
                            /* eslint-disable-next-line @typescript-eslint/naming-convention */
                            '& > div': { flexGrow: 1 }
                        }}
                    >
                        <Lottie
                            lottieRef={wingsAnimationRef}
                            animationData={wingsAnimation}
                            initialSegment={[0, 50]}
                            loop={false}
                        />
                    </Box>
                    <SlideFade
                        in
                        transition={{
                            enter: {
                                duration: 1,
                                delay: 0.5
                            }
                        }}
                        offsetY={0}
                    >
                        <PointsContainer>
                            <Flex
                                bg={'white'}
                                boxShadow={'0px 0px 4.92526px 0.820876px rgba(11, 55, 83, 0.08)'}
                                borderRadius={'16px'}
                                w={{
                                    base: '303px',
                                    lg: '343px'
                                }}
                                h={{
                                    base: '112px',
                                    lg: '120px'
                                }}
                                mx={'20px'}
                                alignItems={'center'}
                                justifyContent={'space-around'}
                                zIndex={5}
                            >
                                <Flex
                                    color={'neutral.900'}
                                    flexDir={'column'}
                                    alignItems={'center'}
                                >
                                    <Flex
                                        fontSize={{
                                            base: '38px',
                                            lg: '44px'
                                        }}
                                        lineHeight={'155%'}
                                        alignItems={'center'}
                                        h={'48px'}
                                    >
                                        {points}
                                    </Flex>
                                    <Text fontSize={'16px'}>{labelTxt.points}</Text>
                                </Flex>
                                <Text fontSize={'44px'} color={'neutral.400'}>=</Text>
                                <Flex
                                    color={'neutral.900'}
                                    flexDir={'column'}
                                    alignItems={'center'}
                                >
                                    <Flex
                                        fontSize={{
                                            base: '38px',
                                            lg: '44px'
                                        }}
                                        lineHeight={'155%'}
                                        alignItems={'center'}
                                        h={'48px'}
                                    >
                                        <div ref={pointsTotalRef} className={'coin-total'}>{points}</div>
                                        <Image w={'28px'} src={mCoin} ml={'10px'} />
                                    </Flex>
                                    <Text fontSize={'16px'}>{labelTxt.coins}</Text>
                                </Flex>
                            </Flex>
                        </PointsContainer>
                    </SlideFade>
                </Box>
            </Box>
        </Box>
    );
};
