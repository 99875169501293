import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';

import packageJson from '../../../package.json';

export const BuildNumber = () => {
    const [showMore, setShowMore] = useState(false);

    return (
        <Box
            position={'fixed'}
            zIndex={9999}
            left={0}
            bottom={0}
            color={'#777'}
            fontSize={'12px'}
            padding={'10px'}
            onClick={() => setShowMore(!showMore)}
        >
            <span>{window._env_.BUILD_NUMBER === '' ? 'local' : window._env_.BUILD_NUMBER}</span>
            {
                showMore && (
                    <>
                        <span>{' | '}</span>
                        <span>{packageJson.dependencies['@3plearning/question-components-library']}</span>
                    </>
                )
            }
        </Box>
    );
};
