import { Box } from '@chakra-ui/react';
import React from 'react';

import { lokalisation } from '../../../../helpers/lokalisation';

export const ExceededAttemptsFinishScreen: React.FC = () => {
    const { exceededAttemptsFinishScreenTxt } = lokalisation();

    return (
        <Box className={'message-content'}>
            <Box className={'message-body'}>
                <div className={'header'} data-testid={'header2'}>{exceededAttemptsFinishScreenTxt.title}</div>
                <Box
                    className={'milestone-description'}
                    mb={14}
                >{exceededAttemptsFinishScreenTxt.description}</Box>
            </Box>
        </Box>
    );};
