import { i18n } from '@lingui/core';
import { I18nProvider as LinguiI18nProvider } from '@lingui/react';
import React, { PropsWithChildren } from 'react';

import { useActivateI18n } from './hooks/use-activate-i18n';

export function I18nProvider ({ children }: PropsWithChildren<unknown>) {
    const {
        isLoading, isError
    } = useActivateI18n();

    if (isLoading || isError) {
        // TODO put a custom design in here
        // return <p>Unable to load locale data</p>;
        return <></>;
    }

    return (
        <LinguiI18nProvider i18n={i18n}>
            {children}
        </LinguiI18nProvider>
    );
}
