/* eslint-disable */
import { useWithMobile } from '../../hooks/use-with-mobile';

export const useDiagnosticsResponsiveStyles = () => {
    const {
        landscape,
        isMobile,
        isTablet
    } = useWithMobile();
    const diagnosticsResponsiveStyles = () => {
        let styles = null;

        if ((isMobile || (landscape && isMobile)) && !isTablet) {
            styles = {
                overflow: 'auto'
            };
        }

        return styles;
    };

    return { diagnosticsResponsiveStyles };
};
