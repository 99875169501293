/* istanbul ignore file */
// https://github.com/gilbarbara/react-inlinesvg/issues/145

import React from 'react';
import InlineSVG, { Props as InlineSVGProps } from 'react-inlinesvg';

import { AppImages } from './app-image-types';

export type AppIconTypeObject = typeof AppImages;

export type AppIconTypeKeys = keyof AppIconTypeObject;

type AppImageBaseProps = {
    iconType: AppIconTypeKeys
    ['aria-label']?: string
};

type SVGProps =
    & { imageType: 'svg' }
    & AppImageBaseProps;

type ImgProps =
    & { imageType: 'img' }
    & AppImageBaseProps;

export type AppImageProps =
    | ImgProps & Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src'>
    | SVGProps & Omit<InlineSVGProps, 'src'>;

const AppImage = ({
    iconType, imageType, ...restProps
}: AppImageProps) => {
    const src = AppImages?.[iconType];

    if (!src) {
        console.error('Unknown icon', iconType);

        return null;
    } else if (imageType === 'svg') {
        return (
            <InlineSVG
                {...restProps as SVGProps}
                role={'img'}
                data-testid={'AppImage'}
                src={src}
                key={src}
                uniquifyIDs={true}
            />
        );
    } else {
        return (
            <img
                {...restProps as ImgProps}
                data-testid={'AppImage'}
                src={src} alt={src}
            />
        );
    }
};

export default AppImage;
