import {
    Global, useTheme
} from '@emotion/react';
import React from 'react';

import { baseAppStyle } from './app';
import { fonts } from './fonts';
import { normalize } from './normalize';
import { reset } from './reset';

export const GlobalStyles = () => {
    const theme = useTheme();

    return (
        <>
            <Global
                styles={[reset, normalize, fonts]}
            />
            <Global styles={() => baseAppStyle(theme)} />
        </>
    );
};
