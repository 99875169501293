/* istanbul ignore file */
import { APP_CONFIG } from '../../config';
import { Credentials } from '../../types/common.types';

// eslint-disable-next-line @typescript-eslint/no-var-requires,import/no-commonjs
const environment = require('@3plearning/resource');

export class BasicResources {
    static initialise (envName: string, credentials: Credentials) {
        const env = environment(envName, APP_CONFIG.appName);

        if (credentials.authToken) {
            env.authToken = credentials.authToken;
            env.jwtToken = credentials.JWT;
        } else if (credentials.username && credentials.password) {
            env.username = credentials.username;
            env.password = credentials.password;
        } else {
            // The caller should have provided a valid set of credentials, so
            // hopefully this will never happen.
            console.error(
                'BasicResources.initialise() - to execute, we need either an authToken or' +
                ' a username/password pair.  The credentials object that was provided does not' +
                ' contain either.  So, expect things to fail badly :-(.'
            );
        }

        return env;
    }
}
