import {
    Box, Flex, HStack
} from '@chakra-ui/react';
import React from 'react';

import { MAX_ATTEMPTS } from '../../../../../../helpers/constants';
import { lokalisation } from '../../../../../../helpers/lokalisation';
import { ProgressHeading } from '../progress-bar/progress-bar.styled';
import { ProgressStars } from './progress-stars';
import { StarRatingContainerProps } from './star-rating.types';
import { StarTooltip } from './star-tooltip';

export function StarRatingContainer ({
    totalQuestions,
    totalStars,
    playStarsAnim
}: StarRatingContainerProps) {
    const totalMaxStars = totalQuestions * MAX_ATTEMPTS;
    const {
        summaryTxt: {
            stars, overallRating
        }
    } = lokalisation();

    return (
        <Box
            data-testid={'stars-box'}
            mt={'10px'}
            w={{
                base: '100%',
                md: 'unset'
            }}
            bg={'white'}
        >
            <ProgressHeading>
                <HStack>
                    <Flex
                        justifyContent={'space-between'}
                        w={'100%'}
                    >
                        <HStack data-testid={'rating'}>
                            <div className={'label'} >
                                {overallRating}
                            </div>
                            <div className={'info'}>{`${totalStars}/${totalMaxStars}`}</div>
                            <div className={'info'}>{stars}</div>
                        </HStack>
                        <StarTooltip />
                    </Flex>
                </HStack>
            </ProgressHeading>
            <ProgressStars
                totalStars={totalStars}
                playStarsAnim={playStarsAnim}
            />
        </Box>
    );
}
