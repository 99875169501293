import React, {
    useEffect, useState
} from 'react';

import AppImage from '../../../../components/app-image/app-image';
import { AppImageTypes } from '../../../../components/app-image/app-image-types';
import {
    PROGRESS_STATE, MilestoneState
} from '../../../../helpers/enums';
import { lokalisation } from '../../../../helpers/lokalisation';
import { isDefined } from '../../../../helpers/utils';
import {
    ProgressMilestoneStyled, WhiteDot
} from './progress-milestone.styled';

export type ProgressMilestoneProps = {
    progressType?: PROGRESS_STATE
    milestoneState?: number
};

export type ProgressMileStonePropsWithState = {
    stepsAnswered: number
} & ProgressMilestoneProps;

export const ProgressMilestone = ({
    stepsAnswered, progressType = PROGRESS_STATE.fluency
}: ProgressMileStonePropsWithState) => {
    const {
        fluency, reasoning, problemSolving
    } = PROGRESS_STATE;
    const [milestoneState, setMilestoneState] = useState<number>(MilestoneState.notStarted);
    const [iconType, setIconType] = useState<AppImageTypes>();
    const [label, setLabel] = useState<string>();
    const { progressTxt } = lokalisation();

    useEffect(() => {
        if (isDefined(stepsAnswered)) {
            switch(progressType) {
            case fluency: {
                if (stepsAnswered >= 5) {
                    setMilestoneState(MilestoneState.completed);
                    setIconType('tick');
                } else {
                    setMilestoneState(MilestoneState.inProgress);
                    setIconType('pinIcon');
                }
                // @ts-ignore
                setLabel(progressTxt[0]);
            }
                break;
            case reasoning: {
                if (stepsAnswered >= 8) {
                    setMilestoneState(MilestoneState.completed);
                    setIconType('tick');
                } else if (stepsAnswered >= 5) {
                    setMilestoneState(MilestoneState.inProgress);
                    setIconType('pinIcon');
                } else {
                    setMilestoneState(MilestoneState.notStarted);
                }
                // @ts-ignore
                setLabel(progressTxt[1]);
            }
                break;
            case problemSolving: {
                if (stepsAnswered === 10) {
                    setMilestoneState(MilestoneState.completed);
                    setIconType('tick');
                } else if (stepsAnswered >= 8) {
                    setMilestoneState(MilestoneState.inProgress);
                    setIconType('pinIcon');
                } else {
                    setMilestoneState(MilestoneState.notStarted);
                }
                // @ts-ignore
                setLabel(progressTxt[2]);
            }
                break;
            }
        }
    }, [stepsAnswered, fluency, problemSolving, progressTxt, progressType, reasoning]);

    return (
        <ProgressMilestoneStyled
            data-testid={'progress-milestone-component'}
            data-attribute={progressType}
            progressType={progressType}
            milestoneState={milestoneState}
        >
            <div className={'milestone-content'}>
                <div className={'pin-holder'}>
                    {iconType && <AppImage imageType={'svg'} iconType={iconType} className={iconType} />}
                    <WhiteDot milestoneState={milestoneState} />
                </div>
            </div>

            <div className={'label'}>{label}</div>
        </ProgressMilestoneStyled>
    );
};
