import styled from '@emotion/styled';

import { pickThemeVar } from '../../styles/theme/app-theme';
import AppImage from '../app-image/app-image';

const correctAnswerGuideSize = '48px';
const guideDimensions = `
    width: ${correctAnswerGuideSize};
    height: ${correctAnswerGuideSize};
`;
const wrapperPadding = '8px';

export const CorrectAnswerWrapper = styled.div`
    border: 1px solid ${pickThemeVar('green200')};
    border-radius: 50%;
    padding: 0;
    margin-top: 32px;
    position: relative;
    overflow: hidden;
    width: 0;
    height: 0;
    animation: grow 0.7s forwards;

    @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
        font-size: 18px;
        margin-left: -${pickThemeVar('questionBodyPaddingMobile')};
        margin-right: -${pickThemeVar('questionBodyPaddingMobile')};
    }

    @keyframes grow {
        43% {
            ${guideDimensions}
            border-radius: 50%;
        }
        44% {
            border-radius: 20px;
        }
        50% {
            padding: ${wrapperPadding};
            width: 200px;
            height: 100px;
        }
        99% {
            width: 90%;
        }
        100% {
            width: auto;
            height: auto;
            padding: ${wrapperPadding};
            border-radius: 20px;
        }
    }
`;

export const CorrectAnswerBody = styled.div`
    opacity: 0;
    animation: appear 0.4s forwards 0.3s;

    @keyframes appear {
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

export const CorrectAnswerGuide = styled(AppImage)`
    position: absolute;
    transform: translate(0, 0);
    z-index: 1;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    animation: guide-grow 0.7s forwards;

    @keyframes guide-grow {
        43% {
            ${guideDimensions}
            transform: translate(${wrapperPadding}, ${wrapperPadding});
        }
        100% {
            ${guideDimensions}
            transform: translate(${wrapperPadding}, ${wrapperPadding});
        }
    }
`;

export const CorrectAnswerHeader = styled.h2(props => {
    const {
        theme: {
            app: {
                green04,
                green50,
                questionBodyPadding,
                questionNumberWidth
            }
        }
    } = props;

    return `
        height: ${correctAnswerGuideSize};
        line-height: ${correctAnswerGuideSize};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-radius: 20px;
        border-bottom-right-radius: 0;
        font-size: 20px;
        background-color: ${green50};
        color: ${green04};
        padding-inline-start: calc(${questionBodyPadding} + ${questionNumberWidth});
        padding-inline-end: 10px;

        @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')(props)}) {
            font-size: 18px;
        }
    `;
});

export const CorrectAnswerContentWrapper = styled.div`
    padding: 24px 16px 18px;
    max-width: 100%;
    overflow: auto;

    @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
        padding-bottom: 16px;
    }

    @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
        padding: 20px 12px 12px;
    }
`;
