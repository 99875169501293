export const para = {
    fontSize: {
        base: '16px',
        break680: '20px'
    },
    letterSpacing: '-0.2px',
    lineHeight: '28px',
    color: 'neutral.600',
    textAlign: 'start',
    fontWeight: '400'
};
