import { Button } from '@chakra-ui/react';
import { LottieRef } from 'lottie-react';
import React, {
    useEffect, useRef, useState
} from 'react';
import {
    useDispatch, useSelector
} from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppState } from '../../../../app/store/store.types';
import { useAudioEffectsContext } from '../../../../components/audio/provider/audio-effects-provider';
import {
    setQuestionProgress, setStudentState
} from '../../../../containers/question-container/store/question-actions';
import { MAX_QUESTION_SET_ATTEMPT } from '../../../../helpers/constants';
import {
    QuestionProgressState, STUDENT_STATES, TransitionScreen, QuestionSetType
} from '../../../../helpers/enums';
import { lokalisation } from '../../../../helpers/lokalisation';
import {
    getPoints, getQueryParam, getTotalNumStars, isDefined
} from '../../../../helpers/utils';
import { useExitApp } from '../../../../hooks/use-exit-app';
import { Resources } from '../../../../resources/resources';
import { ROUTES } from '../../../routes';
import { CollectCoins } from '../collect-coins/collect-coins';
import { DiagnosticsIntro } from '../diagnostics-intro/diagnostics-intro';
import { DiagnosticsSummary } from '../diagnostics-summary/diagnostics-summary';
import { ExceededAttemptsFinishScreen } from '../exceeded-attempts-finish-screen/exceeded-attempts-finish-screen';
import { FeedbackScreen } from '../feedback-screen/feedback-screen';
import { IntroScreen } from '../intro-screen/intro-screen';
import { MessageBoxProps } from '../message-box/message-box';
import { MidMilestoneScreen } from '../mid-milestone-screen/mid-milestone-screen';
import { Summary } from '../summary/summary';
import { UnitTestSummary } from '../unit-test-summary/unit-test-summary';
import { UnitTestsIntro } from '../unit-tests-intro/unit-tests-intro';
import {
    MilestoneMessageStyled, Footer
} from './milestone-message.styled';

export const MilestoneMessage = ({ type }: MessageBoxProps) => {
    const { redirect } = useExitApp();
    const history = useHistory();
    const dispatch = useDispatch();
    const coinAnimationRef: LottieRef = useRef(null);
    const wingsAnimationRef: LottieRef = useRef(null);
    const coinsTotalRef = useRef<HTMLDivElement>(null);
    const coinsTotalDisplayRef = useRef<HTMLDivElement>(null);
    const pointsTotalRef = useRef<HTMLDivElement>(null);
    const milestoneFooterBtnRef = useRef<HTMLButtonElement>(null);
    const totalMilestonePoints = useRef<number | undefined>(undefined);
    const {
        transToScreenTxt, progressTxt, buttonTxt, messageBoxTxt
    } = lokalisation();
    const {
        problemSolvingHeader1, problemSolvingHeader2, problemSolvingDescription, nextStage,
        nextStageStart, reasoningHeader1, reasoningHeader2, reasoningDescription
    } = transToScreenTxt;
    const {
        totalPoints, fluencyDescription, fluencyHeader, fluencyHeaderForExceedingMaximumAttempts, questions, noPointsEarnTxt
    } = messageBoxTxt;
    /*
     * ACTIVITY STATE
     */
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const {
        studentName, questName, progress, praiseFeedback, updatedProgress, unitOfWork, questionSetType
    } = activityState;
    const { firstName } = studentName || {};
    /*
    * ENUMS
    */
    const {
        startScreen,
        startFluency,
        startReasoning,
        startProblemSolving,
        summaryScreen,
        finishScreen,
        exceededAttemptsFinishScreen,
        feedbackScreen,
        diagnosticsStartScreen,
        diagnosticsSummaryScreen,
        unitTestIntroScreen,
        unitTestSummaryScreen
    } = TransitionScreen;
    /*
     * STATE
     */
    const [feedback, setFeedback] = useState<string>();
    const [header, setHeader] = useState<string>();
    const [header2, setHeader2] = useState<string>();
    const [milestoneHeader, setMilestoneHeader] = useState<string>();
    const [milestoneDescription, setMilestoneDescription] = useState<string>();
    const [buttonLabel, setButtonLabel] = useState<string>();
    const [nextStageLabel, setNextStageLabel] = useState<string>();
    const [hasMilestoneSummary, setHasMilestoneSummary] = useState<boolean>(false);
    const [numMilestoneQuestions, setNumMilestoneQuestions] = useState<number>();
    const questionProgress = updatedProgress || progress;
    const totalCorrect = questionProgress?.points?.reduce((total, section) => total + section.stars.filter(Boolean).length, 0) || 0;
    const totalQuestions = questionProgress?.points?.reduce((total, section) => total + section.stars.length, 0) || 10;
    const maximumAttemptExceeded = (questionProgress?.completedAttempts ?? 0) >= MAX_QUESTION_SET_ATTEMPT;
    const {
        playClick, playAllStarsSpin, playClickWithCollectCoins
    } = useAudioEffectsContext();

    /*
     * LABELS
     */
    useEffect(() => {
        switch (type) {
        case startScreen:
            setButtonLabel(buttonTxt.next);
            break;
        case startFluency: {
            totalMilestonePoints.current = getNextStageTotalPoints('Fluency');

            setButtonLabel(buttonTxt.start);
            setNextStageLabel(nextStageStart);
            setMilestoneDescription(fluencyDescription);
            setMilestoneHeader(progressTxt[0]);
            setHasMilestoneSummary(true);
            setHeader(maximumAttemptExceeded ? fluencyHeaderForExceedingMaximumAttempts : fluencyHeader);
            setNumMilestoneQuestions(5);
            break;
        }
        case startReasoning: {
            totalMilestonePoints.current = getNextStageTotalPoints('Reasoning');

            setHeader(`${reasoningHeader1} ${firstName}!`);
            setHeader2(reasoningHeader2);
            setNextStageLabel(nextStage);
            setMilestoneDescription(reasoningDescription);
            setMilestoneHeader(progressTxt[1]);
            setHasMilestoneSummary(true);
            setNumMilestoneQuestions(3);
            setButtonLabel(buttonTxt.ready);
            break;
        }
        case startProblemSolving: {
            totalMilestonePoints.current = getNextStageTotalPoints('ProblemSolving');

            setHeader(`${problemSolvingHeader1} ${firstName}!`);
            setHeader2(problemSolvingHeader2);
            setNextStageLabel(nextStage);
            setMilestoneHeader(progressTxt[2]);
            setMilestoneDescription(problemSolvingDescription);
            setHasMilestoneSummary(true);
            setNumMilestoneQuestions(2);
            setButtonLabel(buttonTxt.ready);
            break;
        }
        case finishScreen: {
            setButtonLabel(buttonTxt.collect);
            break;
        }
        case exceededAttemptsFinishScreen: {
            setButtonLabel(buttonTxt.next);
            break;
        }
        case feedbackScreen: {
            setButtonLabel(buttonTxt.finish);
            if (milestoneFooterBtnRef.current && !feedback) {
                milestoneFooterBtnRef.current.disabled = true;
            }
            break;
        }
        case unitTestIntroScreen:
        case diagnosticsStartScreen: {
            setButtonLabel(buttonTxt.start);
        }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buttonTxt.ready, buttonTxt.next, buttonTxt.start, buttonTxt.finish, buttonTxt.collect, startReasoning, startProblemSolving,
        firstName, problemSolvingDescription, problemSolvingHeader1, problemSolvingHeader2, progressTxt, questName, reasoningHeader1,
        reasoningHeader2, startFluency, startScreen, studentName, type, nextStage, reasoningDescription]);

    useEffect(() => {
        type !== feedbackScreen ? milestoneFooterBtnRef.current?.focus({ preventScroll: true }) : milestoneFooterBtnRef.current?.blur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    function animateValue (obj: HTMLElement, start: number, end: number, duration: number) {
        let startTimestamp: number | null = null;

        const step = (timestamp: number) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);

            obj.innerHTML = Math.floor(progress * (end - start) + start).toString();
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };

        window.requestAnimationFrame(step);
    }

    const nextView = async () => {
        const coins = (updatedProgress || progress)?.totalCoinsCount || 0;
        const points = getPoints(updatedProgress || progress) || 0;
        const totalStars = getTotalNumStars(updatedProgress || progress) || 0;

        switch (type) {
        case startScreen:
            dispatch(setStudentState(STUDENT_STATES.fluencyIntro));
            history.replace(`${ROUTES.milestoneTransition}/${TransitionScreen.startFluency}`);
            playClick();
            break;
        case startFluency:
            dispatch(setStudentState(STUDENT_STATES.question));
            dispatch(setQuestionProgress(QuestionProgressState.nextUnanswered));
            history.replace(ROUTES.questionConsole);
            playClick();
            break;
        case startReasoning:
        case startProblemSolving:
            dispatch(setQuestionProgress(QuestionProgressState.nextUnanswered));
            history.replace(ROUTES.questionConsole);
            playAllStarsSpin(totalStars);
            break;
        case finishScreen:
            if  (
                coinAnimationRef.current &&
                wingsAnimationRef.current &&
                milestoneFooterBtnRef.current &&
                coinsTotalDisplayRef.current &&
                pointsTotalRef.current
            ) {
                milestoneFooterBtnRef.current.disabled = true;
                coinAnimationRef.current.playSegments([140, 176], true);
                wingsAnimationRef.current.playSegments([140, 176], true);
                coinsTotalRef.current?.classList.add('increment');
                animateValue(coinsTotalDisplayRef.current, coins, (coins + points), 1000);
                animateValue(pointsTotalRef.current, points, 0, 1000);
                playClickWithCollectCoins();
            }
            break;
        case exceededAttemptsFinishScreen:
            history.replace(`${ROUTES.milestoneTransition}/${TransitionScreen.feedbackScreen}`);
            playClick();
            break;
        case feedbackScreen: {
            playClick();
            if (isDefined(feedback)) {
                const questionSetId = getQueryParam('questionSetId') || '';
                const locale = getQueryParam('locale') || 'en-AU';
                const assignmentId = questionProgress?.assignmentId;

                await Resources.sendFeedback.post().withBody({
                    questionSetId,
                    assignmentId,
                    studentFeedback: parseInt(feedback, 10),
                    systemInfo: JSON.stringify({
                        locale,
                        userAgent: window.navigator.userAgent,
                        resolution: `${window.screen.width} X ${window.screen.height}`
                    })
                }).raw().send();
            }
            redirect();
            break;
        }
        case unitTestIntroScreen:
        case diagnosticsStartScreen: {
            playClick();
            dispatch(setStudentState(STUDENT_STATES.question));
            history.replace(ROUTES.diagnostics);
        }
        }
    };
    const onAnimationComplete = async () => {
        if (questionSetType === QuestionSetType.questiconActivity) {
            if (milestoneFooterBtnRef.current) {
                milestoneFooterBtnRef.current.disabled = false;
            }
            history.replace(`${ROUTES.milestoneTransition}/${TransitionScreen.feedbackScreen}`);
        } else {
            redirect();
        }
    };
    const handleFeedbackChange = (feedback: string) => {
        setFeedback(feedback);
        if (milestoneFooterBtnRef.current) {
            milestoneFooterBtnRef.current.disabled = false;
        }
    };

    function getNextStageTotalPoints (sectionName: 'Fluency' | 'Reasoning' | 'ProblemSolving'): number | undefined {
        const sectionPointInfo = progress?.points?.find(point => point.sectionName === sectionName);

        if (!sectionPointInfo) return;

        const pointsPerQuestion = sectionPointInfo.pointsPerQuestion;
        const numberOfQuestions = sectionPointInfo.stars.length;

        return pointsPerQuestion * numberOfQuestions;
    }

    return (
        <MilestoneMessageStyled
            data-testid={type}
            type={type}
            shouldShowStripedBorder={totalCorrect === totalQuestions}
            maximumAttemptExceeded={Boolean(maximumAttemptExceeded)}
        >
            {type === startScreen &&
            <IntroScreen />
            }
            {type === diagnosticsStartScreen &&
                <DiagnosticsIntro
                    firstName={firstName}
                    activityName={unitOfWork || ''}
                    maximumAttemptExceeded={maximumAttemptExceeded}
                />
            }
            {type === unitTestIntroScreen &&
                <UnitTestsIntro
                    uowTitle={unitOfWork || ''}
                    activityName={questName || ''}
                    maximumAttemptExceeded={maximumAttemptExceeded}
                />
            }
            {
                type === finishScreen && <CollectCoins
                    coins={(updatedProgress || progress)?.totalCoinsCount || 0}
                    points={getPoints(updatedProgress || progress) || 0}
                    coinAnimationRef={coinAnimationRef}
                    wingsAnimationRef={wingsAnimationRef}
                    coinsTotalRef={coinsTotalRef}
                    coinsTotalDisplayRef={coinsTotalDisplayRef}
                    pointsTotalRef={pointsTotalRef}
                    onComplete={onAnimationComplete}
                />
            }
            {type === TransitionScreen.exceededAttemptsFinishScreen && <ExceededAttemptsFinishScreen />}
            {type === feedbackScreen && <FeedbackScreen
                handleChange={handleFeedbackChange}
                questionsetTitle={questName}
            />}
            {(type === startFluency || type === startReasoning || type === startProblemSolving) &&
            <MidMilestoneScreen
                hasMilestoneSummary={hasMilestoneSummary}
                header={header}
                header2={header2}
                milestoneDescription={milestoneDescription}
                milestoneHeader={milestoneHeader}
                nextStageLabel={nextStageLabel}
                noPointsEarnTxt={maximumAttemptExceeded && type === TransitionScreen.startFluency ? noPointsEarnTxt : undefined}
                numMilestoneQuestions={numMilestoneQuestions}
                questionsTxt={questions}
                questName={questName}
                totalMilestonePoints={totalMilestonePoints.current}
                totalPointsTxt={totalPoints}
            />
            }
            {type === summaryScreen &&
            <Summary
                progress={updatedProgress || progress}
                studentName={firstName}
                questName={questName}
                praiseFeedback={praiseFeedback}
            />}

            {/* completedAttempts is incremented from BE after final question is completed. Show summary for completedAttempts: 1 - 5 */}
            {type === diagnosticsSummaryScreen &&
            <DiagnosticsSummary
                studentName={firstName}
                totalCorrect={totalCorrect}
                totalQuestions={totalQuestions}
                maximumAttemptExceeded={(questionProgress?.completedAttempts ?? 0) > MAX_QUESTION_SET_ATTEMPT}
            />}

            {type === unitTestSummaryScreen &&
                <UnitTestSummary
                    uowTitle={unitOfWork || ''}
                    progress={updatedProgress || progress}
                    questName={questName || ''}
                    totalCorrect={totalCorrect}
                    totalQuestions={totalQuestions}
                />}

            {(type !== summaryScreen && type !== diagnosticsSummaryScreen && type !== unitTestSummaryScreen) &&
            <Footer type={type}>
                <Button
                    ref={milestoneFooterBtnRef}
                    colorScheme={'primary'}
                    className={'btn'}
                    data-testid={'show-question-button'}
                    onClick={nextView}
                    id={'milestone-footer-btn'}
                >
                    {buttonLabel}
                </Button>
            </Footer>
            }
        </MilestoneMessageStyled>
    );
};
