import styled from '@emotion/styled';

import {
    pickThemeFlex, pickThemeVar
} from '../../styles/theme/app-theme';

export const MilestoneTransitionStyled = styled.div`
  ${pickThemeFlex('flexColumnCentered')};

  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  /**
   PHONE LANDSCAPE
   PHONE PORTRAIT
   */
   @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) and (orientation:landscape), 
   (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
      height: auto;
      min-height: 100%;
   }

  .transition-content {
    z-index: 1;
    margin-top: 30px;
    width: 100%;
    max-height: calc(100% - ${pickThemeVar('headerHeight')} - 30px);
    position: relative;

    /* ipad air 4 to ipad 8th */
    @media only screen
      and (-webkit-min-device-pixel-ratio: 2)
      and (orientation: landscape)
      and (min-width: 1010px)
      and (max-width: 1180px)
    {
      height: 100%;
    }
    /**
    LANDSCAPE
    */
    @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
      margin-top: 16px;
      max-height: calc(100% - ${pickThemeVar('headerHeight')} - 16px);
    }
  
    /**
    PORTRAIT
     */
    @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
      ${pickThemeFlex('flexColumn')};
      margin-top: 15px;
      max-height: calc(100% - ${pickThemeVar('headerHeight')} - 21px);
    }

    /**
     PHONE LANDSCAPE
     */
    @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) and (orientation:landscape) {
      ${pickThemeFlex('flexColumn')};
      margin-top: 21px;
      max-height: none;
    }
  }
`;

export const ConfettiHolderStyled = styled.div`
  ${pickThemeFlex('flexChildCentered')};
  
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
