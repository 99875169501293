import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Confetti from '../../../assets/lottie/Confetti Burst.json';
import { AppState } from '../../app/store/store.types';
import { useAudioEffectsContext } from '../../components/audio/provider/audio-effects-provider';
import { Header } from '../../components/header/header';
import { TransitionScreen } from '../../helpers/enums';
import { useLottieAnim } from '../../hooks/use-lottie-anim';
import { useRouteChecker } from '../../hooks/use-route-checker';
import { MilestoneTransitionType } from '../routes.types';
import { useConfetti } from './hook/use-confetti';
import {
    MilestoneTransitionStyled, ConfettiHolderStyled
} from './milestone-transition.styled';
import { MessageBox } from './sub-components/message-box/message-box';
import { TransitionBg } from './sub-components/transition-bg/transition-bg';

export const MilestoneTransition = () => {
    const { questionSetType } = useRouteChecker();
    const { type = '' } = useParams<MilestoneTransitionType>();
    const screenType = parseInt(type);
    const showConfetti = useConfetti();
    const isSummaryScreen = screenType === TransitionScreen.summaryScreen;
    const isTransitionScreens = screenType === TransitionScreen.startFluency ||
        screenType === TransitionScreen.startReasoning ||
        screenType === TransitionScreen.startProblemSolving;
    const { View: ConfettiBurst } = useLottieAnim({ animationData: Confetti });
    const { playTransitionScreenLoad } = useAudioEffectsContext();
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const { progress } = activityState;
    const isFluencyFailModalInActive = progress?.isFluencyStepPassed !== undefined && progress?.isFluencyStepPassed === true;

    useEffect(() => {
        if (isTransitionScreens && isFluencyFailModalInActive) {
            playTransitionScreenLoad();
        }
    }, [isFluencyFailModalInActive, isTransitionScreens, playTransitionScreenLoad]);

    return (
        <MilestoneTransitionStyled data-testid={'milestone-transition-screen'}>
            <TransitionBg type={screenType} quesetionSetType={questionSetType} />
            <Header
                showZoomOutBtn={
                    [
                        TransitionScreen.startScreen,
                        TransitionScreen.diagnosticsStartScreen,
                        TransitionScreen.unitTestIntroScreen
                    ].includes(screenType)
                }
                showExitBtn={
                    [
                        TransitionScreen.startScreen,
                        TransitionScreen.diagnosticsStartScreen,
                        TransitionScreen.startFluency,
                        TransitionScreen.startReasoning,
                        TransitionScreen.startProblemSolving,
                        TransitionScreen.unitTestIntroScreen
                    ].includes(screenType)
                }
            />
            <div className={'transition-content'}>
                {showConfetti &&
                    <ConfettiHolderStyled>
                        {isSummaryScreen && ConfettiBurst}
                    </ConfettiHolderStyled>
                }
                <MessageBox type={screenType} questionSetType={questionSetType} />
            </div>
        </MilestoneTransitionStyled>
    );
};
