export const questionSetMock = {
    questName: 'Fractions and Wholes',
    keypadPreset: 0,
    questions: [
        [
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'How many fifths make a whole?'
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: true
                },
                content: [
                    {
                        type: 'image',
                        attrs: {
                            src: 'https://cdn-conquest.mathletics.com/image/QS-2598_1a-QS-2598_1b.svg',
                            id: '721010104',
                            alt: 0,
                            customWidth: 450,
                            customHeight: 91,
                            width: 450,
                            height: 91,
                            viewport: {
                                width: '1',
                                height: '1'
                            },
                            bynderData: [
                                {
                                    id: '1175553638',
                                    width: 800,
                                    height: 161,
                                    isDefaultSize: true
                                },
                                {
                                    id: '721010104',
                                    width: 450,
                                    height: 91,
                                    isDefaultSize: false
                                }
                            ]
                        }
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'response-section',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'inputbox',
                        attrs: {
                            id: 802279677,
                            unmarked: false,
                            maxlength: null,
                            tabIndex: null
                        }
                    },
                    {
                        type: 'text',
                        text: ' fifths make a whole.'
                    }
                ]
            }
        ],
        [
            {
                type: 'paragraph',
                attrs: { subType: 'question-description' },
                content: [
                    {
                        text: 'Look at the fraction wall. ',
                        type: 'text'
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: { subType: 'question-description' },
                content: [
                    {
                        type: 'image',
                        attrs: {
                            id: '1715216048',
                            alt: 0,
                            src: 'https://cdn-conquest.mathletics.com/image/QS-2599_1a-QS-2599_2a-QS-2599_3a.svg',
                            width: 450,
                            height: 315,
                            viewport: {
                                width: '1',
                                height: '1'
                            },
                            bynderData: [
                                {
                                    id: '1687509915',
                                    width: 800,
                                    height: 560,
                                    isDefaultSize: true
                                },
                                {
                                    id: '1715216048',
                                    width: 450,
                                    height: 315,
                                    isDefaultSize: false
                                }
                            ],
                            customWidth: 450,
                            customHeight: 315
                        }
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: { subType: 'question-description' },
                content: [
                    {
                        text: 'How many thirds make a whole?',
                        type: 'text'
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: { subType: 'response-section' },
                content: [
                    {
                        text: ' ',
                        type: 'text'
                    },
                    {
                        type: 'inputbox',
                        attrs: {
                            id: 1281912876,
                            unmarked: false
                        }
                    },
                    {
                        text: ' thirds make a whole. This is the fraction ',
                        type: 'text'
                    },
                    {
                        type: 'numerator-denominator-input',
                        attrs: {
                            id: 1679201148,
                            isMixed: false,
                            unmarked: false,
                            numerator: '',
                            denominator: '3',
                            wholeNumber: ''
                        }
                    },
                    {
                        text: ' .',
                        type: 'text'
                    }
                ]
            }
        ],
        [
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'Eight pieces make a whole.'
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: true
                },
                content: [
                    {
                        type: 'image',
                        attrs: {
                            src: 'https://cdn-conquest.mathletics.com/image/QS-2600_1a.svg',
                            id: '324229066',
                            alt: 0,
                            customWidth: 450,
                            customHeight: 90,
                            width: 450,
                            height: 90,
                            viewport: {
                                width: '1',
                                height: '1'
                            },
                            bynderData: [
                                {
                                    id: '875742328',
                                    width: 800,
                                    height: 160,
                                    isDefaultSize: true
                                },
                                {
                                    id: '324229066',
                                    width: 450,
                                    height: 90,
                                    isDefaultSize: false
                                }
                            ]
                        }
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'response-section',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'The whole written as a fraction is '
                    },
                    {
                        type: 'numerator-denominator-input',
                        attrs: {
                            id: 768784889,
                            unmarked: false,
                            isMixed: false,
                            wholeNumber: '',
                            numerator: '',
                            denominator: '',
                            numeratorMaxlength: null,
                            denominatorMaxlength: null,
                            wholeMaxlength: null,
                            tabIndex: null
                        }
                    },
                    {
                        type: 'text',
                        text: '.'
                    }
                ]
            }
        ],
        [
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'Look at the fraction wall.'
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: true
                },
                content: [
                    {
                        type: 'image',
                        attrs: {
                            src: 'https://cdn-conquest.mathletics.com/image/QS-2601_1a-QS-2601_2a-QS-2601_3a.png',
                            id: '1467360136',
                            alt: 0,
                            customWidth: 450,
                            customHeight: 315,
                            width: 450,
                            height: 315,
                            viewport: {
                                width: '1',
                                height: '1'
                            },
                            bynderData: [
                                {
                                    id: '1465513201',
                                    width: 800,
                                    height: 561,
                                    isDefaultSize: true
                                },
                                {
                                    id: '1467360136',
                                    width: 450,
                                    height: 315,
                                    isDefaultSize: false
                                }
                            ]
                        }
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'Complete the sentence.'
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'response-section',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'One quarter and '
                    },
                    {
                        type: 'inputbox',
                        attrs: {
                            id: 1806406895,
                            unmarked: false,
                            maxlength: null,
                            tabIndex: null
                        }
                    },
                    {
                        type: 'text',
                        text: ' more quarters make a whole. '
                    }
                ]
            }
        ],
        [
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'Look at the fraction wall.'
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: true
                },
                content: [
                    {
                        type: 'image',
                        attrs: {
                            src: 'https://cdn-conquest.mathletics.com/image/QS-2602_1a-QS-2602_2a-QS-2602_3a.svg',
                            id: '1467966586',
                            alt: 0,
                            customWidth: 450,
                            customHeight: 315,
                            width: 450,
                            height: 315,
                            viewport: {
                                width: '1',
                                height: '1'
                            },
                            bynderData: [
                                {
                                    id: '1459654882',
                                    width: 800,
                                    height: 560,
                                    isDefaultSize: true
                                },
                                {
                                    id: '1467966586',
                                    width: 450,
                                    height: 315,
                                    isDefaultSize: false
                                }
                            ]
                        }
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'response-section',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'Three '
                    },
                    {
                        type: 'dropdown',
                        attrs: {
                            id: 1059599393,
                            randomise: false,
                            placeholder: ''
                        },
                        content: [
                            {
                                type: 'multipleChoiceOption',
                                attrs: {
                                    isMultiAnswer: false,
                                    parentType: 'dropdown',
                                    value: '1059599053'
                                },
                                content: [
                                    {
                                        type: 'text',
                                        text: 'quarters'
                                    }
                                ]
                            },
                            {
                                type: 'multipleChoiceOption',
                                attrs: {
                                    isMultiAnswer: false,
                                    parentType: 'dropdown',
                                    value: '1086381436'
                                },
                                content: [
                                    {
                                        type: 'text',
                                        text: 'fifths'
                                    }
                                ]
                            },
                            {
                                type: 'multipleChoiceOption',
                                attrs: {
                                    isMultiAnswer: false,
                                    parentType: 'dropdown',
                                    value: '1090075941'
                                },
                                content: [
                                    {
                                        type: 'text',
                                        text: 'eighths'
                                    }
                                ]
                            },
                            {
                                type: 'multipleChoiceOption',
                                attrs: {
                                    isMultiAnswer: false,
                                    parentType: 'dropdown',
                                    value: '1091922320'
                                },
                                content: [
                                    {
                                        type: 'text',
                                        text: 'tenths'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        type: 'text',
                        text: ' combined with seven '
                    },
                    {
                        type: 'dropdown',
                        attrs: {
                            id: 1146410220,
                            randomise: false,
                            placeholder: ''
                        },
                        content: [
                            {
                                type: 'multipleChoiceOption',
                                attrs: {
                                    isMultiAnswer: false,
                                    parentType: 'dropdown',
                                    value: '1146410132'
                                },
                                content: [
                                    {
                                        type: 'text',
                                        text: 'quarters'
                                    }
                                ]
                            },
                            {
                                type: 'multipleChoiceOption',
                                attrs: {
                                    isMultiAnswer: false,
                                    parentType: 'dropdown',
                                    value: '1170422490'
                                },
                                content: [
                                    {
                                        type: 'text',
                                        text: 'fifths'
                                    }
                                ]
                            },
                            {
                                type: 'multipleChoiceOption',
                                attrs: {
                                    isMultiAnswer: false,
                                    parentType: 'dropdown',
                                    value: '1172268984'
                                },
                                content: [
                                    {
                                        type: 'text',
                                        text: 'eighths'
                                    }
                                ]
                            },
                            {
                                type: 'multipleChoiceOption',
                                attrs: {
                                    isMultiAnswer: false,
                                    parentType: 'dropdown',
                                    value: '1175039352'
                                },
                                content: [
                                    {
                                        type: 'text',
                                        text: 'tenths'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        type: 'text',
                        text: ' make a whole.'
                    }
                ]
            }
        ],
        [
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'This is two quarters of a set.'
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: true
                },
                content: [
                    {
                        type: 'image',
                        attrs: {
                            src: 'https://cdn-conquest.mathletics.com/image/QS-2603_1a-QS-2603_1b.svg',
                            id: '1696186579',
                            alt: 0,
                            customWidth: 300,
                            customHeight: 189,
                            width: 300,
                            height: 189,
                            viewport: {
                                width: '1',
                                height: '1'
                            },
                            bynderData: [
                                {
                                    id: '1914675772',
                                    width: 800,
                                    height: 504,
                                    isDefaultSize: true
                                },
                                {
                                    id: '1696186579',
                                    width: 300,
                                    height: 189,
                                    isDefaultSize: false
                                }
                            ]
                        }
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'response-section',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'The whole set has '
                    },
                    {
                        type: 'inputbox',
                        attrs: {
                            id: 788389940,
                            unmarked: false,
                            maxlength: null,
                            tabIndex: null
                        }
                    },
                    {
                        type: 'text',
                        text: ' triangles.'
                    }
                ]
            }
        ],
        [
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'This is '
                    },
                    {
                        type: 'latex',
                        attrs: { value: '\\frac{1}{4}' }
                    },
                    {
                        type: 'text',
                        text: ' of the dots in an array. '
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: true
                },
                content: [
                    {
                        type: 'image',
                        attrs: {
                            src: 'https://cdn-conquest.mathletics.com/image/QS-2604_1a.svg',
                            id: '544220425',
                            alt: 0,
                            customWidth: 300,
                            customHeight: 53,
                            width: 300,
                            height: 53,
                            viewport: {
                                width: '1',
                                height: '1'
                            },
                            bynderData: [
                                {
                                    id: '1268607190',
                                    width: 800,
                                    height: 140,
                                    isDefaultSize: true
                                },
                                {
                                    id: '544220425',
                                    width: 300,
                                    height: 53,
                                    isDefaultSize: false
                                }
                            ]
                        }
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'How many dots are there in the whole array?'
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'response-section',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'There are '
                    },
                    {
                        type: 'inputbox',
                        attrs: {
                            id: 349361964,
                            unmarked: false,
                            maxlength: null,
                            tabIndex: null
                        }
                    },
                    {
                        type: 'text',
                        text: ' dots in the whole array.'
                    }
                ]
            }
        ],
        [
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        // eslint-disable-next-line max-len
                        text: 'Maio has some chickens. Seven of the chickens laid eggs today. This is one fifth of the total number of chickens. How many chickens does she have? '
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'response-section',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'Maio has '
                    },
                    {
                        type: 'inputbox',
                        attrs: {
                            id: 1805470232,
                            unmarked: false,
                            maxlength: null,
                            tabIndex: null
                        }
                    },
                    {
                        type: 'text',
                        text: ' chickens.'
                    }
                ]
            }
        ],
        [
            {
                type: 'paragraph',
                attrs: { subType: 'question-description' },
                content: [
                    {
                        type: 'text',
                        text: 'An eighth of the seats in a cinema are in the reserved section. There are '
                    },
                    {
                        type: 'latex',
                        attrs: { value: '20' }
                    },
                    {
                        type: 'text',
                        text: ' seats in the reserved section.  '
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: { subType: 'question-description' },
                content: [
                    {
                        type: 'text',
                        text: 'How many total seats are in the cinema? '
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: { subType: 'response-section' },
                content: [
                    {
                        type: 'text',
                        text: 'There are '
                    },
                    {
                        type: 'input-box',
                        attrs: {
                            id: 1439126289,
                            unmarked: false
                        }
                    },
                    {
                        type: 'text',
                        text: ' total seats in the cinema. '
                    }
                ]
            }
        ],
        [
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'Look at the fraction wall.'
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'question-description',
                    ignoreForReading: true
                },
                content: [
                    {
                        type: 'image',
                        attrs: {
                            src: 'https://cdn-conquest.mathletics.com/image/QS-2607_1a-QS-2607_2a-QS-2607_3a.svg',
                            id: '961400827',
                            alt: 0,
                            customWidth: 450,
                            customHeight: 315,
                            width: 450,
                            height: 315,
                            viewport: {
                                width: '1',
                                height: '1'
                            },
                            bynderData: [
                                {
                                    id: '1417425061',
                                    width: 800,
                                    height: 560,
                                    isDefaultSize: true
                                },
                                {
                                    id: '961400827',
                                    width: 450,
                                    height: 315,
                                    isDefaultSize: false
                                }
                            ]
                        }
                    }
                ]
            },
            {
                type: 'paragraph',
                attrs: {
                    subType: 'response-section',
                    ignoreForReading: false
                },
                content: [
                    {
                        type: 'text',
                        text: 'One half and two '
                    },
                    {
                        type: 'dropdown',
                        attrs: {
                            id: 556727698,
                            randomise: true,
                            placeholder: ''
                        },
                        content: [
                            {
                                type: 'multipleChoiceOption',
                                attrs: {
                                    isMultiAnswer: false,
                                    parentType: 'dropdown',
                                    value: '556727444'
                                },
                                content: [
                                    {
                                        type: 'text',
                                        text: 'halves'
                                    }
                                ]
                            },
                            {
                                type: 'multipleChoiceOption',
                                attrs: {
                                    isMultiAnswer: false,
                                    parentType: 'dropdown',
                                    value: '584433485'
                                },
                                content: [
                                    {
                                        type: 'text',
                                        text: 'thirds'
                                    }
                                ]
                            },
                            {
                                type: 'multipleChoiceOption',
                                attrs: {
                                    isMultiAnswer: false,
                                    parentType: 'dropdown',
                                    value: '587203212'
                                },
                                content: [
                                    {
                                        type: 'text',
                                        text: 'quarters'
                                    }
                                ]
                            },
                            {
                                type: 'multipleChoiceOption',
                                attrs: {
                                    isMultiAnswer: false,
                                    parentType: 'dropdown',
                                    value: '607521311'
                                },
                                content: [
                                    {
                                        type: 'text',
                                        text: 'eighths'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        type: 'text',
                        text: ' make a whole.'
                    }
                ]
            }
        ]
    ]
};

export const loadingData = [
    [
        {
            type: 'paragraph',
            content: [
                {
                    type: 'text',
                    text: 'Loading...'
                }
            ]
        }
    ],
    [
        {
            type: 'paragraph',
            content: [
                {
                    type: 'text',
                    text: 'Loading...'
                }
            ]
        }
    ],
    [
        {
            type: 'paragraph',
            content: [
                {
                    type: 'text',
                    text: 'Loading...'
                }
            ]
        }
    ],
    [
        {
            type: 'paragraph',
            content: [
                {
                    type: 'text',
                    text: 'Loading...'
                }
            ]
        }
    ],
    [
        {
            type: 'paragraph',
            content: [
                {
                    type: 'text',
                    text: 'Loading...'
                }
            ]
        }
    ],
    [
        {
            type: 'paragraph',
            content: [
                {
                    type: 'text',
                    text: 'Loading...'
                }
            ]
        }
    ],
    [
        {
            type: 'paragraph',
            content: [
                {
                    type: 'text',
                    text: 'Loading...'
                }
            ]
        }
    ],
    [
        {
            type: 'paragraph',
            content: [
                {
                    type: 'text',
                    text: 'Loading...'
                }
            ]
        }
    ],
    [
        {
            type: 'paragraph',
            content: [
                {
                    type: 'text',
                    text: 'Loading...'
                }
            ]
        }
    ],
    [
        {
            type: 'paragraph',
            content: [
                {
                    type: 'text',
                    text: 'Loading...'
                }
            ]
        }
    ]
];
