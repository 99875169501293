import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { pickThemeVar } from '../../styles/theme/app-theme';

const workedSolutionGuideSize = '64px';

export const WorkedSolutionWrapper = styled.div`
    box-shadow: 0px 4px 16px rgba(0, 25, 55, 0.16), 0px -2px 8px -8px rgba(11, 55, 83, 0.88) inset;
    margin-top: 400px;
    position: relative;
    animation: up 0.7s forwards;
    height: calc(100% + ${pickThemeVar('questionBodyPadding')});
    background-color: white;
    z-index: 100;

    @media (max-width: ${pickThemeVar('breakPoint')}) {
        height: calc(100% + ${pickThemeVar('questionBodyPaddingMobile')});
    }

    @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
        font-size: 18px;
        margin-left: -${pickThemeVar('questionBodyPaddingMobile')};
        margin-right: -${pickThemeVar('questionBodyPaddingMobile')};
    }

    @keyframes up {
        0% {
            margin-top: 400px;
        }
        100% {
            margin-top: 0;
        }
    }
`;

export const WorkedSolutionBody = styled.div`
    opacity: 0;
    animation: appear 0.4s forwards 0.3s;
    height: 100%;
    padding: 6px;
    padding-bottom: 0;
    box-sizing: border-box;

    @keyframes appear {
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

export const LeftItem = styled.div`
    display: flex;
    align-items: center;
`;

export const WorkedSolutionHeader = styled.div(props => {
    const {
        theme: {
            app: {
                primary50,
                primary700
            }
        }
    } = props;

    return css`
        height: ${workedSolutionGuideSize};
        line-height: ${workedSolutionGuideSize};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-radius: 13px 13px 0 0;
        font-size: 20px;
        background-color: ${primary50};
        color: ${primary700};
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')(props)}) {
            font-size: 18px;
        }
    `;
});

export const WorkedSolutionContent = styled.div<{ signPosts: Record<string, string> }>`
    overflow: auto;
    height: calc(100% - ${workedSolutionGuideSize});
    padding-top: 12px;
    box-sizing: border-box;

    .qtc-wrapper {
        ${({ signPosts }) => Object.keys(signPosts).map((signPostKey) => `
            ${`
            `/* eslint-disable-next-line max-len */}
            *[data-type="worked-solution-${signPostKey}"]:not([data-type="worked-solution-${signPostKey}"]+[data-type="worked-solution-${signPostKey}"]) {
                &::before {
                    content: '${signPosts[signPostKey]}';
                }
            }
        `).join('')}
    }
`;
