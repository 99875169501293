import { css } from '@emotion/react';

export type FlexMixins = typeof flex;

export const flex = {
    flexRowVerticalCentered: css`
      display: flex;
      flex-direction: row;
      align-items: center;
    `,
    flexRowSpaced: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `,
    flexRowVerticalCenteredSpaced: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `,
    flexColumnCentered: css`
      display: flex;
      flex-flow: column;
      align-items: center;
      `,
    flexColumn: css`
      display: flex;
      flex-flow: column;
      align-items: start;
      `,
    flexColumnSpaced: css`
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    `,
    flexAlignEnd: css`
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    `,
    flexRowReverse: css`
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    `,
    flexChildCentered: css`
      display: flex; //default is row layout
      justify-content: center; //main axis (vertical)
      align-items: center; //cross axis (horizontal)
     `
};
