import {
    plural, t
} from '@lingui/macro';

import { SCORE_GROUPS } from './constants';

export const lokalisation = () => {
    const messageBoxTxt = {
        totalPoints: t`Message - Total Points`,
        questions: t`Message - Questions`,
        fluencyDescription: t`Message - Fluency Description`,
        fluencyHeader: t`Message - Fluency Header`,
        fluencyHeaderForExceedingMaximumAttempts: t({
            id: 'Message - Fluency Header For Exceeding Maximum Attempts',
            message: "Let's dive back in"
        }),
        noPointsEarnTxt: t({
            id: 'Message - No Point Earn',
            message: "You've earned all the points you can for this set."
        })
    };
    const diagnosticIntroTxt = { noPointsEarn: t`Diagnostics Intro - You’ve earned all the points you can for this set.` };
    const transToScreenTxt = {
        problemSolvingHeader1: t`Trans To Problem Solving - Header1`,
        problemSolvingHeader2: t`Trans To Problem Solving - Header2`,
        problemSolvingDescription: t`Trans To Problem Solving - Description`,
        nextStage: t`Trans - Next Stage`,
        nextStageStart: t`Trans - Next Stage Start`,
        reasoningHeader1: t`Trans To Reasoning - Header1`,
        reasoningHeader2: t`Trans To Reasoning - Header2`,
        reasoningDescription: t`Trans To Reasoning - Description`
    };
    const progressTxt = [
        t`Progress Label - Fluency`,
        t`Progress Label - Reasoning`,
        t`Progress Label - Problem Solving`
    ];
    const buttonTxt = {
        hint: t`Button - Hint`,
        next: t`Button - Next`,
        exit: t`Button - Exit`,
        check: t`Button - Check`,
        begin: t`Button - Begin`,
        resume: t`Button - Resume`,
        start: t`Button - Start`,
        startQuestions: t`Button - Start Questions`,
        ready: t`Button - Let's do it`,
        tryAgain: t`Button - Try again`,
        tryAnother: t`Try another`,
        workedSolution: t`Button - Worked solution`,
        collect: t`Collect`,
        finish: t`Finish`,
        startAgain: t`Start again`,
        gotIt: t`Got it`,
        home: t`Home`,
        signIn: t`Sign In`
    };
    const introTxt = {
        conceptRefresh: t`Intro Label - Concept refresh`,
        hi: t`Intro Label - Hi`,
        goal: t`Intro Label - today we are practicing`,
        intention: t`Intro Label - Learning intentions`,
        success: t`Intro Label - Here's what success on this challenge means`,
        questionSubHeading: t`Intro Label - Question Sub Heading`,
        diagnosticGoal: (maximumAttemptExceeded: boolean) => {
            return maximumAttemptExceeded ?
                t`let’s dive back in!`
                : t`try these 10 questions to see if you remember the skills you need for this unit.`;
        }
    };
    const labelTxt = {
        coins: t`Coins`,
        points: t`Points`,
        collectCoinsHeading: t`Woohoo, let's collect some coins!`,
        correct: t`Validation label - Correct`,
        correctAnswerHeader: t`Correct Answer Header Label - Here's the correct answer`,
        readAloud: t`Read aloud`,
        summit: t`Progress Label - Summit`,
        attempt: (value: number) => t({
            id: 'Attempt {value}',
            /* eslint-disable @typescript-eslint/naming-convention */
            message: plural(value, {
                1: '1st attempt',
                2: '2nd attempt',
                3: '3rd attempt',
                other: '#th attempt'
            })
            /* eslint-enable @typescript-eslint/naming-convention */
        })
    };
    const praiseStatement = (score: number, name?: string) => {
        if (score <= SCORE_GROUPS.four) {
            return [
                t({
                    id: 'Praise Statement 4 0',
                    message: `Nice try! Keep working on it, ${name}`
                })
            ];
        } else if (score <= SCORE_GROUPS.six) {
            return [
                t({
                    id: 'Praise Statement 6 0',
                    message: `Good work, ${name}!`
                }),
                t({
                    id: 'Praise Statement 6 1',
                    message: `Nice work, ${name}!`
                })
            ];
        } else if (score <= SCORE_GROUPS.eight) {
            return [
                t({
                    id: 'Praise Statement 8 0',
                    message: `Well done, ${name}!`
                }),
                t({
                    id: 'Praise Statement 8 1',
                    message: `Great job, ${name}!`
                })
            ];
        } else if (score === SCORE_GROUPS.nine) {
            return [
                t({
                    id: 'Praise Statement 9 0',
                    message: `Terrific work, ${name}!`
                }),
                t({
                    id: 'Praise Statement 9 1',
                    message: `Excellent work, ${name}!`
                })
            ];
        } else if (score === SCORE_GROUPS.ten) {
            return [
                t({
                    id: 'Praise Statement 10 0',
                    message: `You rock, ${name}!`
                }),
                t({
                    id: 'Praise Statement 10 1',
                    message: `Superb work, ${name}!`
                }),
                t({
                    id: 'Praise Statement 10 2',
                    message: `Perfect score, ${name}!`
                }),
                t({
                    id: 'Praise Statement 10 3',
                    message: `Super result, ${name}!`
                }),
                t({
                    id: 'Praise Statement 10 4',
                    message: `Outstanding result, ${name}!`
                })
            ];
        }

        return [];
    };
    const praiseFeedback2 = (score: number) => {
        if (score === SCORE_GROUPS.ten) {
            return t({
                id: 'Praise Feedback 2nd 10',
                message: `You scored 100% across ${progressTxt[0]}, ${progressTxt[1]}, and ${progressTxt[2]}.`
            });
        }

        return '';
    };
    const praiseFeedback3 = (score: number) => {
        if (score <= SCORE_GROUPS.four) {
            return t({
                id: 'Praise Feedback 3rd 4',
                message: 'Watch the refresher video and then try again.'
            });
        } else if (score === SCORE_GROUPS.ten) {
            return t({
                id: 'Praise Feedback 3rd 10',
                message: 'You have achieved all the success criteria!'
            });
        }

        return '';
    };
    const summaryTxt = {
        praiseStatement,
        score: t`Summary Label - Score`,
        overallRating: t`Summary Label - Overall rating`,
        starRatings: t`Summary Label - Star ratings are based on the overall stars earned.`,
        stars: t`Summary Label - Stars`,
        praiseFeedback2,
        praiseFeedback3,
        feedbackByStrength: [
            t`Summary Label - Focus`,
            t`Summary Label - Good`,
            t`Summary Label - Strong`
        ]
    };
    const exceededAttemptsFinishScreenTxt = {
        title: t({
            id: 'ExceededAttemptsFinishScreen - title',
            message: 'You’ve earned all the points you can for this set.'
        }),
        description: t({
            id: 'ExceededAttemptsFinishScreen - description',
            message: 'Your highest score will always be retained.'
        })
    };
    /* istanbul ignore next */
    const diagnosticsPraises = (score: number) => {
        switch (score) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
            return t({
                id: 'Diagnostics Praise Statement 1 - 4',
                message: 'Nice try'
            });
        case 5:
        case 6:
            return t({
                id: 'Diagnostics Praise Statement 5 - 6',
                message: 'Good work'
            });
        case 7:
        case 8:
            return t({
                id: 'Diagnostics Praise Statement 7 - 8',
                message: 'Well done'
            });
        case 9:
            return t({
                id: 'Diagnostics Praise Statement 9',
                message: 'Terrific work'
            });
        case 10:
            return t({
                id: 'Diagnostics Praise Statement 10',
                message: 'Super result'
            });
        default:
            break;
        }
    };
    const scoreStatement = (totalCorrect: number, totalQuestions: number) => t({
        id: 'Diagnostics Summary - Score Statement',
        message: `${totalCorrect} out of ${totalQuestions}`
    });
    const diagnosticsSummaryTxt = {
        diagnosticsPraises,
        youScored: t`Diagnostics Summary - You scored`,
        scoreStatement,
        prompt: t`Diagnostics Summary - Prompt`,
        scoreRetention: t`Diagnostics Summary - Score Retention`,
        scoreRetentionAfterMaximumAttempt: t`Diagnostics Summary - Score Retention After Maximum Attempt`,
        noPointsEarn: t`Diagnostics Summary - You’ve earned all the points you can for this set.`
    };
    const errorTxt = { errorMessage: t`Uh-oh! Something doesn't add up.` };
    const noInternetTxt = {
        header: t`No internet connection`,
        content1: t`Connection lost,`,
        content2: t`we’re trying to reconnect you.`
    };
    const sessionTimeoutTxt = {
        header: t`Have you finished?`,
        content1: t`Your Mathletics account has been inactive.`,
        content2: t`For security reasons, we have signed you out. If you would like to sign in again, just click below.`
    };
    const workedSolutionTxt = {
        solution: (num: number) => t`Solution ${num}`,
        signPosts: {
            think: t`Signpost - Think`,
            plan: t`Signpost - Plan`,
            do: t`Signpost - Do`,
            answer: t`Signpost - Answer`
        }
    };
    const feedbackTxt = {
        heading: t`How well do you understand`,
        feedback: {
            /* eslint-disable @typescript-eslint/naming-convention */
            'I’m so lost': t`I’m so lost`,
            'Not great': t`Not great`,
            'I’m starting to get it': t`I’m starting to get it`,
            'I’m good': t`I’m good`,
            'I could teach it!': t`I could teach it!`
            /* eslint-enable @typescript-eslint/naming-convention */
        }
    };
    const qtcRendererTxt = { corruptedData: t`Corrupted data!` };
    const exitWithoutCompleting = {
        para1: t`Ready to exit?`,
        para2: t`We’ll save your progress and you can complete the rest of this question set later.`,
        yes: t`Yes`,
        no: t`No`
    };
    const fluencyFailTxt = {
        para1a: t`Take a breath and dive back in. You need to get at least 2 answers correct in this`,
        para1b: t`section before moving on.`,
        para2: t`Watch the concept refresh video and try again.`
    };
    const questionComponentsLibraryTxt = {
        video: t`Video`,
        rotateMsgLabel: t`Rotate your screen to view the question more easily`,
        rotateImageAltLabel: t`Rotate device to landscape`
    };
    const previewTxt = { question: t`question` };
    const zoomOut = {
        heading: t`Does everything look too big?`,
        content: [
            t`Press`,
            t`Ctrl/Command`,
            t`and`,
            t`-`,
            t`to`,
            t`zoom out`,
            t`until the page fits your screen.`
        ]
    };
    const unitPracticeTest= {
        score: t`You scored`,
        accuracy: t`Accuracy`,
        improveScoreTitle: t`Do you want to improve your score?`,
        improveScoreText: t`If you do, your best score will always be retained.`,
        questionSummary: t`Question Summary`,
        retainScoreHeading: t`You have earned all the points you can for this set`,
        retainScoreText: t`Your highest score will always be retained.`
    };

    return {
        progressTxt,
        buttonTxt,
        errorTxt,
        introTxt,
        feedbackTxt,
        transToScreenTxt,
        labelTxt,
        summaryTxt,
        exceededAttemptsFinishScreenTxt,
        diagnosticsSummaryTxt,
        messageBoxTxt,
        diagnosticIntroTxt,
        noInternetTxt,
        workedSolutionTxt,
        qtcRendererTxt,
        exitWithoutCompleting,
        fluencyFailTxt,
        questionComponentsLibraryTxt,
        previewTxt,
        zoomOut,
        unitPracticeTest,
        sessionTimeoutTxt
    };
};
