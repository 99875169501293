import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
    pickThemeFlex, pickThemeVar, ThemeProps
} from '../../../../styles/theme/app-theme';
import { QuestionProgressStyleProps } from '../../question-container.types';
import { attemptStatusUtil } from './utils/attempt-status-util';

const attemptCapsuleStyle = (props: QuestionProgressStyleProps & ThemeProps) => {
    const { getCapsuleStyle } = attemptStatusUtil();
    const questionProgress = props;
    const {
        borderColour, capsuleWidth, leftPos
    } = getCapsuleStyle(questionProgress);

    return css `
      border-color: ${borderColour};
      width: ${capsuleWidth};
      left: ${leftPos};
    `;
};

export const AttemptsStatusStyled = styled.div<QuestionProgressStyleProps>`
  ${pickThemeFlex('flexColumnCentered')};
  position: relative;
  
  .attempt-state {
    ${pickThemeFlex('flexChildCentered')}
    font-size: 14px;
    height: 18px;
    line-height: 28px;
    margin-bottom: 2px;
    color: ${pickThemeVar('neutral600')};
  }
  
  .attempt-capsule {
    ${pickThemeFlex('flexChildCentered')};
    position: absolute;
    top: 20px;
    height: 28px;
    box-sizing: border-box;
    border-radius: 16px;
    border: solid 1px;

    ${attemptCapsuleStyle};
  }
  
  .attempt-stars {
    ${pickThemeFlex('flexRowVerticalCentered')};
    height: 28px;
    display: flex;
    align-items: center;
  }

  @media (max-width: calc(${pickThemeVar('breakTabletLandscape')})) {
    transform: scale(0.8333);
  }
`;

const starStyle = (props: QuestionProgressStyleProps & ThemeProps) => {
    const { getStarStyle } = attemptStatusUtil();
    const questionProgress = props;
    const { greyStar } = getStarStyle(questionProgress);

    let greyStarStyle ;

    if (greyStar) {
        greyStarStyle = css`
          border: 1px solid ${props.theme.app.grey03};
          
          .star {
            width: 14px;
            opacity: 0.6;
            
            path {
              fill: ${props.theme.app.grey02} !important;
            }
          }
        `;
    } else {
        greyStarStyle = css `
          
          .star {
            width: 20px;
          }
        `;
    }

    return greyStarStyle;
};

export const AttemptStarStyled = styled.div<QuestionProgressStyleProps>`
  ${pickThemeFlex('flexChildCentered')};
  height: 22px;
  width: 22px;
  box-sizing: border-box;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 50%;
  border: 1px solid white;
  
  ${starStyle};
  
  .icon {
    position: absolute;
    top: 0;
    
    &.correct {
      top: 6px;
    }

    &.incorrect {
      top: 7px;
    }
    
    &.answer {
      top: 6px;
      width: 8px;
      height: 8px;
      box-sizing: border-box;
      border-radius: 50%;
      border: solid 1px ${pickThemeVar('blue05')};
      opacity: 0.3;
    }
  }
`;

