import React, {
    useEffect, useMemo, useRef, useState
} from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../../../app/store/store.types';
import AppImage from '../../../../components/app-image/app-image';
import { useAudioEffectsContext } from '../../../../components/audio/provider/audio-effects-provider';
import {
    MilestoneState, QuestionProgressState
} from '../../../../helpers/enums';
import { isDefined } from '../../../../helpers/utils';
import { showStars } from '../../anims/anims';
import {
    ProgressStepPropsWithState, RefType
} from '../../question-container.types';
import {
    ProgressStepStyled, StarHolderStyled
} from './progress-step.styled';

export const ProgressStep = ({
    stepsAnswered, questionNumIndex, numStars, questionNumber
}: ProgressStepPropsWithState) => {
    const [starShapes, setStarShapes] = useState<JSX.Element[]>([]);
    const starListRef = useRef<HTMLDivElement>(null);
    const firstStarRef = useRef<HTMLDivElement>(null);
    const secondStarRef = useRef<HTMLDivElement>(null);
    const thirdStarRef = useRef<HTMLDivElement>(null);
    /*
     * STARS REFS
     */
    const starsRefs: RefType[] = useMemo(() => ([firstStarRef, secondStarRef, thirdStarRef]), []);
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const {
        progress, playCorrectStars, questionProgressState
    } = activityState;
    const { playCorrectStarsAudio } = useAudioEffectsContext();

    let currentQuestionNum: number | undefined;

    if(progress){
        const { stepsCompleted } = progress;

        currentQuestionNum = stepsCompleted;
    }
    const isCurrentStep = questionNumber === questionNumIndex + 1;

    useEffect(() => {
        if (starShapes && starShapes.length > 0) {
            if (firstStarRef.current && starListRef.current) {
                const starsRefsList = [];

                for (let i = 0; i < starShapes.length; i++) {
                    starsRefsList.push(starsRefs[i]);
                }

                if(questionProgressState === QuestionProgressState.resetUnanswered
                    || questionProgressState === QuestionProgressState.nextUnanswered) {
                    showStars(starsRefsList, starListRef);
                } else if (playCorrectStars && isCurrentStep) {
                    showStars(starsRefsList, starListRef);
                    playCorrectStarsAudio(starShapes.length);
                }
            }
        }
    }, [currentQuestionNum, isCurrentStep, questionNumIndex, playCorrectStars, playCorrectStarsAudio, questionNumber,
        questionProgressState, starShapes, starsRefs]);

    /*
     * STEP STATE
     */
    const calculateStepState = () => {
        if (isDefined(stepsAnswered)) {
            if (questionNumIndex + 1 <= stepsAnswered) {
                return MilestoneState.completed;
            }
            if (questionNumIndex === stepsAnswered) {
                return MilestoneState.inProgress;
            }
        }

        return MilestoneState.notStarted;
    };
    const stepState = calculateStepState();

    /*
    * STARS
    */
    useEffect(() => {
        if (stepState !== MilestoneState.notStarted) {
            const starList = [];

            for (let i = 0; i < numStars; i++) {
                starList.push(
                    <StarHolderStyled
                        key={i}
                        ref={starsRefs[i]}
                        isCurrentStep={isCurrentStep}
                        numStars={numStars}
                    >
                        <AppImage
                            imageType={'svg'}
                            iconType={'star'}
                            className={'star'}
                        />
                    </StarHolderStyled>
                );
            }
            setStarShapes(starList);
        }
    }, [isCurrentStep, questionNumIndex, numStars, starsRefs, stepState]);

    return (
        <ProgressStepStyled
            data-testid={'progress-step'}
            stepState={stepState}
            questionNumIndex={questionNumIndex}
            numStars={numStars || 0}
            questionNumber={questionNumber}
        >
            <div className={'step-content'}>
                <div className={'bg-disc'}>
                    <div className={'label'}><span>{questionNumIndex + 1}</span></div>
                </div>
                {stepState !== MilestoneState.notStarted &&
                <div className={'star-list'} ref={starListRef}>
                    {starShapes}
                </div>
                }
            </div>
        </ProgressStepStyled>
    );
};
