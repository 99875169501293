import {
    Box,
    HStack,
    Button,
    useTheme,
    Text
} from '@chakra-ui/react';
import React, { FC } from 'react';

import { useRestartApp } from '../../../containers/fluency-fail/fluency-fail.hooks';
import { TempSpinnerWrapper } from '../../../containers/question-container/sub-components/temp-poc/temp-spinner.styled';
import { ExitType } from '../../../helpers/enums';
import { lokalisation } from '../../../helpers/lokalisation';
import { useExitApp } from '../../../hooks/use-exit-app';
import { useWithMobile } from '../../../hooks/use-with-mobile';
import { useAudioEffectsContext } from '../../audio/provider/audio-effects-provider';
import { btnStyle } from './fluency-fail-modal.constants';
import { useModalResponsiveStyles } from './fluency-fail-modal.hooks';
import { FluencyFailModalContentProps } from './fluency-fail-modal.types';

export const FluencyFailModalContent: FC<FluencyFailModalContentProps> = ({ questionSetType }) => {
    const {
        fluencyFailTxt,
        progressTxt,
        buttonTxt
    } = lokalisation();
    const {
        isMobile,
        landscape,
        isTablet
    } = useWithMobile();
    const theme = useTheme();
    const {
        contentResponsiveStyles,
        buttonResponsiveStyles
    } = useModalResponsiveStyles();
    const { playClick } = useAudioEffectsContext();
    const {
        exitApp,
        exiting
    } = useExitApp();
    const {
        restartApp,
        restarting
    } = useRestartApp();
    const handleExit = async () => {
        playClick();
        exitApp(questionSetType, ExitType.fluencyExit);
    };
    const handleStartAgain = () => {
        playClick();
        restartApp(questionSetType);
    };
    const isMobileLandscape = (isMobile && landscape) && !isTablet;

    return (
        <Box
            display={'flex'}
            justifyContent={'flex-end'}
            zIndex={'100'}
            position={'relative'}
        >
            <Box
                fontSize={{
                    tabletLandscape: '30px',
                    md: '28px',
                    base: '24px'
                }}
                letterSpacing={'-0.4px'}
                lineHeight={'140%'}
                m={{
                    tabletLandscape: '74px 34px',
                    md: '90px 20px 20px 64px',
                    base: isMobileLandscape ? '3% 7%' : '122px 20px 64px 20px'
                }}
                {...contentResponsiveStyles()}
            >
                <Box
                    maxWidth={'460px'}
                >
                    <Box
                        w={'80px'}
                        h={'2px'}
                        backgroundColor={'#B2F114'}
                        mb={isMobileLandscape ? '12px' : '24px'}
                    />
                    <Box
                        mb={'30px'}
                    >
                        {`${fluencyFailTxt.para1a} `}
                        <Text
                            as={'span'}
                            fontWeight={'bold'}
                        >
                            {progressTxt[0]}
                        </Text>
                        {` ${fluencyFailTxt.para1b}`}
                    </Box>
                    <Box
                        mb={'30px'}
                    >
                        {fluencyFailTxt.para2}
                    </Box>
                    <HStack
                        spacing={{
                            tabletLandscape: '40px',
                            md: '24px',
                            base: '24px'
                        }}
                        align={'center'}
                        justifyContent={'center'}
                        w={'100%'}
                    >
                        <Button
                            color={theme.app.blue02}
                            borderColor={theme.app.primary300}
                            variant={'outline'}
                            colorScheme={'white'}
                            data-testid={'modal-exit-button'}
                            className={'close-button'}
                            onClick={() => handleExit()}
                            isDisabled={exiting || restarting}
                            {...btnStyle}
                            {...buttonResponsiveStyles()}
                        >
                            {exiting || buttonTxt.exit}
                            {exiting && (
                                <TempSpinnerWrapper data-testid={'spinner'}>
                                    <div
                                        className={'lds-ellipsis'}
                                    >
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                    </div>
                                </TempSpinnerWrapper>
                            )}
                        </Button>
                        <Button
                            colorScheme={'primary'}
                            data-testid={'modal-start-again-button'}
                            className={'exit-button'}
                            onClick={() => handleStartAgain()}
                            isDisabled={exiting || restarting}
                            {...btnStyle}
                            {...buttonResponsiveStyles()}
                        >
                            {restarting || buttonTxt.startAgain}
                            {restarting && (
                                <TempSpinnerWrapper data-testid={'spinner'}>
                                    <div
                                        className={'lds-ellipsis'}
                                    >
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                    </div>
                                </TempSpinnerWrapper>
                            )}
                        </Button>
                    </HStack>
                </Box>
            </Box>
        </Box>
    );
};
