import { Circle } from '@chakra-ui/react';
import React from 'react';

import { UnitTestQuestionAttemptsPillProps } from './unit-test-question-attempts.types';

export const UnitTestQuestionAttemptPill: React.FC<UnitTestQuestionAttemptsPillProps> = ({ status }) => {
    const getOutlineColors = (attemptStatus: UnitTestQuestionAttemptsPillProps['status']) => {
        switch (attemptStatus) {
        case 'Attempted':
            return {
                borderColor: 'neutral.300',
                borderWidth: '1px',
                boxSizing: {
                    base: 'border-box',
                    md: 'content-box'
                }
            };
        case 'Prompted':
            return {
                borderColor: 'primary.500',
                borderWidth: '2px',
                mr: {
                    base: '-2px',
                    md: 0
                }
            };
        case 'Unattempted':
            return {
                borderColor: {
                    base: 'neutral.300',
                    md: 'transparent'
                },
                borderWidth: '1px',
                boxSizing: {
                    base: 'border-box',
                    md: 'content-box'
                }
            };
        }
    };
    const getFillColors = (attemptStatus: UnitTestQuestionAttemptsPillProps['status']) => {
        switch (attemptStatus) {
        case 'Attempted':
            return {
                bgColor: 'neutral.500',
                border: 'none'
            };
        case 'Prompted':
            return {
                bg: 'linear-gradient(103deg, #00ABE8 4%, #007ACC 45%, #0063A7 100%)',
                border: 'none'
            };
        case 'Unattempted':
            return {
                bgColor: {
                    base: 'white',
                    md: 'transparent'
                },
                borderWidth: {
                    base: 0,
                    md: '1px'
                },
                borderColor: 'neutral.300',
                borderStyle: 'solid'
            };
        }
    };

    return (
        <Circle
            bgColor={{
                base: 'white',
                md: 'transparent'
            }}
            border={'1px solid'}
            // @ts-ignore
            boxSizing={'content-box'}
            size={{
                base: '12px',
                md: '18px',
                lg: '22px'
            }}
            {...getOutlineColors(status)}
        >
            <Circle
                size={{
                    base: '8px',
                    md: '12px',
                    lg: '14px'
                }}
                {...getFillColors(status)}
            />
        </Circle>
    );
};
