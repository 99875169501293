import {
    useEffect,
    useState
} from 'react';
import {
    initialize,
    pageview
} from 'react-ga';

const useGoogleAnalytics = () => {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes('localhost') && window._env_.GTM_ID) {
            initialize(window._env_.GTM_ID);
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            pageview(window.location.href);
        }
    }, [initialized]);

    return initialized;
};

export default useGoogleAnalytics;
