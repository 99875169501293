import { TextSegment } from '@3plearning/question-components-library';

export type SlideWrapperProps = {
    isPrev: boolean
};

type VideoSegment = {
    type: 'videoSrc'
    text: string
};

type ConceptRefreshSegment = TextSegment | VideoSegment;

type ConceptRefreshBlock = {
    content: ConceptRefreshSegment[]
    type?: string
};

export type ConceptRefreshContent = ConceptRefreshBlock[];

export type ConceptRefreshSlideProps = {
    conceptRefresh?: ConceptRefreshContent
};

export enum ContentType {
    conceptRefresh = 'concept_refresh',
    workedSolution = 'worked_solution'
  }
