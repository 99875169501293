import {
    Content, QtcRenderer, validationType
} from '@3plearning/question-components-library';
import { ErrorBoundary } from '@sentry/react';
import React from 'react';

import { lokalisation } from '../../helpers/lokalisation';
import CorruptedData from '../corrupted-data/corrupted-data';
import { CorrectAnswerProps } from './correct-answer.types';

export const CorrectAnswerContent: React.FC<CorrectAnswerProps> = ({
    question, validation
}) => {
    const { questionComponentsLibraryTxt } = lokalisation();
    const correctAnswerValidation = validation && {
        ...validation,
        validAnswer: { ...(validation?.primaryAnswer ?? validation?.validAnswer) }
    };

    return (
        <ErrorBoundary fallback={<CorruptedData />}>
            <QtcRenderer
                callback={() => void 0}
                question={question as Content}
                showCorrectAnswer={true}
                validation={correctAnswerValidation as validationType}
                translations={questionComponentsLibraryTxt}
            />
        </ErrorBoundary>
    );
};
