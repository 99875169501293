import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    ModalFooter,
    useTheme,
    Box,
    Image,
    Flex,
    Circle
} from '@chakra-ui/react';
import React from 'react';

import keycap from '../../../../assets/images/keycap.png';
import { lokalisation } from '../../../helpers/lokalisation';
import { useAudioEffectsContext } from '../../audio/provider/audio-effects-provider';
import {
    Command,
    Ctrl,
    HyphenUnderscore, KeycapWrapper
} from './zoom-out.styled';
import { ZoomOutModalProps } from './zoom-out.types';

export const ZoomOutModal = ({
    isOpen,
    onClose
}: ZoomOutModalProps) => {
    const {
        buttonTxt, zoomOut
    } = lokalisation();
    const theme = useTheme();
    const { playClick } = useAudioEffectsContext();
    const handleOnClose = () => {
        playClick();
        onClose();
    };
    const os = window.navigator?.userAgent?.includes('Mac') ? 'mac' : 'other';

    return (
        <Modal
            size={'full'}
            data-testid={'zoom-out-modal'}
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent
                border={'8px'}
                borderColor={theme.app.whiteAlpha30}
                bg={'transparent'}
                borderRadius={'31px'}
                mt={'80px'}
                w={'495px'}
                overflow={'hidden'}
                pos={'relative'}
            >
                <ModalBody
                    data-testid={'modal-body'}
                    bg={'white'}
                    borderRadius={'24px 24px 0 0'}
                    pt={'24px'}
                    px={'24px'}
                    pb={0}
                >
                    <Box
                        textAlign={'center'}
                        fontSize={'22px'}
                        letterSpacing={'-1.215px'}
                        lineHeight={'140%'}
                        pb={'16px'}
                        color={theme.app.neutral900}
                    >
                        {zoomOut.heading}
                    </Box>
                    <Flex
                        alignItems={'center'}
                        wrap={'wrap'}
                        fontSize={'13px'}
                        letterSpacing={'-0.078px'}
                        pb={'16px'}
                        lineHeight={'normal'}
                        color={theme.app.grey04}
                    >
                        {zoomOut.content.map((content, index) => {
                            switch (index) {
                            case 1:
                                return (
                                    <KeycapWrapper key={index}>
                                        <Image src={keycap} alt={content} />
                                        {os === 'mac' ? <Command /> : <Ctrl />}
                                    </KeycapWrapper>
                                );
                            case 3:
                                return (
                                    <KeycapWrapper key={index}>
                                        <Image src={keycap} alt={content} />
                                        <HyphenUnderscore os={os} />
                                    </KeycapWrapper>
                                );
                            case 5:
                                return <strong key={index}>&nbsp;{content}&nbsp;</strong>;
                            default:
                                return <span key={index}>{content}</span>;
                            }
                        })}
                    </Flex>
                    <Circle size={100} bg={theme.colors.teal[400]} opacity={0.4} pos={'absolute'} left={'-24px'} top={'-41px'} />
                </ModalBody>
                <ModalFooter
                    bg={'white'}
                    pb={'24px'}
                    px={'24px'}
                    pt={0}
                    borderRadius={'0 0 24px 24px'}
                    justifyContent={'center'}
                >
                    <Button
                        colorScheme={'primary'}
                        data-testid={'modal-close-button'}
                        className={'close-button'}
                        onClick={() => handleOnClose()}
                        py={'6px'}
                        w={'100%'}
                        fontSize={'16px'}
                        fontWeight={'500'}
                        zIndex={1}
                    >
                        {buttonTxt.gotIt}
                    </Button>
                    <Circle size={300} bg={theme.colors.yellow[200]} opacity={0.45} pos={'absolute'} right={'-99px'} bottom={'-263px'} />
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
