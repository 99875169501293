import styled from '@emotion/styled';

import {
    pickThemeFlex, pickThemeVar
} from '../../styles/theme/app-theme';

export const QuestionContainerStyled = styled.div`
  position: relative;
  margin: 0 32px;
  max-height: 100%;
  min-height: min(100%, ${pickThemeVar('progressPanelHeight')});
  width: calc(100% - (2 * ${pickThemeVar('questionContainerMargin')}));
  display: flex;
  flex-direction: row-reverse;

  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    margin: 0 24px 0 24px;
    width: calc(100% - 48px);
  }

  @media (max-width: ${pickThemeVar('breakPoint')}) {
    position: absolute;
    top: 0;
    left: 0;
    ${pickThemeFlex('flexColumnCentered')};
    width: 100%;
    height: 100%;
    margin: unset;
  }
`;
