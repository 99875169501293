/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
    useState, useEffect
} from 'react';

import { useWithMobile } from '../../../hooks/use-with-mobile';

/*
   https://usehooks.com/useWindowSize/
*/
export function useWindowSize (): ({ viewWidth: number; viewHeight: number }) {
    const [windowSize, setWindowSize] = useState({
        viewWidth: 0,
        viewHeight: 0
    });

    useEffect(() => {
        function handleResize () {
            setWindowSize({
                viewWidth: window.innerWidth,
                viewHeight: window.innerHeight
            });
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}

export const useModalResponsiveStyles = () => {
    const {
        isMobile,
        isTablet,
        landscape
    } = useWithMobile();
    const contentResponsiveStyles = () => {
        let styles = null;

        if (isMobile && !isTablet) {
            if (landscape) {
                styles = {
                    fontSize: '20px',
                    width: '54%'
                };
            } else {
                styles = {
                    fontSize: '18px',
                    marginTop: '80px',
                    width: '100%'
                };
            }
        }

        return styles;
    };
    const bkgdResponsiveStyles = () => {
        let styles = null;

        if ((isMobile || (landscape && isMobile)) && !isTablet) {
            styles = { display: 'none' };
        }

        return styles;
    };
    const fluffyResponsiveStyles = () => {
        let styles = null;

        if (isMobile && !isTablet) {
            if (landscape) {
                styles = {
                    top: '32px',
                    left: '13px',
                    width: 'unset',
                    maxWidth: '33%'
                };
            } else {
                styles = {
                    top: '-62px',
                    left: '-100px',
                    width: 'unset',
                    transform: 'scale(0.5)'
                };
            }
        }

        return styles;
    };
    const modalResponsiveStyles = () => {
        let styles = null;

        if (isMobile && !landscape && !isTablet) {
            styles = { width: '500px !important' };
        }

        return styles;
    };
    const buttonResponsiveStyles = () => {
        let styles = null;

        if ((isMobile || (landscape && isMobile)) && !isTablet) {
            styles = { fontSize: '16px' };
        }

        return styles;
    };

    return {
        modalResponsiveStyles,
        fluffyResponsiveStyles,
        contentResponsiveStyles,
        bkgdResponsiveStyles,
        buttonResponsiveStyles
    };
};
