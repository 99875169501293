/* eslint-disable @typescript-eslint/naming-convention */
export const Modal = {
    sizes: {
        full: {
            dialog: {
                'maxW': 'none',
                'minH': 'auto',
                '@supports(min-height: -webkit-fill-available)': { minH: 'auto' }
            }
        }
    },
    variants: {
        'concept-refresh': {
            dialog: {
                boxShadow: 'unset',
                marginTop: '80px',
                width: 'calc(100% - 60px)',
                height: 'calc(100% - 160px)',
                backgroundColor: 'transparent'
            },
            body: { padding: '0 60px' }
        },
        'modal-alert': {
            dialogContainer: { zIndex: 'toast' },
            overlay: { zIndex: 'popover' }
        }
    }
};
