import {
    Container,
    Flex,
    Button,
    VStack,
    Divider,
    useTheme,
    Text,
    StackDirection,
    useMediaQuery,
    Stack
} from '@chakra-ui/react';
import React, {
    useEffect, useRef
} from 'react';
import { useHistory } from 'react-router-dom';

import { useAudioEffectsContext } from '../../../../components/audio/provider/audio-effects-provider';
import { Progress as ProgressType }
    from '../../../../containers/question-container/sub-components/question-activity/question-activity.types';
import { MAX_QUESTION_SET_ATTEMPT } from '../../../../helpers/constants';
import { TransitionScreen } from '../../../../helpers/enums';
import { lokalisation } from '../../../../helpers/lokalisation';
import { returnToStudentPortal } from '../../../../helpers/utils';
import { ROUTES } from '../../../routes';
import { useGetAnimationDetails } from '../summary/hooks/use-get-animation-details';
import { ProgressBar } from '../summary/sub-components/progress-bar/progress-bar';
import { Result } from './result/result';

type UnitTestSummaryProps = {
    uowTitle: string
    questName: string
    totalCorrect: number
    totalQuestions: number
    progress?: ProgressType
};

export const UnitTestSummary = ({
    uowTitle,
    questName,
    totalCorrect,
    totalQuestions,
    progress
}: UnitTestSummaryProps) => {
    const history = useHistory();
    const theme = useTheme();
    const {
        primary600,
        neutral600,
        neutral900
    } = theme?.app;
    const [isLargerThan600] = useMediaQuery('(min-width: 665px)');
    const stackDirection: StackDirection = isLargerThan600 ? 'row' : 'column';
    const {
        score,
        showGold,
        barRef,
        barGoldRef,
        goldBarStars,
        goldBrick,
        totalCorrectRef,
        scoreNum
    } = useGetAnimationDetails({
        totalCorrect,
        totalQuestions
    });
    const {
        buttonTxt, unitPracticeTest
    } = lokalisation();
    // completedAttempts is incremented from BE after final question is completed. Show summary for completedAttempts: 1 - 5
    const maxAttemptsReach = (progress?.completedAttempts ?? 0) > MAX_QUESTION_SET_ATTEMPT;
    const isMaxScoreReached = scoreNum === 100;
    const { playClick } = useAudioEffectsContext();
    const finishBtnRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        finishBtnRef.current?.focus({ preventScroll: true });
    }, []);

    function handleFinish () {
        playClick();
        if (maxAttemptsReach || totalCorrect === 0) {
            returnToStudentPortal();
        } else {
            history.replace(`${ROUTES.milestoneTransition}/${TransitionScreen.finishScreen}`);
        }
    }

    return (
        <Container
            m={0}
            p={0}
            margin={'auto'}
            data-testid={'unit-test-summary-screen'}
            maxWidth={'100%'}
            bgColor={'white'}
        >
            <VStack
                px={{
                    base: '20px',
                    breakTabletLandscape: '40px'
                }}
                pt={8}
                pb={5}
                maxWidth={'100%'}
                alignItems={'stretch'}
                spacing={'24px'}
            >
                <Flex direction={'column'} >
                    <Flex
                        display={'inline-flex'}
                        fontSize={{
                            base: '24px',
                            breakTabletLandscape: '30px'
                        }}
                        letterSpacing={'-0.8px'}
                        lineHeight={'40px'}
                        mb={{
                            base: '20px',
                            md: '32px'
                        }}
                        data-testid={'unit-test-summary-heading'}
                        color={'neutral.900'}
                    >
                        <strong>{uowTitle}</strong>
                        <Divider
                            orientation={'vertical'}
                            bg={neutral900}
                            pos={'relative'}
                            w={'1px'}
                            h={'auto'}
                            m={'0 8px'}
                            opacity={'1'}
                        />
                        {questName}
                    </Flex>
                    <Stack direction={stackDirection} spacing={'16px'}>
                        <VStack
                            data-testid={'unit-test-summary-stack'}
                            boxShadow={'0px 0px 4.63216px 0.772026px rgba(11, 55, 83, 0.08);'}
                            borderRadius={'16px'}
                            boxSizing={'border-box'}
                            w={'auto'}
                            h={'auto'}
                            flex={1}
                            alignItems={'start'}
                            justifyContent={{
                                base: 'center',
                                break680: 'unset'
                            }}
                        >
                            <VStack
                                alignItems={'start'}
                                spacing={{ base: '12px' }}
                                p={{
                                    base: '20px',
                                    break1270: '16px'
                                }}
                            >
                                <Text
                                    fontSize={'14px'}
                                    textTransform={'uppercase'}
                                    color={neutral900}
                                    fontWeight={500}
                                    lineHeight={'normal'}
                                    letterSpacing={'1px'}
                                >
                                    {unitPracticeTest.score}
                                </Text>
                                <Text
                                    data-testid={'unit-test-summary-score'}
                                    color={primary600}
                                    fontSize={{
                                        base: '36px',
                                        break1270: '44px'
                                    }}
                                    fontWeight={400}
                                    lineHeight={{
                                        base: '46px',
                                        break1270: '57.2px'
                                    }}
                                    letterSpacing={{
                                        base: '-0.8px',
                                        break1270: '-1.2px'
                                    }}
                                >
                                    {totalCorrect} out of {totalQuestions}
                                </Text>
                            </VStack>
                        </VStack>
                        <ProgressBar
                            score={score}
                            totalCorrect={totalCorrect}
                            totalQuestions={totalQuestions}
                            showGold={showGold}
                            barRef={barRef}
                            barGoldRef={barGoldRef}
                            goldBarStars={goldBarStars}
                            goldBrick={goldBrick}
                            totalCorrectRef={totalCorrectRef}
                            scoreNum={scoreNum}
                            isSummaryPage={false}
                        />
                    </Stack>
                </Flex>
                <VStack alignItems={'start'} spacing={'24px'}>
                    {(!isMaxScoreReached || (isMaxScoreReached && maxAttemptsReach)) && <Flex
                        color={neutral900}
                        fontSize={{
                            base: '24px',
                            break1270: '30px'
                        }}
                        fontWeight={400}
                        lineHeight={{
                            base: '33px',
                            break1270: '42px'
                        }}
                        letterSpacing={{
                            base: '-0.2px',
                            break1270: '-0.8px'
                        }}
                    >
                        {maxAttemptsReach ? unitPracticeTest.retainScoreHeading : unitPracticeTest.improveScoreTitle}
                    </Flex>}
                    <Flex
                        color={neutral600}
                        fontSize={'18px'}
                        fontWeight={400}
                        lineHeight={'21.6px'}
                        letterSpacing={'-0.036px'}
                    >
                        {(maxAttemptsReach || isMaxScoreReached) ? unitPracticeTest.retainScoreText : unitPracticeTest.improveScoreText}
                    </Flex>
                </VStack>
                <VStack
                    alignItems={'start'}
                    mt={{
                        base: '48px !important',
                        md: '58px !important'
                    }}
                >
                    <Flex
                        data-testid={'unit-test-summary-question-heading'}
                        textTransform={'uppercase'}
                        color={neutral600}
                        fontFamily={'Sofia Pro'}
                        fontSize={{
                            base: '14px',
                            break1270: '18px'
                        }}
                        fontWeight={400}
                        lineHeight={'21.6px'}
                        letterSpacing={{
                            base: '1px',
                            break1270: '-0.036px'
                        }}
                        mb={{
                            base: '4px',
                            md: '12px'
                        }}
                    >
                        {unitPracticeTest.questionSummary}
                    </Flex>

                    <Flex
                        data-testid={'unit-test-summary-results'}
                        boxShadow={'0px 0px 4.63216px 0.772026px rgba(11, 55, 83, 0.08);'}
                        borderRadius={'16px'}
                        boxSizing={'border-box'}
                        p={{
                            base: '16px',
                            md: '16px 24px'
                        }}
                        w={'100%'}
                        flexWrap={'wrap'}
                    >
                        {progress?.points && progress?.points[0].stars.map(((item, index) => {
                            return (
                                <Result key={index} answer={item} questionNumber={index + 1} />
                            );
                        })
                        )}
                    </Flex>
                </VStack>
            </VStack>
            <Flex justify={'flex-end'} boxShadow={'xs'}>
                <Button
                    colorScheme={'primary'}
                    mx={6}
                    my={3}
                    w={{
                        base: '100%',
                        md: '180px',
                        tabletLandscape: '210px'
                    }}
                    h={'48px'}
                    fontSize={'20px'}
                    data-testid={'unit-test-summary-finish-button'}
                    onClick={handleFinish}
                    ref={finishBtnRef}
                >
                    {(maxAttemptsReach || totalCorrect === 0) ? buttonTxt.finish : buttonTxt.next}
                </Button>
            </Flex>
        </Container>
    );
};
