import { Credentials } from '../types/common.types';
import { BasicResources } from './utils/basic-resources';

// The Resources class provides a set of static fields, one for each resource
// needed by the application. Users should generally call its initialise() method, to set
// up the environment and create all of the resources the application will use.

// In some (hopefully, rare) situations, it may be required that the environment is provided
// from a parent application. In these situations, call createResources(parentEnv) directly,
// and pass it the Resources.env object that is the environment required.
export class Resources {
    /* istanbul ignore file */
    // createResources() should create a static field for each resource:
    //
    //    const env = parentEnv || Resources.env;
    //
    //    Resources.colours = env.resource('Colours')
    //                           .baseUrl('@AvatarAdminBaseUrl')
    //                           .service('colours');
    //
    //
    // Application code can then access those resources like so:
    //
    //    const allColours = Resource.colours.get().send();

    // from original react-boilerplate
    /* eslint-disable @typescript-eslint/no-explicit-any */
    static env: any;
    static getToken?: any;
    static getConfigurationItem?: any;
    static getQuestionsDetails?: any;
    static getStrandUow?: any;
    static getQuestionSetPreview?: any;
    static getQuestion?: any;
    static saveQuestion?: any;
    static sendFeedback?: any;
    static collectCoins?: any;
    static exitQuestionSet?: any;
    static getReadAloudLocale?: any;
    static postReadAloudLocale?: any;
    static questionVariantsFromContentApi?: any;
    static testSympy?: any;
    static featureToggles?: any;
    /* eslint-disable @typescript-eslint/no-explicit-any */

    // from original react-boilerplate
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static createResources (parentEnv?: any) {
        if (parentEnv) {
            Resources.env = parentEnv;
        }

        const AUTHENTICATION_URL = window._env_.AUTHENTICATION_GATEWAY_ENDPOINT;
        const QUESTICON_GATEWAY_URL = window._env_.QUESTICON_GATEWAY;

        Resources.sendFeedback = Resources.env
            .resource('studentFeedback')
            .baseUrl(QUESTICON_GATEWAY_URL)
            .service('/studentFeedback');

        Resources.getQuestionsDetails = Resources.env
            .resource('getQuestionsDetails')
            .baseUrl(QUESTICON_GATEWAY_URL)
            .service('/questDetails');

        Resources.getStrandUow = Resources.env
            .resource('getStrandUow')
            .baseUrl(QUESTICON_GATEWAY_URL)
            .service('/title/course/{courseId}/uow/{uowId}');

        Resources.getQuestionSetPreview = Resources.env
            .resource('getQuestionSetPreview')
            .baseUrl(QUESTICON_GATEWAY_URL)
            .service('/question-preview/{questionSetId}');

        Resources.getQuestion = Resources.env
            .resource('getQuestion')
            .baseUrl(QUESTICON_GATEWAY_URL)
            .service('/Question');

        Resources.saveQuestion = Resources.env
            .resource('saveQuestion')
            .baseUrl(QUESTICON_GATEWAY_URL)
            .service('/QuestionResult');

        Resources.collectCoins = Resources.env
            .resource('collectCoins')
            .baseUrl(QUESTICON_GATEWAY_URL)
            .service('/CollectCoins');

        Resources.questionVariantsFromContentApi = Resources.env
            .resource('questionVariantsFromContentApi')
            .baseUrl(QUESTICON_GATEWAY_URL)
            .service('/Test/questionVariantsFromContentApi/{variantId}');

        Resources.testSympy = Resources.env
            .resource('testSympy')
            .baseUrl(QUESTICON_GATEWAY_URL)
            .service('/Test/testSympy');

        Resources.featureToggles = Resources.env
            .resource('featureToggles')
            .baseUrl(QUESTICON_GATEWAY_URL)
            .service('/Test/featureToggles');

        Resources.getToken = Resources.env.resource('sessions')
            .baseUrl(AUTHENTICATION_URL)
            .service('/sessions');

        Resources.exitQuestionSet = Resources.env
            .resource('exitQuestionSet')
            .baseUrl(QUESTICON_GATEWAY_URL)
            .service('/ExitTest');

        Resources.getReadAloudLocale = Resources.env
            .resource('getReadAloudLocale')
            .baseUrl(QUESTICON_GATEWAY_URL)
            .service('/QuestUserSettings');

        Resources.postReadAloudLocale = Resources.env
            .resource('postReadAloudLocale')
            .baseUrl(QUESTICON_GATEWAY_URL)
            .service('/QuestUserSettings');

        Resources.getConfigurationItem = async (key: string) => {
            try {
                return await Resources.env.getConfigurationItem(key);
            } catch (error) {
                console.error('Retrieving configuration failed. Please check you actually have a config key called "' +
                    key + '" in app named "' + Resources.env.application + '" in Config for environment "' + Resources.env.name + '".');
            }
        };
    }

    // Initialise the environment and resources, based on the provided
    // environment name.  We return the environment so the caller has
    // access to everything, in particular the authToken if one was
    // passed in the query parameters.

    static initialise (envName: string, credentials: Credentials) {
        Resources.env = BasicResources.initialise(envName, credentials);

        Resources.createResources();

        return Resources.env;
    }
}
