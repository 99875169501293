/* istanbul ignore file */
const isDevelopment = process.env.NODE_ENV !== 'production';
const isProduction = process.env.NODE_ENV === 'production';

/**
 * This file is the central place for any immutable configuration settings,
 * it should be standalone and not have any cyclic dependencies.
 */
export const APP_CONFIG = {
    // The application name, to be used when asking the configuration system for settings.
    appName: 'Questicon',
    isDevelopment,
    isProduction,
    // TODO link to best practices for lingui workflow this was raised by Harry in #frontend-guild.
    //
    // Generally at development time you can implement application features and work from a local json file,
    // and use the `npm run lingui:update` tooling. However we need to make sure we are not out of sync with
    // production data on CDN. Use this flag to force loading from CDN.
    shouldLoadLocaleFromCDN: true
};
