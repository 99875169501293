import { Dispatch } from 'redux';

import { setServerErrorIntercepted } from '../containers/question-container/store/question-actions';
import { Resources } from './resources';

class Intercept3pResourcesError {
    static addResourceResponseErrorInterceptor (dispatch: Dispatch) {
        const interceptor = Intercept3pResourcesError.handleErrors.bind(this, dispatch);

        Resources.env?.addResponseInterceptor(interceptor);
    }

    static handleErrors (dispatch: Dispatch, result: Record<string, string | number>) {
        const {
            status,
            code,
            message
        } = result;

        try {
            if (status > 399 && status < 600) {
                dispatch(setServerErrorIntercepted({
                    status,
                    code,
                    message
                }));
            }
        } catch (e) {
            dispatch(setServerErrorIntercepted({
                status: -1,
                code: 'unknownError',
                message: 'unknown error'
            }));
        }

        return result;
    }
}

export default Intercept3pResourcesError;
