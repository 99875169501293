import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { useWithMobile } from '../../hooks/use-with-mobile';
import {
    pickThemeVar, pickThemeFlex
} from '../../styles/theme/app-theme';
import { QuestionConsoleStyledProps } from './question-console.types';

export const QuestionConsoleStyled = styled.div<QuestionConsoleStyledProps>(props => {
    const {
        landscape,
        isMobile,
        isTablet
    } = useWithMobile();
    const {
        showQuestion,
        minHeight,
        theme: {
            app: {
                headerHeight,
                progressPanelHeight,
                questionHeaderHeightCollapsed
            }
        }
    } = props;

    let breakPointMinHeight;

    if (showQuestion) {
        if (minHeight && ((isMobile || (landscape && isMobile)) && !isTablet)) {
            breakPointMinHeight = `${minHeight}px`;
        } else {
            breakPointMinHeight = 'unset';
        }
    } else {
        breakPointMinHeight = `calc(${headerHeight} + ${progressPanelHeight} + ${questionHeaderHeightCollapsed})`;
    }

    return css`
      background: linear-gradient(180deg, #0BB3F4 0%, #13B7F6 18.17%, #4CC9F9 53.41%, #B4ECFF 77.67%, #D3F4FF 100%)
      0 0/100% 573px no-repeat,
      linear-gradient(180deg, #87F8EF 0%, #1EEDDA 24.95%, #01CDDA 51.34%, #01ADE1 73.96%, #018AE9 100%) 0 573px/100% 400px no-repeat,
      linear-gradient(180deg, #018AE9 0%, #018AE9 100%) 0 973px/100% 100px repeat;

      width: 100%;
      ${!((isMobile || (landscape && isMobile)) && !isTablet) && `
        height: 100%;
        ${showQuestion ? `min-height: calc(${headerHeight} + ${progressPanelHeight});` : ''}
        overflow: hidden;
      `}
      position: relative;
      display: flex;
      flex-direction: column;

      .content {
        max-width: ${pickThemeVar('maxContentWidth')(props)};
        min-width: ${pickThemeVar('minContentWidth')(props)};
        position: relative;
        margin: 0 auto;
        flex-grow: 1;
        max-height: calc(100% - ${pickThemeVar('headerHeight')(props)});
        display: flex;
        flex-direction: column;
        
        .left-mountain {
          position: absolute;
          left: -381px;
          top: 250px;
          height: 406px;
          width: 440px;
        }

        .right-mountain {
          position: absolute;
          right: -336px;
          top: 525px;
          height: 153px;
        }
        
        .message-screen {
          ${pickThemeFlex('flexColumnCentered')(props)};
          
          position: absolute;
          top: 0;
          height: 100%;
          margin: 0 32px 32px 32px;
          width: calc(100% - 64px);
        }

        @media (max-width: ${pickThemeVar('breakTabletLandscape')(props)}) {
          max-width: 100%;
        }
        
      }

      @media (max-width: ${pickThemeVar('breakPoint')(props)}) {
        min-height: ${breakPointMinHeight};

        .content {
          width: 100%;
          height: calc(100% - 100px);
          margin:0 auto;
          max-width: unset;
          min-width: unset;
          ${pickThemeFlex('flexColumnCentered')(props)};

          .message-screen {
            width: 100%;
          }
        }
      }
    `;
});

export const ProgressMountainStyled = styled.div`
  position: absolute;
  top: -70px;
  right: -163px;

  .progress-mountain {
    width: 654px;
    height: 716px;
    max-width: none;
  }

  @media (max-width: ${pickThemeVar('breakPoint')}) {
    position: relative;
    right: unset;

    .progress-mountain {
      position: relative;
      right: 35px;
    }

    .right-mountain {
      position: relative;
      left: 36px;
    }
  }
`;
