import {
    useEffect, useState, useMemo, useCallback
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppState } from '../../../app/store/store.types';
import {
    QuestionSetType, STUDENT_STATES, TransitionScreen
} from '../../../helpers/enums';
import { isDefined } from '../../../helpers/utils';
import { ROUTES } from '../../routes';

export const FIRST_MILESTONE = 5;

export const SECOND_MILESTONE = 8;

export const useMilestoneProgress = () => {
    const history = useHistory();
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const {
        numQuestionSteps, questionSetType, studentState,
        progress, updatedProgress, questionsCount
    } = activityState;
    const [allQuestionsComplete, setAllQuestionsComplete] = useState<boolean>(false);
    const [questionNumber, setQuestionNumber] = useState<number>(0);
    const isMilestoneComplete = useMemo(() => {
        const updatedStepsCompleted = updatedProgress?.stepsCompleted;
        const updatedAttempt = updatedProgress?.attempt;

        if (updatedStepsCompleted && updatedAttempt
            && updatedAttempt === 1
            && [FIRST_MILESTONE, SECOND_MILESTONE].includes(updatedStepsCompleted)
            && questionSetType === QuestionSetType.questiconActivity
        ) {
            return true;
        }

        return false;
    }, [updatedProgress?.stepsCompleted, updatedProgress?.attempt, questionSetType]);
    const isQuestComplete = studentState === STUDENT_STATES.summary;
    const navigateToTransitionScreen = (): void => {
        const transitionScreenEnum = updatedProgress?.stepsCompleted === FIRST_MILESTONE ?
            TransitionScreen.startReasoning : TransitionScreen.startProblemSolving;

        history.replace(`${ROUTES.milestoneTransition}/${transitionScreenEnum}`);
    };
    const navigateToSummaryScreen = useCallback((): void => {
        let summaryTransitionScreenEnum;

        if (questionSetType === QuestionSetType.questiconActivity) {
            summaryTransitionScreenEnum = TransitionScreen.summaryScreen;
        } else if(questionSetType === QuestionSetType.diagnostics) {
            summaryTransitionScreenEnum = TransitionScreen.diagnosticsSummaryScreen;
        } else if(questionSetType === QuestionSetType.unitTest) {
            summaryTransitionScreenEnum = TransitionScreen.unitTestSummaryScreen;
        }

        history.replace(`${ROUTES.milestoneTransition}/${summaryTransitionScreenEnum}`);
    }, [history, questionSetType]);

    useEffect(() => {
        isQuestComplete && navigateToSummaryScreen();
    }, [isQuestComplete, navigateToSummaryScreen]);
    /*
    * QUESTION NUMBER
    */
    useEffect(() => {
        const { stepsCompleted } = progress || {};

        let questionTotal = numQuestionSteps;

        if (questionSetType === QuestionSetType.unitTest) {
            questionTotal = questionsCount;
        }

        if (isDefined(stepsCompleted) && isDefined(questionTotal)) {
            setQuestionNumber(stepsCompleted < questionTotal ? stepsCompleted + 1 : questionTotal);
        }
    }, [progress, numQuestionSteps, questionSetType, questionsCount]);
    /*
   * ALL QUESTIONS COMPLETE
   */
    useEffect(() => {
        const { stepsCompleted } = progress || {};

        if (isDefined(stepsCompleted) && isDefined(numQuestionSteps)) {
            setAllQuestionsComplete(stepsCompleted === numQuestionSteps);
        }
    }, [progress, numQuestionSteps]);

    return {
        questionNumber,
        allQuestionsComplete,
        isMilestoneComplete,
        navigateToTransitionScreen
    };
};
