import React from 'react';

import {
    QuestionSetType, TransitionScreen
} from '../../../../helpers/enums';
import { Guide } from '../guide/guide';
import { MilestoneMessage } from '../milestone-message/milestone-message';
import { MessageBoxStyled } from './message-box.styled';

export type MessageBoxProps = {
    type: TransitionScreen
    questionSetType?: QuestionSetType
    hasGuide?: boolean
};

export const MessageBox = ({
    type, questionSetType
}: MessageBoxProps) => {
    const isUPT = type === TransitionScreen.unitTestIntroScreen ||
        type === TransitionScreen.unitTestSummaryScreen ||
        questionSetType === QuestionSetType.unitTest;

    return (
        <MessageBoxStyled type={type} data-testid={'message-box'} hasGuide={!isUPT}>
            <div className={'message-box-content'}>
                {!isUPT && <div className={'guide-holder-holder'}>
                    <div className={'guide-holder'}>
                        <Guide />
                    </div>
                </div>}
                <div className={'message-holder'}>
                    <MilestoneMessage type={type} />
                </div>
            </div>
        </MessageBoxStyled>
    );
};

export default MessageBox;
