import { css } from '@emotion/react';

import { AppThemeType } from './theme/app-theme';

export const baseAppStyle = (theme: AppThemeType) => css`
    html, body {
        font-family: ${theme.typography.fontFamily};
    }

    body {
        margin: 0;
        background-color: ${theme.app.appBgColour};
        overflow: hidden;
    }

    .application {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        *, *::before, ::after {
          border-color: transparent;
          box-sizing: content-box;
        }

        .question-set-type-0, .single-variant-preview-tests-container {
            .rotate-device-modal-content {
                margin-top: 175px;
            }
        }

        .single-variant-preview-container {
            .rotate-device-modal-content {
                margin-top: 165px;
            }
        }

        .root-container.question-set-type-0,
        .root-container.single-variant-preview-container {
            &[data-rotate-modal-active="true"] {
                & > :first-of-type {
                    z-index: 0;
                }
            }
        }
    }

    /*::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: white;
    }

    ::-webkit-scrollbar-track {
        background-color: ${theme.app.white01};
    }

    ::-webkit-scrollbar-thumb {
        background: lightgray;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: darkgrey;
    }*/

    // build version number, display after trooper build after deploy to demo|qa|live
    .version-number {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 10;
        color: #aaa; // static color - no change required
        font-size: 9px;
    }
`;
