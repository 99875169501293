import {
    Box,
    IconButton
} from '@chakra-ui/react';
import React, { FC } from 'react';

import AppImage from '../../components/app-image/app-image';
import {
    Nav,
    NavButtonsProps
} from './preview.types';

export const NavButtons: FC<NavButtonsProps> = ({ navigate }) => {
    return (
        <Box>
            <IconButton
                aria-label={'backward'}
                icon={<AppImage iconType={'chevronRight'} imageType={'svg'} />}
                onClick={() => navigate(Nav.backward)}
                className={'btn back'}
            />
            <IconButton
                aria-label={'forward'}
                icon={<AppImage iconType={'chevronRight'} imageType={'svg'} />}
                onClick={() => navigate(Nav.forward)}
                className={'btn'}
            />
        </Box>
    );
};
