import {
    useEffect, useState
} from 'react';

import {
    loadI18n, getURLLocale
} from '../utils/locale-utils';

type Options = {
    locale?: string
    baseUrl?: string
};

/**
 * This hook will load the required data once for the before the application is ready to
 * render for the first time.
 */
export function useActivateI18n (options?: Options) {
    const locale = options?.locale || getURLLocale();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    // Load the required i18n data dynamically either from CDN or a local bundle
    useEffect(() => {
        loadI18n({
            ...options,
            locale
        }).then(() => {
            setIsLoading(false);
        }).catch((error: unknown) => {
            console.error('useActivateI18n unable to load i18n json data or fallback', error);
            setIsError(true);
        });
    }, [locale, options]);

    return {
        isLoading,
        isError
    };
}
