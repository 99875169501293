import axios from 'axios';

import { featureFlags } from '../helpers/constants';
import { Resources } from '../resources/resources';
import { Credentials } from '../types/common.types';
import EntryApp from './entry-app';

export const getFeatureToggles = async () => {
    const response = await Resources.featureToggles.post().withBody(featureFlags).send();

    if (response.data) {
        window.questiconFeatureToggles =  response.data;
    } else {
        throw new Error('Server error');
    }
};

export const resourceSetting = async (credentials: Credentials, envName:string, config: Record<string, string>) => {
    Resources.initialise(envName, credentials);
    Resources.env.setConfiguration(config);
    try {
        await getFeatureToggles();
    } catch (e) {
        console.log(e);
    }
    EntryApp.renderElement();
};

export const getToken = async (credentials: Credentials, envName:string, config: Record<string, string>) => {
    try {
        // No choice but to use axios because 3P Resources hasn't even been initialised yet
        // but this is only for Development environment,
        // down the road, we need to remove of 3P resources because
        // we no longer use Config Manager
        const response = await axios({
            method: 'post',
            url: `${window._env_.AUTHENTICATION_GATEWAY_ENDPOINT}/sessions`,
            data: {
                userName: credentials.username,
                password: credentials.password,
                productId: 0
            }
        });

        if (response.data) {
            credentials = {
                ...credentials,
                authToken: response.data.token,
                username: undefined,
                password: undefined
            };
            resourceSetting(credentials, envName, config);
        } else {
            console.log('development env - auth error');
        }
    } catch(e) {
        console.log(`development env - auth error - ${e}`);
    }
};
