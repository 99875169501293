import {
    cssVar, SystemStyleFunction
} from '@chakra-ui/theme-tools';

const $bg = cssVar('tooltip-bg');
const baseStyle: SystemStyleFunction = ({ theme }) => ({
    [$bg.variable]: 'white',
    color: theme.app.fontGrey01,
    borderRadius: 'md',
    px: 0,
    py: 0,
    maxWidth: 'unset',
    boxShadow: `0 5px 20px 0 ${theme.app.grey06}`
});

export const Tooltip = { baseStyle };
