import {
    Box, Flex, useToken
} from '@chakra-ui/react';
import React from 'react';

import { QuestionAttemptPillProps } from './question-attempt-pill.types';

export const QuestionAttemptPill: React.FC<QuestionAttemptPillProps> = ({
    status,
    isCurrentQuestion
}) => {
    const [neutral400, green500] = useToken('colors', ['neutral.400', 'green.500']);
    const getOutlineColors = (attemptStatus: QuestionAttemptPillProps['status']) => {
        if (isCurrentQuestion) {
            return {
                borderColor: 'primary.500',
                borderWidth: '2px'
            };
        }

        switch (attemptStatus) {
        case 'Correct':
            return {
                borderColor: `${green500}66`,
                borderWidth: '1px'
            };
        case 'Incorrect':
            return {
                borderColor: `${neutral400}66`,
                borderWidth: '1px'
            };
        case 'Unattempted':
            return {
                borderColor: 'transparent',
                borderWidth: '1px'
            };
        }
    };
    const getFillColors = (attemptStatus: QuestionAttemptPillProps['status']) => {
        if (isCurrentQuestion && attemptStatus === 'Unattempted') {
            return {
                bg: 'linear-gradient(103.65deg, #00ABE8 -2.03%, #007ACC 43.7%, #0063A7 105.46%)',
                border: 'none'
            };
        }

        switch (attemptStatus) {
        case 'Correct':
            return {
                bgColor: 'green.400',
                border: 'none'
            };
        case 'Incorrect':
            return {
                bgColor: 'neutral.500',
                border: 'none'
            };
        case 'Unattempted':
            return {
                bgColor: 'transparent',
                border: '1px',
                borderColor: 'neutral.300',
                borderStyle: 'solid'
            };
        }
    };

    return (
        <Flex
            alignItems={'center'}
            justifyContent={'center'}
            border={'1px solid'}
            borderRadius={'50%'}
            boxSizing={isCurrentQuestion ? 'content-box' : 'border-box'}
            w={{
                md: '26px',
                base: '15px'
            }}
            h={{
                md: '26px',
                base: '15px'
            }}
            {...getOutlineColors(status)}
        >
            <Box
                borderRadius={'50%'}
                w={{
                    md: '16px',
                    base: '8px'
                }}
                h={{
                    md: '16px',
                    base: '8px'
                }}
                {...getFillColors(status)}
            />
        </Flex>
    );
};
