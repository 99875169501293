import {
    Box, Flex, useTheme
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { QuestionSetType } from '../../../src/helpers/enums';
import { AppThemeType } from '../../../src/styles/theme/app-theme';
import { AppState } from '../../app/store/store.types';
import { Header } from '../../components/header/header';
import { QuestionContainer } from '../../containers/question-container/question-container';
import { useRouteChecker } from '../../hooks/use-route-checker';
import unitTestBackgroundImg from '../diagnostics/assets/UPT_Background.svg';
import backgroundImg from './assets/AYR_Background.svg';
import { useDiagnosticsResponsiveStyles } from './diagnostics.hooks';

export const Diagnostics = () => {
    useRouteChecker();

    const { diagnosticsResponsiveStyles } = useDiagnosticsResponsiveStyles();
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const theme: AppThemeType = useTheme();
    const scrollableContainer = useRef<HTMLDivElement>(null);

    return (
        <Box
            bgImage={`url(${activityState.questionSetType === QuestionSetType.diagnostics ? backgroundImg : unitTestBackgroundImg})`}
            bgRepeat={'no-repeat'}
            bgSize={'cover'}
            {...(activityState.questionSetType === QuestionSetType.unitTest && {
                bgPosition: {
                    base: 'center',
                    lg: '0 0'
                }
            })}
            w={'100%'}
            h={'100%'}
            boxSizing={'border-box'}
            overflow={'hidden'}
            ref={scrollableContainer}
            {...diagnosticsResponsiveStyles()}
        >
            <Flex
                direction={'column'}
                w={'100%'}
                h={'100%'}
            >
                <Box>
                    <Header showZoomOutBtn showReadAloudBtn showExitBtn />
                </Box>
                <Box
                    maxW={`calc(${theme.app.testsStageMaxWidth} + (2 * ${theme.app.questionContainerMargin}))`}
                    mx={'auto'}
                    position={'relative'}
                    flexGrow={1}
                    w={'100%'}
                    h={{
                        base: 'auto',
                        lg: 'calc(100% - 100px)'
                    }}
                >
                    <QuestionContainer scrollableContainer={scrollableContainer.current} />
                </Box>
            </Flex>
        </Box>
    );
};
