import { Content } from '@3plearning/question-components-library';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { getQuestionFailure } from '../../containers/question-container/store/question-actions';
import { Resources } from '../../resources/resources';
import {
    Nav,
    QuestionSetProps
} from './preview.types';
import { loadingData } from './test/mock-data';

export const useQuestionSet = () => {
    const dispatch = useDispatch();
    const [questionSet, setQuestionSet] = useState<QuestionSetProps>({
        questName: '',
        keypadPreset: 0,
        questions: loadingData as Content[]
    });
    const getQuestionSet = async (questionSetId: string, locale: string) => {
        try {
            const response = await Resources.getQuestionSetPreview
                .get({ questionSetId })
                .withParams({ locale })
                .send();

            if (response.data) {
                setQuestionSet(response.data);
            } else {
                dispatch(getQuestionFailure());
            }
        } catch (e) {
            dispatch(getQuestionFailure());
        }
    };

    return {
        questionSet,
        getQuestionSet
    };
};

export const useNav = (totalQuestions: number) => {
    const [questionNumber, setQuestionNum] = useState(0);
    const navigate = (type: number) => {
        if (questionNumber === totalQuestions - 1 && type === Nav.forward) {
            setQuestionNum(0);
        } else if (questionNumber === 0 && type === Nav.backward) {
            setQuestionNum(totalQuestions - 1);
        } else if (type === Nav.forward) {
            setQuestionNum(questionNumber + 1);
        } else if (type === Nav.backward) {
            setQuestionNum(questionNumber - 1);
        }
    };

    return {
        questionNumber,
        navigate
    };
};
