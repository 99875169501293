import styled from '@emotion/styled';

const questionHeaderHeight = 68;

export const PreviewCtnr = styled.div`
   width: 100%;
   height: 100%;
`;

export const StageCtnr = styled.div`
  height: 100%;
  margin-left: 1px;
  background-color: white;
  overflow: hidden;
`;

export const NavCtnr = styled.div`
  height: ${questionHeaderHeight}px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .question {
    text-transform: uppercase;
    color: #5C6C7F;
    font-size: 14px;
    margin-right: 12px;
  }

  .question-num {
    font-weight: bold;
    color: #001937
  }

  .btn {
    border: #99DDF6 solid 1px;
    background-color: white;
    border-radius: 8px;
    width: 56px;
    height: 44px;
    box-shadow: 0px 1px 2px 0px #0019370F;
    margin-right: 32px;

    &.back {
      transform: scaleX(-1);
      margin-right: 4px;
    }
  }
`;

export const QuestionCtnr = styled.div`
  height: calc(100% - ${questionHeaderHeight}px);
  overflow: auto;
  padding: 0 32px;
`;
