import { CloseButton } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import AppImage from '../../../../components/app-image/app-image';
import { useWithMobile } from '../../../../hooks/use-with-mobile';
import {
    pickThemeFlex, pickThemeVar
} from '../../../../styles/theme/app-theme';
import {
    QuestionActivityStyledProps, ValidationStyledProps
} from './question-activity.types';

export const QuestionActivityStyled = styled.div<QuestionActivityStyledProps & ValidationStyledProps>(props => {
    const {
        landscape,
        isMobile,
        isTablet
    } = useWithMobile();
    const {
        showQuestion,
        isWorkedSolutionActivated,
        theme: {
            app: {
                headerHeight,
                questionHeaderHeight,
                green04,
                progressPanelWidth,
                progressPanelHeight,
                progressPanelLeftMargin,
                stickyHintContainerHeight,
                questionBodyPadding
            }
        },
        isDiagnostic,
        isGuideImageRemoved
    } = props;

    return css`
      flex-grow: 1;
      min-height: 100%;
      border-radius: ${pickThemeVar('borderRadius')(props)};
      transition: width 0.5s;
      color: ${pickThemeVar('neutral900')(props)};
      display: flex;
      flex-direction: column;
      position: relative;
      width: calc(100vw - ${progressPanelWidth} - ${progressPanelLeftMargin});
      ${isDiagnostic ? '' : `max-width: calc(100% - ${progressPanelWidth} - ${progressPanelLeftMargin});`}

      .question-header {
        width: 100%;
        flex-shrink: 0;

        .question-header-title {
          background: rgba(255,255,255,0.5);
          backdrop-filter: blur(111px);
          width: 100%;
          min-height: ${pickThemeVar('questionHeaderHeight')(props)};
          border-top-left-radius: ${ isGuideImageRemoved ? '20px' : '35px'};
          border-top-right-radius: 20px;
          flex-shrink: 0;
          padding-left: ${showQuestion && !isGuideImageRemoved && '84px'};
          box-sizing: border-box;
          text-align: center;

          ${pickThemeFlex('flexChildCentered')(props)}

          .quest-name {
            color: ${pickThemeVar('fontPrimary')(props)};
            margin: ${isGuideImageRemoved ? '11px 0px' : '11px 24px 11px 0px'};
          }

          .show-question-button {
            width: 100%;
            margin: 12px 24px;
          }
        }

        .question-header-hint {
          width: 100%;
          display: ${isWorkedSolutionActivated ? 'none' : 'flex'};
          justify-content: flex-end;
          background-color: white;
        }
      }

      .question-holder {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        background-color: white;
        box-shadow: 0px 3.28351px 6.56701px 0.820876px rgba(0, 25, 55, 0.08);
        border-radius: 0px 0px 20px 20px;
        flex-grow: 1;
        /* height must be passed down to WorkedSolutionWrapper for ios 14 for it to be scrollable */
        height: 100%;
        ${!((isMobile || (landscape && isMobile)) && !isTablet) && `
          min-height: calc(100% - ${headerHeight} - ${questionHeaderHeight});
        `}

        @media (min-height: 876px) {
          ${!((isMobile || (landscape && isMobile)) && !isTablet) && `
            min-height: calc(${progressPanelHeight} - ${questionHeaderHeight} - ${stickyHintContainerHeight});
          `}
        }
      }

      ${isDiagnostic ? '' : `
        @media (max-width: ${pickThemeVar('breakTabletLandscape')(props)}) {
          max-width: calc(100% - ${progressPanelWidth} - 24px);
        }
      `}
      
     
      @media (max-width: ${pickThemeVar('breakPoint')(props)}) {
        position: ${showQuestion ? 'absolute' : 'fixed'};
        transition: top 0.5s;
        z-index: 1;
        top: ${showQuestion ? 0 : `calc(100% - ${pickThemeVar('questionHeaderHeightCollapsed')(props)})`};
        ${!((isMobile || (landscape && isMobile)) && !isTablet) && `
            height: 100%;
        `}
        width: calc(100% - 2 * ${questionBodyPadding});
        max-width: unset;

        .question-header-title {
          background: ${!showQuestion && 'white'};
          width: ${showQuestion && 'auto'};

          .quest-name {
            display: ${!showQuestion && 'none'};
          }
        }

        .question-holder {
          /* make question look uniform during hide animation */
          margin-top: 0;
        }

        .activity-guide {
          display: ${!showQuestion && 'none'};
        }
      }

      @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')(props)}) {
        width: calc(100% - 32px);

        .question-header-title {
          .quest-name {
            font-size: 13px;
            margin: 8px 16px 8px 0px;
          }
        }
      }

      ${props.isValidated && props.isCorrect && showQuestion ? `
        &::after {
          position: absolute;
          top: 0;
          height: 80px;
          background-color: white;
          content: '${props.correctLabel}';
          color: white;
          font-size: 24px;
          display: block;
          overflow: hidden;
          width: 0;
          transform: translateX(0);
          line-height: 80px;
          border-radius: 40px;
          padding: 0 25px 0 61px;
          text-align: center;
          box-shadow: 0px 3px 15px rgb(0 0 0 / 10%), 0px 4px 6px rgb(0 0 0 / 5%);
          animation: slide 0.8s forwards ease-out;
        

        @keyframes slide {
            100% {
              color: ${green04};
              transform: translateX(40px);
              width: 217px;
              border-radius: 15px;
            }
        }

        @media (max-width: ${pickThemeVar('breakPoint')(props)}) {
          @keyframes slide {
            100% {
              color: ${green04};
              transform: translateX(40px);
              width: 197px;
              border-radius: 15px;
            }
          }
        }
      }
      ` : ''}
  `;
});

export const QuestionBody = styled.div<{ isWorkedSolutionActivated: boolean }>(props => {
    const {
        landscape,
        isMobile,
        isTablet
    } = useWithMobile();
    const {
        isWorkedSolutionActivated, theme: {
            app: {
                breakPoint, questionBodyPadding, questionBodyPaddingMobile
            }
        }
    } = props;

    return css`
      flex-grow: 1;
      padding: ${questionBodyPadding};
      padding-top: 0px;
      overflow-y: ${isWorkedSolutionActivated ? 'hidden' : 'auto'};
      overflow-x: hidden;
      position: relative;
      left: -20px;
      padding-left: calc(20px + ${questionBodyPadding});
      margin-right: -20px;
      /* height must be passed down to WorkedSolutionWrapper for ios 14 for it to be scrollable */
      ${!((isMobile || (landscape && isMobile)) && !isTablet) && `
            height: 100%;
      `}

      :focus {
        outline: none;
      }

      @media (max-width: ${breakPoint}) {
        padding: ${questionBodyPaddingMobile};
        padding-top: 0px;
        left: -16px;
        margin-right: -16px;
        padding-left: calc(16px + ${questionBodyPaddingMobile});
      }
    `;
});

export const QuestionContent = styled.div<{ isWorkedSolutionActivated: boolean }>(props => {
    const {
        isWorkedSolutionActivated, theme: {
            app: {
                stickyHintContainerHeight, breakPoint
            }
        }
    } = props;
    const {
        landscape,
        isMobile,
        isTablet
    } = useWithMobile();

    return css`
      flex-grow: 1;
      ${((isMobile || (landscape && isMobile)) && !isTablet) && `
            margin-bottom: 72px;
      `}
      ${isWorkedSolutionActivated && css`
        position: absolute;
        padding-top: ${stickyHintContainerHeight};
        // keep validation colour in background visible and pad content
        padding-right: 68px;
        box-sizing: border-box;
        width: 100%;

        @media (max-width: ${breakPoint}) {
          padding-right: 56px;
        }
      `}
    `;
});

export const ActivityGuide = styled(AppImage)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  transform: translate(-2px, -2px);
`;

export const WorkedSolutionCloseButton = styled(CloseButton)(props => {
    const {
        theme: {
            app: {
                questionBodyPadding, breakTabletPortraitPhone
            },
            colors
        }
    } = props;

    return css`
      :active {
        background-color: ${colors.primary['600']};
        color: white;
      }
      border-color: ${colors.primary['500']};
      position: absolute;
      top: 0;
      right: ${questionBodyPadding};
      transform: translate(50%, -50%);
      z-index: 101;
      animation: fade-in 1s forwards;

      @media (max-width: ${breakTabletPortraitPhone}) {
        right: 0;
      }

      @keyframes fade-in {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `;
});
