import {
    Box,
    Text
} from '@chakra-ui/react';
import React, { FC } from 'react';

import { lokalisation } from '../../helpers/lokalisation';
import { NavButtons } from './nav-buttons';
import { NavCtnr } from './preview.styled';
import { QuestionHeaderProps } from './preview.types';

export const QuestionHeader: FC<QuestionHeaderProps> = ({
    navigate,
    totalQuestions,
    questionNumber
}) => {
    const { previewTxt } = lokalisation();

    return (
        <NavCtnr>
            <Box className={'question'}>
                {previewTxt.question} <Text as={'span'} className={'question-num'}>{questionNumber + 1}</Text>/{totalQuestions}
            </Box>
            <NavButtons navigate={navigate} />
        </NavCtnr>
    );
};
