/*
 * ACTIVITY STATE
 */
import { useSelector } from 'react-redux';

import { AppState } from '../../../app/store/store.types';
import { getFinalTotals } from '../sub-components/summary/utils/util';

export const useConfetti = () => {
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const { progress } = activityState;
    const {
        finalTotal: {
            correct, questions
        }
    } = getFinalTotals({ progress });
    const score = (questions && correct / questions) || 0;

    return score * 100 >= 50;
};
