import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../app/store/store.types';
import { default as Modal } from '../../components/modals/fluency-fail-modal/fluency-fail-modal';

const FluencyFail = () => {
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const {
        progress, questionSetType
    } = activityState;
    const { ...chakraModalProps } = useDisclosure();

    return (
        <Modal
            questionSetType={questionSetType}
            {...chakraModalProps}
            isOpen={progress?.isFluencyStepPassed === false && progress?.isFluencyStepPassed !== undefined}
        />
    );
};

export default FluencyFail;
