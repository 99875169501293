import {
    Flex, Divider
} from '@chakra-ui/react';
import React from 'react';

import { QuestionSetType } from '../../../src/helpers/enums';
import { useProgressActivity } from '../../containers/question-container/hooks/use-progress-activity';
import { QuestionAttemptPill } from '../question-attempt-pill/question-attempt-pill';
import { UnitTestQuestionAttempts } from '../unit-test-question-attempts/unit-test-question-attempts';

export const QuestionAttempts = () => {
    const {
        progressData,
        questionCorrect,
        questionAnswered,
        questionSetType
    } = useProgressActivity();

    if (questionSetType === QuestionSetType.unitTest) {
        return <UnitTestQuestionAttempts />;
    }
    const allPoints = progressData?.points?.reduce(
        (finalPoints, section) => [...finalPoints, ...section.stars], [] as number[]
    );

    return (
        <Flex alignItems={'center'}>
            {allPoints?.map((point, index) => {
                const attemptNum = index + 1;
                const isCorrect = point > 0;
                const isCurrentQuestion = progressData?.stepsCompleted === (questionAnswered ? attemptNum : index);
                const currentAttemptStatus = questionCorrect ? 'Correct' : 'Incorrect';
                const attemptStatus = isCorrect ? 'Correct' : 'Incorrect';
                const validationStatus = isCurrentQuestion ? currentAttemptStatus : attemptStatus;

                return (
                    <React.Fragment key={attemptNum}>
                        <QuestionAttemptPill
                            isCurrentQuestion={isCurrentQuestion}
                            status={attemptNum > (progressData?.stepsCompleted || 0) ? 'Unattempted' : validationStatus}
                        />
                        {attemptNum < (allPoints?.length || 0) && <Divider
                            w={{
                                md: '22px',
                                base: '3px'
                            }}
                            bgColor={'neutral.100'}
                        />}
                    </React.Fragment>
                );
            })}
        </Flex>
    );
};
