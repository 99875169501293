import React from 'react';

import AppImage from '../../../../components/app-image/app-image';
import { MAX_ATTEMPTS } from '../../../../helpers/constants';
import { lokalisation } from '../../../../helpers/lokalisation';
import { useProgressActivity } from '../../hooks/use-progress-activity';
import { QuestionProgressProps } from '../../question-container.types';
import {
    AttemptsStatusStyled, AttemptStarStyled
} from './attempt-status.styled';

export const AttemptStatus = () => {
    const { labelTxt: { attempt } } = lokalisation();
    /*
     * USE PROGRESS ACTIVITY HOOK
     */
    const questionProgress: QuestionProgressProps = useProgressActivity();
    const {
        progressAttempt, questionAnswered, questionCorrect
    } = questionProgress;
    const getIcon = (attemptNum: number) => {
        if(attemptNum === progressAttempt) {
            return (questionCorrect ?
                <div className={'icon correct'} data-testid={'correct-icon'}>
                    <AppImage imageType={'svg'} iconType={'attemptTick'} />
                </div>:
                <div className={'icon incorrect'} data-testid={'incorrect-icon'}>
                    <AppImage imageType={'svg'} iconType={'attemptCross'} />
                </div>
            );
        } else if(attemptNum > progressAttempt) {
            return <div className={'icon answer'} data-testid={'answer-icon'} />;
        }
    };

    return (
        <AttemptsStatusStyled questionProgress={questionProgress} >
            <div className={'attempt-state'}>{!questionAnswered && attempt(progressAttempt)}</div>
            <div className={'attempt-capsule'} data-testid={'attempt-capsule'} />
            <div className={'attempt-stars'}>
                {Array(MAX_ATTEMPTS).fill('').map((value, index) => {
                    const attemptNum = index + 1;

                    return (
                        <AttemptStarStyled
                            key={index}
                            questionProgress={questionProgress}
                            attemptNum={attemptNum}
                        >
                            {questionAnswered && getIcon(attemptNum)}
                            <AppImage
                                imageType={'svg'}
                                iconType={'star'}
                                className={'star'}
                            />
                        </AttemptStarStyled>
                    );}
                )}
            </div>
        </AttemptsStatusStyled>
    );
};
