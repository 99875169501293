import { FeedbackProps } from './summary.types';

export const feedbackByScore = (feedbacks?: FeedbackProps[], score?: number) => {
    if (!feedbacks || (!score && score != 0)) { return; }
    let index = 0;

    const praises = feedbacks.length ? feedbacks : [{
        id: '-1',
        percentage: -1,
        feedback: ''
    }];

    praises.sort((a: FeedbackProps, b: FeedbackProps) => b.percentage - a.percentage);

    praises.forEach((feedback: FeedbackProps, ind: number) => {
        if (score <= feedback.percentage) {
            index = ind;
        }
    });

    return [praises[index].feedback];
};
