/* eslint-disable*/

import { css } from '@emotion/react';

/*
  Font taken from Google fonts:
  https://use.typekit.net/bcv1gms.css
*/
export const fonts = css`
  /* devanagari */

  /* Sofia Pro Bold aliases */
  @font-face {
    font-family:"sofia-pro";
    src:url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;
  }
  @font-face {
    font-family: 'SofiaPro-Bold';
    src:url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;
  }

  /* Sofia Pro Italic Bold aliases */
  @font-face {
    font-family:"sofia-pro";
    src:url("https://use.typekit.net/af/e30e66/00000000000000007735a151/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/e30e66/00000000000000007735a151/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/e30e66/00000000000000007735a151/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:700;
  }

  /* Sofia Pro Regular aliases */
  @font-face {
    font-family:"sofia-pro";
    src:url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
  }
  @font-face {
    font-family: 'Sofia-Pro';
    src:url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
  }
  @font-face {
    font-family: 'SofiaProRegular';
    src:url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
  }
  @font-face {
    font-family: 'Sofia Pro';
    src:url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
  }

  /* Sofia Pro Italic Regular aliases */
  @font-face {
    font-family:"sofia-pro";
    src:url("https://use.typekit.net/af/37de2f/00000000000000007735a165/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/37de2f/00000000000000007735a165/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/37de2f/00000000000000007735a165/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:400;
  }
  @font-face {
    font-family: 'Sofia Pro';
    src:url("https://use.typekit.net/af/37de2f/00000000000000007735a165/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/37de2f/00000000000000007735a165/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/37de2f/00000000000000007735a165/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:400;
  }

  @font-face {
      font-family: 'KaTeX_SansSerif-Regular';
      src: url('../assets/sansserifpartial/assets/fonts/KaTeX_SansSerif-Regular.woff2') format('woff2');
      font-display: auto;
      font-style: normal;
      font-weight: 400;
  }

  @font-face {
      font-family: 'KaTeX_SansSerif-Italic';
      src: url('../assets/sansserifpartial/assets/fonts/KaTeX_SansSerif-Italic.woff2') format('woff2');
      font-display: auto;
      font-style: italic;
      font-weight: 400;
  }

  @font-face {
      font-family: 'KaTeX_SansSerif-Bold';
      src: url('../assets/sansserifpartial/assets/fonts/KaTeX_SansSerif-Bold.woff2') format('woff2');
      font-display: auto;
      font-style: normal;
      font-weight: 700;
  }

  @font-face {
    font-family:"KaTeX_Main_Original";
    src:url("../assets/sansserifpartial/assets/fonts/KaTeX_Main_Original-Regular.woff2") format("woff2");
    font-display:auto;font-style:normal;font-weight:normal;
  }

  @font-face {
    font-family:"KaTeX_Main_Original";
    src:url("../assets/sansserifpartial/assets/fonts/KaTeX_Main_Original-Italic.woff2") format("woff2");
    font-display:auto;font-style:italic;font-weight:normal;
  }

  @font-face {
    font-family:"KaTeX_Main_Original";
    src:url("../assets/sansserifpartial/assets/fonts/KaTeX_Main_Original-Bold.woff2") format("woff2");
    font-display:auto;font-style:normal;font-weight:bold;
  }

  @font-face {
    font-family:"KaTeX_Main_Original";
    src:url("../assets/sansserifpartial/assets/fonts/KaTeX_Main_Original-BoldItalic.woff2") format("woff2");
    font-display:auto;font-style:italic;font-weight:bold;
  }
`;
