import React from 'react';

import AppImage from '../../../../components/app-image/app-image';
import { GuideStyled } from './guide.styled';

export const Guide = () => {
    return (
        <GuideStyled>
            <AppImage imageType={'img'} iconType={'guide'} />
        </GuideStyled>
    );
};
