import queryString from 'querystring';

import { Content } from '@3plearning/question-components-library';

import { Progress } from '../containers/question-container/sub-components/question-activity/question-activity.types';
import { ConceptRefreshContent } from '../routes/milestone-transition/sub-components/intro-screen/intro-screen.types';

export const getQueryParam = (param: string) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(param);
};

export const getQueryParamValues = (params: string[], queryString = window.location.search) => {
    const urlParams = new URLSearchParams(queryString);

    return params.reduce((acc, item) => {
        const value = urlParams.get(item);

        if (value) {
            acc[item] = value;
        }

        return acc;
    }, {} as Record<string, string>);
};

export function getQueryParams (query: string) {
    const params = query.replace('?', '');

    return queryString.parse(params) as Record<string, string>;
}

/**
 * Use this for strict typing a defined value for an undefined & null check.
 * For example when values from the C Sharp JSON serializer
 * default to null despite swagger docs, this can all assert the type that it is defined.
 */
export function isDefined<TValue> (value: TValue | undefined | null): value is TValue {
    return value !== undefined && value !== null;
}

// Not truly random, please don't use for anything that needs any security
export function getRandomNumBetween (min: number, max: number) {
    return Math.random() * (max - min) + min;
}

// Not truly random, please don't use for anything that needs any security
export function getRandomStringOfLength (length: number): string {
    let result = '';

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

/**
 * Determine if the current runtime is the Jest test runner,
 * this env is specified in the jest docs here.
 *
 * @see https://jestjs.io/docs/environment-variables
 * @see https://stackoverflow.com/a/52231746
 */
export function isInJest (): boolean {
    return window?.process?.env?.JEST_WORKER_ID !== undefined;
}

export function getRandomArrayElement<T> (array: Array<T> = []): T {
    return array[Math.floor(Math.random() * (array.length))];
}

export const transformSchema = (content?: Content | ConceptRefreshContent | {
    content: []
    segments: { type: 'text' | 'latex'
    value: string
}[] }[]) =>
    content?.map(block => ({
        ...block,
        content: (block as { content: []; segments: { type: 'text' | 'latex'; value: string; text: string }[] }).segments?.map(
            segment => ({
                ...segment,
                [segment.type === 'latex' ? 'attrs' : 'text']: segment.type === 'latex' ?  { value: segment.value } : segment.value
            })
        ) || block.content
    }));

export const getPoints = (progress?: Progress) => progress?.points?.reduce(
    (totalPoints, section) => totalPoints + section.stars.reduce(
        (sectionTotal, questionResult) => {
            if (questionResult > 0) {
                return sectionTotal + section.pointsPerQuestion;
            }

            return sectionTotal;
        }, 0),
    0);

export const getTotalNumStars = (progress?: Progress) => progress?.points?.reduce(
    (totalPoints, section) => totalPoints + section.stars.reduce(
        (sectionTotal, questionResult) => {
            return sectionTotal + questionResult;
        }, 0),
    0);

export const returnToStudentPortal = () => {
    try {
        const searchParams = new URLSearchParams(document.location.search);
        const studentPortalUrl = searchParams.get('backUrl');

        if (studentPortalUrl) {
            window.location.href = studentPortalUrl;
        } else {
            window.close();
        }
    } catch (error) {
        console.log(error);
    }
};
