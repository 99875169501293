import React, {
    useState, useEffect
} from 'react';

import AppImage from '../../../../components/app-image/app-image';
import { AppImageTypes } from '../../../../components/app-image/app-image-types';
import {
    QuestionSetType, TransitionScreen
} from '../../../../helpers/enums';
import diagnosticBackgroundImg from '../../../diagnostics/assets/AYR_Background.svg';
import unitTestBackgroundImg from '../../../diagnostics/assets/UPT_Background.svg';
import {
    TransitionBgStyled, SeaGradient, SkyGradient, Clouds, Dots, BgWrap
} from './transition-bg.styled';

export type TransitionBgProps = {
    type: TransitionScreen
    quesetionSetType?: QuestionSetType
    bgSrc?: string
};

export const TransitionBg = ({
    type, quesetionSetType
}: TransitionBgProps) => {
    const [leftBgSrc, setLeftBgSrc] = useState<AppImageTypes>();
    const [rightBgSrc, setRightBgSrc] = useState<AppImageTypes>();
    const [rightMountainSrc, setRightMountainSrc] = useState<AppImageTypes>();
    const [hasClouds, setHasClouds] = useState<boolean>(true);
    const transitionBgProps: TransitionBgProps = { type: isTestTransitionScreen(type) ? 0 : type };

    useEffect(() => {
        switch (type) {
        case TransitionScreen.startScreen:
            setLeftBgSrc('leftMountain');
            setRightMountainSrc('progressMountain');
            break;
        case TransitionScreen.startFluency:
            setLeftBgSrc('leftFluency');
            setRightBgSrc('rightFluency');
            setRightMountainSrc('progressMountain');
            break;
        case TransitionScreen.startReasoning:
            setLeftBgSrc('leftReasoning');
            setRightBgSrc('rightReasoning');
            break;
        case TransitionScreen.startProblemSolving:
            setLeftBgSrc('leftProblemSolving');
            setRightBgSrc('rightProblemSolving');
            break;
        case TransitionScreen.summaryScreen:
            setHasClouds(false);
            setRightBgSrc('summitMountain');
            break;
        }
    }, [type]);

    function isTestTransitionScreen (type: number): boolean {
        const {
            diagnosticsStartScreen,
            diagnosticsSummaryScreen,
            unitTestIntroScreen,
            unitTestSummaryScreen
        } = TransitionScreen;

        return [
            diagnosticsStartScreen,
            diagnosticsSummaryScreen,
            unitTestIntroScreen,
            unitTestSummaryScreen
        ].includes(type) || quesetionSetType === QuestionSetType.diagnostics || quesetionSetType === QuestionSetType.unitTest;
    }

    if (isTestTransitionScreen(type)) {
        transitionBgProps.bgSrc = (
            type === TransitionScreen.unitTestIntroScreen ||
            type === TransitionScreen.unitTestSummaryScreen ||
            quesetionSetType === QuestionSetType.unitTest
        ) ? unitTestBackgroundImg : diagnosticBackgroundImg;
    }

    return (
        <BgWrap>
            <TransitionBgStyled {...transitionBgProps}>
                {!isTestTransitionScreen(type) && <>
                    <SeaGradient type={type} />
                    <SkyGradient type={type} />
                </>}
                {hasClouds &&
                <Clouds type={type} data-testid={'clouds'}>
                    <AppImage imageType={'img'} iconType={'cloudBumpy'} className={'bumpy1'} />
                    <AppImage imageType={'img'} iconType={'cloudBumpy'} className={'bumpy2'} />
                    <AppImage imageType={'img'} iconType={'cloudBumpy'} className={'bumpy3'} />
                    <AppImage imageType={'img'} iconType={'cloudNarrow'} className={'narrow1'} />
                    <AppImage imageType={'img'} iconType={'cloudNarrow'} className={'narrow2'} />
                    <AppImage imageType={'img'} iconType={'cloudNarrow'} className={'narrow3'} />
                </Clouds>
                }
                {rightMountainSrc &&
                    <div className={'right-mountain'} data-testid={'right-mountain'}>
                        <AppImage imageType={'img'} iconType={rightMountainSrc} />
                    </div>
                }
                {leftBgSrc &&
                    <div className={'left-bottom-bg'} data-testid={'left-bottom-bg'}>
                        <AppImage imageType={'img'} iconType={leftBgSrc} />
                    </div>
                }
                {rightBgSrc &&
                    <div className={'right-bottom-bg'} data-testid={'right-bottom-bg'}>
                        <AppImage imageType={'img'} iconType={rightBgSrc} />
                    </div>
                }
            </TransitionBgStyled>
            <Dots type={isTestTransitionScreen(type) ? 0 : type}>
                <div className={'top-yellow'} />
                <div className={'small-green-1'} />
                <div className={'small-green-2'} />
                <div className={'middle-green'} />
                <div className={'middle-yellow'} />
                <div className={'big-green'} />
                <div className={'small-blue'} />
                <div className={'bottom-blue'} />
                <div className={'bottom-yellow'} />

            </Dots>
        </BgWrap>
    );
};
