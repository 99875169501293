import {
    useMediaQuery,
    useTheme
} from '@chakra-ui/react';

import { useWithMobile } from '../../../../hooks/use-with-mobile';

export const useFooterResponsiveStyles = () => {
    const {
        landscape,
        isMobile,
        isTablet
    } = useWithMobile();
    const theme = useTheme();
    const [breakMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const footerResponsiveStyles = () => {
        let styles = null;

        if ((isMobile || (landscape && isMobile)) && !isTablet) {
            styles = {
                bottom: 0,
                width: `calc(100% - ${landscape && !breakMobile ? '48px' : '32px'})`,
                backgroundColor: 'white',
                zIndex: 100
            };
        }

        return styles;
    };

    return { footerResponsiveStyles };
};
