import { Progress } from '../../../../../containers/question-container/sub-components/question-activity/question-activity.types';
import { TotalStats } from '../summary.types';

export const ratings = [
    {
        from: 0,
        to: 7,
        numRatingStars: 0.5,
        numRatingStarAnims: 1
    },
    {
        from: 8,
        to: 12,
        numRatingStars: 1,
        numRatingStarAnims: 1
    },
    {
        from: 13,
        to: 17,
        numRatingStars: 1.5,
        numRatingStarAnims: 2
    },
    {
        from: 18,
        to: 22,
        numRatingStars: 2,
        numRatingStarAnims: 2
    },
    {
        from: 23,
        to: 27,
        numRatingStars: 2.5,
        numRatingStarAnims: 3
    },
    {
        from: 28,
        to: 30,
        numRatingStars: 3,
        numRatingStarAnims: 3
    }
];

type GetStarTypeProps = {
    totalStars: number
    index: number
};

export const getStarRatingNum = (totalStars: number) => {
    const rating = ratings.find(({
        from, to
    }) => totalStars >= from && totalStars <= to);
    const numRatingStars = rating?.numRatingStars;
    const numRatingStarAnims = rating?.numRatingStarAnims;

    return {
        numRatingStars,
        numRatingStarAnims
    };
};

export const getStarType = ({
    totalStars, index
}: GetStarTypeProps) => {
    const { numRatingStars } = getStarRatingNum(totalStars);

    if (!numRatingStars) {
        return undefined;
    }
    switch (index) {
    case 0: {
        if (numRatingStars === 0.5) {
            return 'Half';
        }

        return 'Full';
    }
    case 1: {
        if (numRatingStars === 1.5) {
            return 'Half';
        } else if (numRatingStars > 1.5) {
            return 'Full';
        }

        return undefined;
    }
    case 2: {
        if (numRatingStars === 2.5) {
            return 'Half';
        } else if (numRatingStars > 2.5) {
            return 'Full';
        }

        return undefined;
    }
    }
};

type GetFinalTotals = {
    progress: Progress | undefined
};

export const getFinalTotals = ({ progress }: GetFinalTotals) => {
    const highestCorrectByStrength = [
        [2, 3, 5],
        [1, 2, 3],
        [0, 1, 2]
    ];
    const initTotal: TotalStats = {
        correct: 0,
        stars: 0
    };
    /*
    * Build an array of milestone data as an array of type TotalStats
    */
    const sectionTotals = progress?.points?.map(({
        pointsPerQuestion, stars: s
    }, section) => {
        const sectionTotal = s.reduce((total, startValue) => ({
            correct: total.correct + +Boolean(startValue),
            stars: total.stars + startValue,
            points: [
                ...((total.points) || []),
                {
                    pointsEarned: startValue !== 0 ? pointsPerQuestion : 0,
                    isCorrect: startValue !== 0
                }]
        }), initTotal);

        return ({
            ...sectionTotal,
            questions: s.length,
            strength: highestCorrectByStrength[section].findIndex((c) => sectionTotal.correct <= c)
        });
    }) || [];
    /*
     * Build a single TotalStats object, finalTotal, by reducing the above array of type TotalStats
     */
    const finalTotal = sectionTotals.reduce((total, sectionTotal) => ({
        correct: total.correct + sectionTotal.correct,
        stars: total.stars + sectionTotal.stars,
        questions: (total.questions || 0) + (sectionTotal?.questions || 0)
    }), initTotal);

    return {
        finalTotal,
        sectionTotals
    };
};
