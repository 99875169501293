import styled from '@emotion/styled';

export const Bkgd = styled.div`
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;    
    .mountain {
        position: relative;
        z-index: 100;
    }
    .ocean {
        position: absolute;
        z-index: 10;
        bottom: 0;
    }
`;
