export const MAX_ATTEMPTS = 3;

export const SCORE_GROUPS = {
    four: 4,
    six: 6,
    eight: 8,
    nine: 9,
    ten: 10
};

/**
 * Student cannot earn points after this numer of attempts.
 *
 * For example, if MAX_QUESTION_SET_ATTEMPT is 5, student can only earn points in the first 5 attempts.
 * After 5 attempts, they can still work on the question set but no longer earn any point.
 *
 * @constant
 * @type {number}
 */
export const MAX_QUESTION_SET_ATTEMPT = 5;

export const featureFlags = [
];
