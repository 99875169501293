import {
    Circle, Image
} from '@chakra-ui/react';
import React, {} from 'react';

import star from '../../../../../../../assets/images/Star.svg';
import { getStarType } from '../../utils/util';
import { Props } from './star-rating.types';

export const StarRating = ({ rating }: Props) => {
    const {
        from, numRatingStarAnims
    } = rating;
    const result = [];

    for (let i = 0; i <= numRatingStarAnims; ++i) {
        const starType = getStarType({
            totalStars: from,
            index: i
        });

        if (starType) {
            result.push(
                <Circle
                    key={i}
                    size={'24px'}
                    boxSizing={'border-box'}
                    pos={'relative'}
                    data-testid={'rating-star'}
                >
                    {(starType === 'Full') &&
                    <Image
                        width={'80%'}
                        height={'80%'}
                        src={star}
                        zIndex={1}
                        data-testid={`whole-star-${i}`}
                    />
                    }
                    {(starType === 'Half') &&
                    <Image
                        width={'40%'}
                        height={'80%'}
                        src={star}
                        align={'left'}
                        fit={'cover'}
                        mr={'40%'}
                        zIndex={1}
                        data-testid={`half-star-${i}`}
                    />
                    }
                </Circle>
            );
        }
    }

    return (
        <>
            {result}
        </>
    );
};
