import { QtcRenderer } from '@3plearning/question-components-library';
import { ErrorBoundary } from '@sentry/react';
import React from 'react';

import CorruptedData from '../corrupted-data/corrupted-data';
import { HintContentProps } from './hint-content.types';

export const HintContent: React.FC<HintContentProps> = ({
    content,
    readAloudConfig
}) => (
    <ErrorBoundary fallback={<CorruptedData />}>
        <QtcRenderer
            question={content}
            callback={() => void 0}
            showCorrectAnswer={true}
            readAloudConfig={{
                ...readAloudConfig,
                readAll: true
            }}
        />
    </ErrorBoundary>
);
