import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { TransitionScreen } from '../../../../helpers/enums';
import {
    pickThemeFlex, pickThemeVar
} from '../../../../styles/theme/app-theme';
import { MessageBoxProps } from '../message-box/message-box';

const checkScreenStyles = (props:MessageBoxProps & { shouldShowStripedBorder: boolean }) => {
    const {
        startScreen, summaryScreen, diagnosticsSummaryScreen
    } = TransitionScreen;

    switch (props.type) {
    case startScreen:
        return css `
          border-radius: 0 35px 30px 30px;
          position: relative;
        `;
    case summaryScreen:
        return css `
          border-radius: 0 20px 20px;
          border-width: 4px;
          border-style: solid;       
          background: linear-gradient(145deg, #FBE294 0%, #FBE294 25%, #58D25B 25%, #58D25B 50%, #4DC4EF 50%, #4DC4EF 100%) border-box;
        `;
    case diagnosticsSummaryScreen:
        return css `
        border-radius: 0 20px 20px;
        border-width: 0;
        border-style: solid;       
        ${props.shouldShowStripedBorder &&
          `border-width: 4px;
          background: linear-gradient(145deg, #FBE294 0%, #FBE294 25%, #58D25B 25%, #58D25B 50%, #4DC4EF 50%, #4DC4EF 100%) border-box;`}
        `;
    case TransitionScreen.finishScreen:
    case TransitionScreen.feedbackScreen:
    case TransitionScreen.diagnosticsStartScreen:
        return css `
            border-radius: 0 20px 20px;
            position: relative;
        `;
    default:
        return css `border-radius: 20px;`;
    }
};

export const MilestoneMessageStyled = styled.div<MessageBoxProps & { shouldShowStripedBorder: boolean; maximumAttemptExceeded: boolean } >`
  ${checkScreenStyles};
  width: 100%;
  max-height: 100%;
  z-index: 10;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  /* intro and transition screens, summary screen content */
  & > div:nth-last-of-type(2), & > div:only-of-type > div:first-of-type {
    flex-grow: 1;
    overflow-y: auto;
  }

  /* summary screen container */
  & > div:only-of-type {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .header-tab {
    ${pickThemeFlex('flexChildCentered')};

    height: 45px;
    flex-shrink: 0;
    border-radius: 20px 20px 0 0;
    color: ${pickThemeVar('fontPrimary')};
    padding: 0 32px;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(113.799px);

    .uow-topic {
      font-size: 16px;
    }
  }

  .message-content {
    ${pickThemeFlex('flexColumnSpaced')};
    ${({ type }) => type !== TransitionScreen.finishScreen ? 'padding: 32px 40px;' : 'position: relative;'}
    background-color: white;

    .message-body {
      height: 100%;
      ${({ type }) => type === TransitionScreen.finishScreen ? `
        width: 100%;
      ` : ''}

      .header {
        font-size: 30px;
        color: ${({ theme: { colors: { neutral } } }) => neutral['900']};
        line-height: 42px;
        letter-spacing: -0.8px;
        ${({ type }) => type === TransitionScreen.finishScreen ? `
            margin-top: 32px;
            margin-left: 40px;
        ` : ''}
      }

      .next-stage {
        color: ${pickThemeVar('blue02')};
        font-size: 20px;
        margin-top: 24px;
      }

      .milestone-header {
        color: ${pickThemeVar('blue02')};
        font-size: 40px;
        line-height: 52px;
        letter-spacing: -1px;
        margin-top: 8px;
      }

      .milestone-description {
        margin-top: 20px;
        color: ${pickThemeVar('fontGrey01')};
        line-height: 28px;
        font-size: 20px;
      }
    }
    .milestone-summary {
      display: inline-flex;
      box-shadow: 0px 0px 4.63216px 0.772026px rgba(11, 55, 83, 0.08);
      border-radius: 16px;
      margin: 32px 0;
      ${({ maximumAttemptExceeded }) => maximumAttemptExceeded && 'margin-bottom: 16px;'}
      height: 110px;

      .num-stack {
        ${pickThemeFlex('flexChildCentered')};
        margin: 0 30px;
        width: 108px;

        @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
          margin: 0 24px;
          width: 84px;
        }
        
        &_content {
          ${pickThemeFlex('flexColumnCentered')};
          .num-label {
            font-size: 36px;
            color: ${pickThemeVar('blue02')}
          }
          .num-description {
            font-size: 16px;
            margin-top: 10px;
            color: ${pickThemeVar('fontGrey01')};
          }
        }
      }
    }
  }

  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    .message-content {
        ${({ type }) => type !== TransitionScreen.finishScreen ? 'padding: 32px;' : ''}

      .message-body {
        .header {
          font-size: 24px;
          line-height: 33.6px;
          letter-spacing: -0.4px;
          ${({ type }) => type === TransitionScreen.finishScreen ? `
            margin-left: 32px;
          ` : ''}
        }
        .next-stage, .milestone-description {
          font-size: 18px;
        }
        .milestone-header {
          font-size: 36px;
          line-height: 46.8px;
          letter-spacing: -1.15px;
        }
      }
      .milestone-summary {
        height: 102px;
        margin: 24px 0 0;
        ${({ maximumAttemptExceeded }) => maximumAttemptExceeded && 'margin-bottom: 16px;'}

        .num-stack {
          &_content {
            .num-label {
              font-size: 32px;
            }
            .num-description {
            }
          }
        }
      }
    }
  }

  @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
    .header-tab {
      padding: 0 20px;
    }
    .message-content {
      ${({ type }) => type !== TransitionScreen.finishScreen ? 'padding: 32px 20px;' : ''}

      .message-body {
        .header {
            letter-spacing: -0.94px;
            ${({ type }) => type === TransitionScreen.finishScreen ? `
                margin-left: 20px;
            ` : ''}
        }
        .milestone-header {
          font-size: 32px;
          line-height: 41.6px;
        }
      }
    }
  }
`;

export const Footer = styled.div<MessageBoxProps>`
  ${pickThemeFlex('flexRowReverse')};
  height: 72px;
  flex-shrink: 0;
  background-color: white;
  box-shadow: 0px 0px 4.92526px 0.820876px rgba(11, 55, 83, 0.08);
  z-index: 9;

  .btn {
    width: 210px;
    font-size: 20px;
    line-height: 28px;
    padding: 8px 32px;
    align-items: flex-start;
    min-height: 48px;
    margin-right: 24px;
  }

  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    height: 64px;

    .btn {
      width: 160px;
      min-height: 40px;
      margin-right: 24px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
    .btn {
      width: 100%;
      margin: 0 24px;
    }
  }
`;
