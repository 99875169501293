import { Box } from '@chakra-ui/react';
import React from 'react';

import AppImage from '../../../components/app-image/app-image';
import { useModalResponsiveStyles } from './fluency-fail-modal.hooks';
import { Bkgd } from './fluency-fail-modal.styled';

export const FluencyFailModalBkgd = () => {
    const { bkgdResponsiveStyles } = useModalResponsiveStyles();

    return (
        <Box
            as={Bkgd}
            {...bkgdResponsiveStyles()}
        >
            <AppImage imageType={'img'} iconType={'fluencyFailMountain'} className={'mountain'} />
            <AppImage imageType={'img'} iconType={'fluencyFailOcean'} className={'ocean'} />
        </Box>
    );
};
