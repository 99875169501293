import styled from '@emotion/styled';

import {
    pickThemeFlex, pickThemeVar
} from '../../../../styles/theme/app-theme';

export const ProgressActivityStyled = styled.div`
  width: ${pickThemeVar('progressPanelWidth')};
  margin-left: ${pickThemeVar('progressPanelLeftMargin')};
  display: ${(props: { showProgress: boolean }) => props.showProgress ? '' : 'none'};

  .progress-content {
    position: relative;

    .progress-activity-frame {
      width: ${pickThemeVar('progressPanelWidth')};
      height: ${pickThemeVar('progressPanelHeight')};
      background: linear-gradient(124.19deg, rgba(121, 161, 250, 0.0001) 3.22%, rgba(255, 255, 255, 0.0001) 22.08%);
      mix-blend-mode: normal;
      box-shadow: inset 0 1px 64px 1px #FFFFFF;
      border-radius: 40px;
    }

    .progress-path { // contains svg app image of dashed path
      position: absolute;
      top: 46px;
      left: 40px;
      width: 205px;
      height: 484px;
    }


    .summit {
      ${pickThemeFlex('flexChildCentered')};

      position: absolute;
      bottom: 416px;
      left: 14px;
      width: 238px;
      z-index: 0;

      .summit-line {
        width: 100%;
        border-bottom: solid 1px white;
      }

      .summit-label {
        position: absolute;
        bottom: 9px;
        left: 0;
        font-size: 12px;
        color: white;
        text-shadow: 0px 2px 8px rgba(0, 25, 55, 0.72);
      }
    }
  }

  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    margin-left: 24px;
  }

  @media (max-width: ${pickThemeVar('breakPoint')}) {
    position: relative;
    width: 100%;
    transition: all 0.5s;
    margin-left: 0;
    ${pickThemeFlex('flexColumnCentered')};

    .progress-content {
      .progress-activity-frame {
        position: relative;
        width: 335px;
        height: 558px;
      }
    }
  }
`;

