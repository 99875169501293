/* istanbul ignore file */
import React from 'react';

import { TempSpinnerWrapper } from './temp-spinner.styled';
import { TempSpinnerProps } from './temp-spinner.types';

const TempSpinner = ({ isLoading }: TempSpinnerProps) => (
    <>
        {isLoading && (
            <TempSpinnerWrapper data-testid={'spinner'}>
                <div
                    className={'lds-ellipsis'}
                >
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </TempSpinnerWrapper>
        )}
    </>
);

export default TempSpinner;
