import React from 'react';

import TempSpinner from '../../../src/containers/question-container/sub-components/temp-poc/temp-spinner';
import { AppInitialisationStyled } from './app-initialisation.styled';
import { useAppInitialization } from './hooks/use-app-initialization';

export const AppInitialisation = () => {
    const {
        isLoading, isError
    } = useAppInitialization();

    if (isError) {
        console.log('isError');
    }

    if (isLoading) {
        return (
            <AppInitialisationStyled data-testid={'init-app'}>
                <TempSpinner isLoading={isLoading} />
            </AppInitialisationStyled>
        );
    }

    return <div />;
};
