import React from 'react';

import SessionTimeoutModal from '../../components/modals/session-timeout-modal/session-timeout-modal';
import { ServerErrorType } from '../../helpers/enums';
import { useServerErrorAggregator } from '../../hooks/use-server-error-aggregator';

const SessionTimeout = () => {
    const { errorType } = useServerErrorAggregator();

    return (
        <SessionTimeoutModal
            isOpen={errorType === ServerErrorType.timeout}
        />
    );
};

export default SessionTimeout;
