import { ReadAloud } from '@3plearning/question-components-library';
import {
    Box, Button, ButtonGroup, Flex, Text, useDisclosure, useTheme
} from '@chakra-ui/react';
import React, {
    useEffect, useRef
} from 'react';
import {
    useDispatch, useSelector
} from 'react-redux';
import { useParams } from 'react-router-dom';

import { AppState } from '../../app/store/store.types';
import ExitWithoutCompletingModal from '../../components/modals/exit-without-completing/exit-without-completing';
import {
    setReadAloudLocale, setStudentState
} from '../../containers/question-container/store/question-actions';
import { ZoomOut } from '../../containers/zoom-out/zoom-out';
import {
    QuestionSetType, STUDENT_STATES
} from '../../helpers/enums';
import { lokalisation } from '../../helpers/lokalisation';
import { getQueryParam } from '../../helpers/utils';
import { MilestoneTransitionType } from '../../routes/routes.types';
import AppImage from '../app-image/app-image';
import { useAudioEffectsContext } from '../audio/provider/audio-effects-provider';
import {
    LogoStyled, HeaderStyled, ProgressBtnStyled, LogoTitleWrapper, HeaderTextWrapper

} from './header.styled';

interface HeaderProps {
    showReadAloudBtn?: boolean
    showExitBtn?: boolean
    showZoomOutBtn?: boolean
}

export const Header = ({
    showReadAloudBtn = false, showExitBtn = false, showZoomOutBtn = false
}: HeaderProps) => {
    const dispatch = useDispatch();
    const {
        buttonTxt: { exit },
        labelTxt: { readAloud }
    } = lokalisation();
    const theme = useTheme();
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const {
        strandName,
        studentState,
        unitOfWork,
        questionSetType,
        readAloudLocale,
        readAloudEnabled,
        activity,
        isWorkedSolutionActivated,
        updatedProgress
    } = activityState;
    const { type } = useParams<MilestoneTransitionType>();
    const isShowProgressBtnVisible = questionSetType === QuestionSetType.questiconActivity && studentState === 'question' && !type;
    const { ...chakraModalProps } = useDisclosure();
    const showProgress = () => {
        dispatch(setStudentState(STUDENT_STATES.resume));
    };
    const { playClick } = useAudioEffectsContext();
    const defaultReadAloudLocale = getQueryParam('locale') || 'en-AU';
    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTimeout(() => {
            if (headerRef.current) {
                headerRef.current.scrollIntoView(true);
            }
        });
        // scroll to top of page for mobile on isWorkedSolutionActivated change to hide height transition from user
    }, [activity, type, isWorkedSolutionActivated]);

    return (
        <HeaderStyled data-testid={'header'} ref={headerRef}>
            <LogoTitleWrapper>
                <LogoStyled iconType={'mathLogo'} imageType={'svg'} />
            </LogoTitleWrapper>
            <HeaderTextWrapper>
                <div className={'header-title'}>
                    {(questionSetType !== QuestionSetType.questiconActivity) ? <Flex
                        display={{
                            base: 'flex',
                            md: 'block'
                        }}
                        flexWrap={{
                            base: 'nowrap',
                            md: 'wrap'
                        }}
                        alignItems={'center'}
                        bgColor={'rgba(255, 255, 255, 0.5)'}
                        borderRadius={'full'}
                        backdropFilter={'blur(32px)'}
                        p={'10px 24px'}
                        whiteSpace={'nowrap'}
                        fontSize={{
                            base: '14px',
                            md: '16px',
                            breakTabletLandscape: '18px'
                        }}
                        lineHeight={{
                            base: '18.2px',
                            md: '20.8px',
                            breakTabletLandscape: '23.4px'
                        }}
                    >
                        <Text as={'span'} fontWeight={'normal'}>{strandName}</Text>
                        {strandName && unitOfWork && <Text as={'span'} mx={1}>|</Text>}
                        {unitOfWork &&
                            <Text as={'span'} fontWeight={'bold'}>
                                {unitOfWork}
                            </Text>}
                    </Flex> : <Text
                        fontSize={'sm'}
                        color={theme.app.grey04}
                        as={'span'}
                        display={'block'}
                        lineHeight={'normal'}
                    >
                        {strandName}
                        {(strandName) && <>&nbsp;|&nbsp;</>}
                        {unitOfWork && unitOfWork}
                    </Text>
                    }
                </div>
            </HeaderTextWrapper>
            <Box className={'header-btn-wrapper'}>
                <ButtonGroup
                    spacing={'10px'}
                >
                    {showZoomOutBtn && <ZoomOut />}
                    {isShowProgressBtnVisible &&
                        <ProgressBtnStyled
                            variant={'header-btn'}
                            data-testid={'show-progress-button'}
                            onClick={() => {
                                playClick();
                                showProgress();
                            }}
                            leftIcon={<AppImage iconType={'SummaryMountain'} imageType={'svg'} />}
                            tabIndex={-1}
                        />}
                    {showReadAloudBtn &&
                        <ReadAloud
                            onLocaleChange={(readAloudLocale) => {
                                dispatch(setReadAloudLocale(readAloudLocale ?? defaultReadAloudLocale, true));
                            }}
                            onToggleEnableState={(readAloudEnabled) => {
                                dispatch(setReadAloudLocale(readAloudLocale ?? defaultReadAloudLocale, !readAloudEnabled));
                            }}
                            popoverLabel={readAloud}
                            selectedLocale={readAloudLocale}
                            disabled={!readAloudEnabled}
                            data-testid={'header-read-aloud-button'}
                        />
                    }
                    {showExitBtn &&
                        <Button
                            color={theme.app.blue02}
                            variant={'header-btn'}
                            data-testid={'exit-button'}
                            onClick={() => {
                                playClick();
                                chakraModalProps.onOpen();
                            }}
                            tabIndex={-1}
                        >
                            {exit}
                        </Button>
                    }
                </ButtonGroup>
            </Box>
            <ExitWithoutCompletingModal
                questionSetType={questionSetType}
                isQuestCompleted={updatedProgress?.isQuestCompleted}
                {...chakraModalProps}
            />
        </HeaderStyled>
    );
};
