import styled from '@emotion/styled';

export const PointsContainer = styled.div`
    display: flex;
    position: absolute;
    bottom: 0;
    opacity: 0;
    width: 100%;
    justify-content: center;
    animation: slideIn 1s ease-in-out 0.5s forwards;

    @keyframes slideIn {
        from {
            bottom: 0;
            opacity: 0;
        }
        to {
            bottom: 20px;
            opacity: 1;
        }
    }
`;

export const CoinTotalContainer = styled.div`
    .increment {
        box-shadow: 0 0 0 0 rgba(217, 152, 0, 0.7);
        animation: glow 2s cubic-bezier(0.66, 0, 0, 1);
    
        @keyframes glow {
            to {
                box-shadow: 0 0 0 8px rgba(217, 152, 0, 0);
            }
        }
    }
`;
