import { css } from '@emotion/react';
import styled from '@emotion/styled';

import commandIcon from '../../../../assets/images/command.svg';

export const KeycapWrapper = styled.div`
    display: inline-block;
    position: relative;
    color: ${({ theme: { app: { white03 } } }) => white03};
    margin: 0 8px;
`;

export const Ctrl = styled.div`
    position: absolute;
    top: 4px;
    left: 10px;
    font-size: 13px;

    &:before {
        content: 'Ctrl';
    }
`;

export const Command = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    &:before, &:after {
        position: absolute;
    }

    &:before {
        content: url(${commandIcon});
        top: 7px;
        left: 15px;
    }

    &:after {
        content: 'command';
        top: 21px;
        left: 8px;
        font-size: 6px;
        font-weight: 400;
        letter-spacing: -0.036px;
    }
`;

export const HyphenUnderscore = styled.div<{ os: string }>(props => {
    const {
        os, theme: { app: { white03 } }
    } = props;

    return css`
        position: absolute;
        top: 0;
        left: 0;

        &:before, &:after {
            position: absolute;
            content: '';
            border: 1px solid ${white03};
        }
        // underscore
        &:before {
            top: 9px;
            left: ${os === 'mac' ? '15px' : '12px'};
            width: 13px;
        }
        // hyphen
        &:after {
            top: 24px;
            left: ${os === 'mac' ? '18px' : '12px'};
            width: 7px;
        }
    `;
});
