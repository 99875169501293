import { LoaderDots } from '@3plearning/chakra-mathletics-theme';
import {
    useDisclosure,
    Flex,
    VStack,
    Text
} from '@chakra-ui/react';
import React from 'react';

import { lokalisation } from '../../../helpers/lokalisation';
import { ModalLayout } from '../modal-layout';
import { NoInternetImage } from './no-internet-image';
import { ModalErrorProps } from './no-internet-modal.types';

const NoInternetModal = ({ isOpen }: ModalErrorProps) => {
    const { onClose } = useDisclosure();
    const { noInternetTxt } = lokalisation();

    return (
        <>
            <ModalLayout data-testid={'no-internet-modal'} isOpen={isOpen} onClose={onClose}>
                <Flex
                    flexDirection={{
                        base: 'column',
                        break680: 'row'
                    }}
                    align={'start'}
                >
                    <Flex
                        justify={'start'}
                        alignSelf={{
                            base: 'center',
                            break680: 'start'
                        }}
                        h={'100%'}
                        w={'100%'}
                        maxW={{
                            base: '380px',
                            breakTabletPortraitPhone: '420px',
                            break1270: '450px'
                        }}
                    >
                        <NoInternetImage />
                    </Flex>

                    <VStack
                        spacing={0}
                        align={{
                            base: 'center',
                            break680: 'start'
                        }}
                        justifyContent={'center'}
                        w={'100%'}
                        h={'100%'}
                        minHeight={{
                            base: 'auto',
                            break680: '410px'
                        }}
                        pr={{
                            base: '0px',
                            breakTabletPortraitPhone: '30px',
                            lg: '24px',
                            break1270: '40px'
                        }}
                        py={{
                            base: '16px',
                            break680: 0
                        }}
                    >
                        <VStack
                            mx={{
                                base: '30px',
                                break680: 0
                            }}
                            align={'start'}
                            pb={{
                                base: '30px',
                                lg: '48px'
                            }}
                        >
                            <Text
                                fontWeight={'400'}
                                fontSize={{
                                    base: '30px',
                                    breakTabletPortraitPhone: '40px'
                                }}
                                lineHeight={{
                                    base: '140%',
                                    breakTabletPortraitPhone: '130%'
                                }}
                                letterSpacing={{
                                    base: '-0.8px',
                                    breakTabletPortraitPhone: '-1px'
                                }}
                                color={'neutral.900'}
                                textAlign={'start'}
                            >
                                {noInternetTxt.content1}
                                <br />
                                {noInternetTxt.content2}
                            </Text>
                        </VStack>
                        <LoaderDots totalDots={3} />
                    </VStack>
                </Flex>
            </ModalLayout>
        </>
    );
};

export default NoInternetModal;
