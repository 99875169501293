import React, {
    useEffect, useState
} from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../app/store/store.types';
import AppImage from '../../components/app-image/app-image';
import { Header } from '../../components/header/header';
import { QuestionContainer } from '../../containers/question-container/question-container';
import { STUDENT_STATES } from '../../helpers/enums';
import { useRouteChecker } from '../../hooks/use-route-checker';
import {
    ProgressMountainStyled, QuestionConsoleStyled
} from './question-console.styled';

export const QuestionConsole = () => {
    useRouteChecker();
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const {
        studentState, questionSetType, validation, isWorkedSolutionActivated
    } = activityState;
    const [minHeight, setMinHeight] = useState(0);

    useEffect(() => {
        // wait for animation to finish before detecting height
        setTimeout(() => {
            if (isWorkedSolutionActivated) {
                const workedSolution = document.getElementsByClassName('worked-solution-wrapper')[0] as HTMLElement;

                setMinHeight(workedSolution?.offsetHeight + 217);
            } else {
                const questionContent = document.getElementsByClassName('question-content')[0] as HTMLElement;

                setMinHeight(questionContent?.offsetHeight + 280);
            }
        }, 1700);
    }, [isWorkedSolutionActivated, validation]);

    return (
        <QuestionConsoleStyled
            showQuestion={studentState === STUDENT_STATES.question}
            className={`root-container question-set-type-${questionSetType}`}
            minHeight={minHeight}
        >
            <Header showZoomOutBtn showReadAloudBtn showExitBtn />
            <div className={'content'}>
                <AppImage imageType={'img'} iconType={'leftMountain'} className={'left-mountain'} />
                <ProgressMountainStyled>
                    <AppImage imageType={'img'} iconType={'progressMountain'} className={'progress-mountain'} />
                </ProgressMountainStyled>
                <QuestionContainer scrollableContainer={document.getElementsByClassName('application')[0]} />
                <AppImage imageType={'img'} iconType={'rightFgMountain'} className={'right-mountain'} />
            </div>
        </QuestionConsoleStyled>
    );
};
