import { useSelector } from 'react-redux';

import { AppState } from '../app/store/store.types';
import { ServerErrorType } from '../helpers/enums';

export const useServerErrorAggregator = () => {
    let errorType = -1;

    const { serverErrorIntercepted } = useSelector<AppState, AppState['activityState']>(state => state.activityState);

    switch (serverErrorIntercepted?.status) {
    case 401: {
        errorType = ServerErrorType.timeout;
        break;
    }
    default: {
        if (serverErrorIntercepted?.status as number > 399 && serverErrorIntercepted?.status as number < 600) {
            errorType = ServerErrorType.other;
        }
        break;
    }
    }

    return { errorType };
};
