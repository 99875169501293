import {
    Content,
    ContentSegment,
    Segment
} from '@3plearning/question-components-library';
import React from 'react';

export const generateBlock = (blocks: Content = [], assetsBasePath?: string, blockKey?: string) => (
    <div>
        {blocks?.map(({ content }, blockIndex: number) => (
            <div key={`${blockKey}-${blockIndex}`} className={'block'}>
                {content && assetsBasePath && <ContentSegment assetsBasePath={assetsBasePath} content={content as Segment[]} />}
            </div>
        ))}
    </div>
);
