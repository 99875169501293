/* istanbul ignore file */
import {
    useState, useEffect
} from 'react';

/*
 https://usehooks.com/useWindowSize/
 */
export function useWindowSize () {
    // Initialize state with 0 width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        viewWidth: 0,
        viewHeight: 0
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize () {
            // Set window width/height to state
            setWindowSize({
                viewWidth: window.innerWidth,
                viewHeight: window.innerHeight
            });
        }

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Empty dependency array ensures that effect is only run on mount

    return windowSize;
}

