import { Button } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { pickThemeVar } from '../../styles/theme/app-theme';
import AppImage from '../app-image/app-image';

export const HeaderStyled = styled.div`
  min-height: ${pickThemeVar('headerHeight')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 96%;
  margin: 0 2%;
  z-index: 100;
  padding-top: 20px;
  padding-bottom: 10px;
  box-sizing: border-box;

  .header-btn-wrapper {
    order: 3;
    padding-left: 2%;
  }

  @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
    min-height: ${pickThemeVar('headerHeightMobile')};
    flex-wrap: wrap;
    justify-content: space-between;

    .header-btn-wrapper {
      order: 2;
    }
  }
`;

export const HeaderTextWrapper = styled.div`
  order: 2;
  min-width: 0;
  padding-top: 0;
  flex-basis: 100%;

  .header-title {
    width: fit-content;
    max-width: 100%;
    font-weight: 700;

    span {
      white-space: break-spaces;
      overflow: hidden;
    }
  }

  @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
    padding-top: 10px;
    order: 3;
    max-widtH: 100%;

    .header-title {
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`;

export const LogoTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  order: 1;

  @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
    order: 1;
    flex-direction: column;
    align-items: flex-start;
    height: unset;
  }
`;

export const LogoStyled = styled(AppImage)`
  width: 135px;
  height: 48px;
  margin-right: 48px;
  flex-shrink: 0;
  
  /**
  LANDSCAPE
   */
  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    width: 112px;
    height: 40px;
    margin-right: 10px;
  }
  /**
  IPHONE
   */
  @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
    width: 92px;
    height: 32px;
    margin-right: 10px;
  }
`;

export const ProgressBtnStyled = styled(Button)`
  .chakra-button__icon {
    margin: unset;
  }

  svg {
    path {
      &[fill="#4DC4EF"] {
        fill: ${pickThemeVar('blue02')};
      }
      &[fill="white"] {
        fill: transparent;
      }
    }
  }

  @media (min-width: ${pickThemeVar('breakPoint')}) {
    display: none;
  }
`;
