import { useToken } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { TransitionScreen } from '../../../../helpers/enums';
import {
    pickThemeFlex, pickThemeVar
} from '../../../../styles/theme/app-theme';
import { TransitionBgProps } from './transition-bg';

const styleLeftBottom = (props: TransitionBgProps) => {
    switch (props.type) {
    case TransitionScreen.startScreen:
        return css`
              bottom: 150px;
              width: 440px;
              height: 406px;
            `;
    case TransitionScreen.startFluency:
        return css`
              img {
                width: 367px;
                height: 772px;
              }
            `;
    case TransitionScreen.startReasoning:
        return css`
              bottom: -166px;

              img {
                width: 952px;
                height: 852px;
              }
            `;
    }
};
const tabletLandscapeStyleLeftBottom = (props: TransitionBgProps) => {
    switch (props.type) {
    case TransitionScreen.startScreen:
        return css`
              left: -60px;
              bottom: 50px;
              width: 272px;
              height: 250px;
            `;
    case TransitionScreen.startFluency:
        return css`
              img {
                width: 319px;
                height: 483px;
              }
            `;
    case TransitionScreen.startReasoning:
        return css`
              z-index: 0;
              bottom: 0;

              img {
                width: 658px;
                height: 402px;
              }
            `;
    }
};
const styleRightBottom = (props: TransitionBgProps) => {
    switch (props.type) {
    case TransitionScreen.startFluency:
        return css`
              img {
                width: 1399px;
                height: 673px;
              }
            `;
    case TransitionScreen.summaryScreen:
        return css`
              img {
                height: 95vh;
                object-fit: cover;
                object-position: right;
              }
            `;
    }
};
const responsiveStyleRightBottom = (props: TransitionBgProps) => {
    switch (props.type) {
    case TransitionScreen.startFluency:
        return css`
              img {
                width: 871px;
                height: 419px;
              }
            `;
    }
};
const styleRightMountain = (props: TransitionBgProps) => {
    switch (props.type) {
    case TransitionScreen.startScreen:
        return css`

              width: 1173px;
              height: 964px;
              bottom: 43px;
              right: -303px;

              img {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 1173px;
                height: 964px;
              }
            `;
    case TransitionScreen.startFluency:
        return css`
              bottom: -148px;
              left: calc(100vw * 0.51);
              width: 2276px;
              height: 2393px;

              img {
                position: relative;
                left: -726px;
                width: 2276px;
                height: 2393px;
              }
            `;
    }
};
const tabletLandscapeStyleRightMountain = (props: TransitionBgProps) => {
    switch (props.type) {
    case TransitionScreen.startScreen:
        return css`
              bottom: 43px;
              right: -406px;

              img {
                width: 960px;
                height: 789px;
              }
            `;
    case TransitionScreen.startFluency:
        return css`
              bottom: -92px;
              left: calc(100vw * 0.57);
              width: 1428px;
              height: 1501px;

              img {
                position: relative;
                left: -495px;
                width: 1428px;
                height: 1501px;
              }
            `;
    }
};
const tablePortraitPhoneStyleRightMountain = (props: TransitionBgProps) => {
    switch (props.type) {
    case TransitionScreen.startFluency:
        return css`
              bottom: -92px;
              left: calc(100vw * 0.47);
              width: 1428px;
              height: 1501px;

              img {
                position: relative;
                left: -479px;
                width: 1428px;
                height: 1501px;
              }
            `;
    }
};

export const BgWrap = styled.span`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const TransitionBgStyled = styled.div<TransitionBgProps>`
  width: 100%;
  height: 100%;

  ${props => (
        props.type === TransitionScreen.feedbackScreen ||
        props.type === TransitionScreen.finishScreen ||
        props.type === TransitionScreen.exceededAttemptsFinishScreen) ? 'filter: grayscale(20%) blur(50px);' : ''};

  ${pickThemeFlex('flexChildCentered')};

  ${props => props.bgSrc && css`
    background-image: url(${props.bgSrc});
    background-repeat: no-repeat;
    background-size: cover;

    ${props.type === TransitionScreen.unitTestIntroScreen ? `
        @media (max-width: ${props.theme.breakpoints.lg}) {
            background-position: center;
        }
    ` : ''};
  `}

  .left-bottom-bg {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;

    ${styleLeftBottom};

    @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
      ${tabletLandscapeStyleLeftBottom};
    }
  }

  .right-mountain {
    position: absolute;
    bottom: 0;
    right: 0;

    ${styleRightMountain};

    @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
      ${tabletLandscapeStyleRightMountain};
    }

    @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
      ${tablePortraitPhoneStyleRightMountain};
    }
  }

  .right-bottom-bg {
    position: absolute;
    right: 0;
    bottom: 0;

    ${props => (
        props.type === TransitionScreen.feedbackScreen ||
        props.type === TransitionScreen.finishScreen ||
        props.type === TransitionScreen.exceededAttemptsFinishScreen) ? 'transform: translate(50px, 50px);' : ''};

    ${styleRightBottom};

    @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
      ${responsiveStyleRightBottom};
    }
  }
`;

const seaGradientHeight = (props: TransitionBgProps) => {
    switch (props.type) {
    case TransitionScreen.startScreen:
    case TransitionScreen.startFluency:
        return css`
              height: 319px;
              background: linear-gradient(180deg, #87F8EF 0.37%, #35F1DE 20.75%, #00E7D4 40.24%, #00CBDA 63.31%, #0297BE 100%);
            `;
    case TransitionScreen.startProblemSolving:
        return css`
              height: 229px;
              background: linear-gradient(180deg, #87F8EF 0%, #1EEDDA 24.95%, #01CDDA 51.34%, #01ADE1 73.96%, #018AE9 100%);
            `;
    case TransitionScreen.finishScreen:
    case TransitionScreen.exceededAttemptsFinishScreen:
    case TransitionScreen.summaryScreen:
        return css`
              height: 92px;
              background: linear-gradient(180deg, #87F8EF 0%, #1EEDDA 48.91%, #01CDDA 100%);
            `;
    default:
        return css`
              height: 417px;
              background: linear-gradient(180deg, #87F8EF 0%, #1EEDDA 24.95%, #01CDDA 51.34%, #01ADE1 73.96%, #018AE9 100%);
            `;
    }
};
const responsiveSeaGradientHeight = (props: TransitionBgProps) => {
    switch (props.type) {
    case TransitionScreen.startScreen:
    case TransitionScreen.startFluency:
        return css`
              height: 161px;
            `;
    case TransitionScreen.startProblemSolving:
        return css`
              height: 229px;
            `;
    case TransitionScreen.finishScreen:
    case TransitionScreen.exceededAttemptsFinishScreen:
    case TransitionScreen.summaryScreen:
        return css`
              height: 64px;
            `;
    default:
        return css`
              height: 417px;
            `;
    }
};

export const SeaGradient = styled.div<TransitionBgProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  ${seaGradientHeight};

  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    ${responsiveSeaGradientHeight};
  }
`;

const skyGradientHeight = (props: TransitionBgProps) => {
    switch (props.type) {
    case TransitionScreen.startScreen:
    case TransitionScreen.startFluency:
        return css`
              height: calc(100% - 319px);
              background: linear-gradient(180deg, #0BB3F4 0%, #4CC9F9 53.12%, #B4ECFF 91.75%, #D3F4FF 100%);
            `;
    case TransitionScreen.startProblemSolving:
        return css`
              height: calc(100% - 229px);
              background: linear-gradient(180deg, #0BB3F4 0%, #13B7F6 18.17%, #4CC9F9 53.41%, #B4ECFF 77.67%, #D3F4FF 100%);
            `;
    case TransitionScreen.finishScreen:
    case TransitionScreen.exceededAttemptsFinishScreen:
    case TransitionScreen.summaryScreen:
        return css`
              height: calc(100% - 92px);
              background: linear-gradient(180deg, #0BB3F4 0%, #13B7F6 22.66%, #4CC9F9 42.54%, #B4ECFF 80.12%, #EFFBFF 100%);
            `;
    default:
        return css`
              height: calc(100% - 417px);
              background: linear-gradient(180deg, #0BB3F4 0%, #13B7F6 18.17%, #4CC9F9 53.41%, #B4ECFF 77.67%, #D3F4FF 100%);
            `;
    }
};
const responsiveSkyGradientHeight = (props: TransitionBgProps) => {
    switch (props.type) {
    case TransitionScreen.startScreen:
    case TransitionScreen.startFluency:
        return css`
              height: calc(100% - 161px);
            `;

    case TransitionScreen.startProblemSolving:
        return css`
              height: calc(100% - 229px);
            `;
    case TransitionScreen.finishScreen:
    case TransitionScreen.exceededAttemptsFinishScreen:
    case TransitionScreen.summaryScreen:
        return css`
              height: calc(100% - 64px);
            `;
    default:
        return css`
              height: calc(100% - 417px);
            `;
    }
};

export const SkyGradient = styled.div<TransitionBgProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  ${skyGradientHeight};

  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    ${responsiveSkyGradientHeight};
  }
`;

export const Clouds = styled.div<TransitionBgProps>`
  position: absolute;
  left: calc(50% - 900px);
  bottom: 319px;
  width: 1800px;
  height: 1200px;

  display: ${props => props.type === TransitionScreen.summaryScreen ? 'none' : 'block'};

  .bumpy1 {
    position: absolute;
    bottom: 160px;
    left: 200px;
    width: 472px;
    height: 42px;
  }

  .bumpy2 {
    position: absolute;
    bottom: 400px;
    left: 45px;
    width: 329px;
    height: 30px;
  }

  .bumpy3 {
    position: absolute;
    bottom: 400px;
    left: 1280px;
    width: 329px;
    height: 30px;
  }

  .narrow1 {
    position: absolute;
    bottom: 576px;
    left: 620px;
    width: 467px;
    height: 38px;
  }

  .narrow2 {
    position: absolute;
    bottom: 266px;
    left: 200px;
    width: 467px;
    height: 38px;
  }

  .narrow3 {
    position: absolute;
    bottom: 246px;
    left: 1600px;
    width: 467px;
    height: 38px;
  }
`;

export const Dots = styled.div<TransitionBgProps>(props => {
    const [primary400, yellow300, green400]  = useToken('colors', ['primary.400', 'yellow.300', 'green.400']);

    switch (props.type) {
    case TransitionScreen.finishScreen:
    case TransitionScreen.exceededAttemptsFinishScreen:
    case TransitionScreen.feedbackScreen:
    case TransitionScreen.summaryScreen:
        return css `
          .top-yellow {
            position: absolute;
            top: -80px;
            left: 280px;
            width: 290px;
            height: 290px;
            border-radius: 50%;
            background-color: ${yellow300};
            mix-blend-mode: normal;
            opacity: 0.7;
          }

          .middle-green {
            position: absolute;
            top: 410px;
            left: 340px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: ${green400};
            mix-blend-mode: normal;
            opacity: 0.7;
          }

          .small-green-1 {
            position: absolute;
            bottom: 290px;
            left: 340px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: ${green400};
            mix-blend-mode: normal;
            opacity: 0.7;
          }

          .small-green-2 {
            position: absolute;
            top: 360px;
            right: 290px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: ${green400};
            mix-blend-mode: normal;
            opacity: 0.7;
          }

          .small-blue {
            position: absolute;
            top: 370px;
            left: 410px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #47C9FA;
            mix-blend-mode: normal;
            opacity: 0.7;
          }

          .bottom-blue {
            position: absolute;
            bottom: -400px;
            left: -280px;
            width: 600px;
            height: 600px;
            border-radius: 50%;
            background-color: ${primary400};
            mix-blend-mode: normal;
          }

          .middle-yellow {
            position: absolute;
            bottom: 300px;
            right: 310px;
            width: 135px;
            height: 135px;
            border-radius: 50%;
            background-color: ${yellow300};
            mix-blend-mode: normal;
            opacity: 0.8;
          }

          .big-green {
            position: absolute;
            bottom: 100px;
            right: 240px;
            width: 290px;
            height: 290px;
            border-radius: 50%;
            background-color: ${green400};
            mix-blend-mode: normal;
            opacity: 0.7;
          }

          .bottom-yellow {
            position: absolute;
            bottom: -260px;
            right: -300px;
            width: 465px;
            height: 465px;
            border-radius: 50%;
            background-color: ${yellow300};
            mix-blend-mode: normal;
            opacity: 0.8;
          }
      
          @media (max-width: ${pickThemeVar('breakTabletLandscape')(props)}) {
            .top-yellow {
              position: absolute;
              top: -67px;
              left: 224px;
              width: 232px;
              height: 232px;
              border-radius: 50%;
            }
            .bottom-blue {
              bottom: -290px;
              left: -250px;
              width: 477px;
              height: 477px;
            }
          }

          ${props.type === TransitionScreen.summaryScreen ?
        '.small-green-1,.small-green-2, .bottom-yellow, .big-green, .small-blue, .middle-yellow {display: none;}' :
        '.middle-green {display: none;}'};

        @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')(props)}) {
          .middle-green,
          .small-green-1,
          .small-green-2,
          .bottom-yellow,
          .big-green,
          .small-blue,
          .middle-yellow {
            display: none;
          }

          ${(props.type === TransitionScreen.finishScreen ||
            props.type === TransitionScreen.exceededAttemptsFinishScreen ||
            props.type === TransitionScreen.feedbackScreen) ? '.top-yellow { left: -40px;}' : '.top-yellow, .bottom-blue {display: none;}'};
        }
        `;
    default:
        return css`
          display: none;
        `;
    }
});
