import {
    Box, Flex, HStack, Image, Text, useTheme, VStack
} from '@chakra-ui/react';
import React, {
    ReactElement, RefObject
} from 'react';

import SilverBar from '../../../../../../../assets/images/silver-bar.png';
import { lokalisation } from '../../../../../../helpers/lokalisation';
import {
    ProgressHeading, ProgressImageStyled, ProgressInnerBarStyled, ProgressTextStyled
} from './progress-bar.styled';

type ProgressBarProps = {
    score: string
    totalCorrect: number
    totalQuestions: number
    showGold: boolean
    barRef: RefObject<HTMLDivElement>
    barGoldRef: RefObject<HTMLDivElement>
    goldBarStars: ReactElement<HTMLDivElement>
    goldBrick: ReactElement<HTMLDivElement>
    totalCorrectRef: RefObject<HTMLDivElement>
    scoreNum: number
    isSummaryPage: boolean
};

export function ProgressBar ({
    score,
    totalCorrect,
    totalQuestions,
    showGold,
    barRef,
    barGoldRef,
    goldBarStars,
    goldBrick,
    totalCorrectRef,
    scoreNum,
    isSummaryPage
}: ProgressBarProps) {
    const theme = useTheme();
    const { neutral900 } = theme?.app;
    const { unitPracticeTest } = lokalisation();

    return (
        <Box
            data-testid={'score-box'}
            w={'auto'}
            boxSizing={'border-box'}
            flex={1}
            mr={isSummaryPage ? {
                base: 0,
                md: '20px',
                lg: '24px',
                breakTabletLandscape: '32px'
            } : 0}
        >
            {isSummaryPage && <ProgressHeading>
                <HStack data-testid={'score'}>
                    <div className={'label'} >
                        { score }
                    </div>
                    <div className={'info'}>{`${totalCorrect}/${totalQuestions}`}</div>
                </HStack>
            </ProgressHeading>}
            <VStack
                data-testid={'progress-bar-h-stack'}
                boxShadow={'0px 0px 4.63216px 0.772026px rgba(11, 55, 83, 0.08);'}
                borderRadius={'16px'}
                boxSizing={'border-box'}
                p={{
                    base: isSummaryPage ?  '10px' : '20px',
                    md: isSummaryPage ? '10px' : '24px'
                }}
                {...(!isSummaryPage && {
                    py: {
                        base: '20px',
                        md: '20px',
                        break1270: '16px'
                    }
                })}
                w={'100%'}
                h={{
                    base: isSummaryPage ? '80px' : 'auto',
                    break1270: isSummaryPage ? '110px' :'100%'
                }}
                alignItems={'center'}
                justifyContent={{
                    base: 'center',
                    ...(!isSummaryPage && { break680: 'unset' })
                }}
            >
                {!isSummaryPage &&
                    <Flex
                        alignSelf={'start'}
                        mb={{
                            base: '10px',
                            phoneLandscape: '17px'
                        }}
                    >
                        <Text
                            fontSize={'14px'}
                            textTransform={'uppercase'}
                            color={neutral900}
                            fontWeight={500}
                            lineHeight={'normal'}
                            letterSpacing={'1px'}
                        >
                            {unitPracticeTest.accuracy}
                        </Text>
                    </Flex>
                }
                <HStack w={'100%'} spacing={0}>
                    <Box
                        w={'100%'}
                        h={'20px'}
                        borderRadius={'32px'}
                        border={'1px solid #D1E8EF'}
                        ml={{
                            base: isSummaryPage ? '0' : '20px',
                            messageBoxWidth: 0
                        }}
                    >
                        <ProgressInnerBarStyled
                            data-testid={'progress-bar'}
                            totalCorrect={totalCorrect}
                            totalQuestions={totalQuestions}
                            data-show-gold={showGold}
                        >
                            <div className={'inner-bar'} ref={barRef} />
                            <div className={'inner-bar-gold'} ref={barGoldRef} />
                            {showGold &&
                            <div className={'gold-bar-stars'}>{goldBarStars}</div>
                            }
                        </ProgressInnerBarStyled>
                    </Box>
                    <ProgressImageStyled
                        isSummaryPage={isSummaryPage}
                        data-testid={'progress-image'}
                        data-show-gold={showGold}
                    >
                        {showGold &&
                        <div
                            className={'gold-bar'}
                        >{goldBrick}</div>
                        }
                        {!showGold &&
                        <div
                            className={'grey-bar'}
                        >
                            <Image
                                src={SilverBar}
                                width={'41px'}
                            />
                        </div>
                        }
                    </ProgressImageStyled>
                    <ProgressTextStyled isSummaryPage={isSummaryPage}>
                        <div
                            ref={totalCorrectRef}
                            className={'progress-text'}
                        >
                            {scoreNum}
                        </div>
                        <div
                            className={'progress-text-percent'}
                            data-testid={'progress-text'}
                        >
                            %
                        </div>
                    </ProgressTextStyled>
                </HStack>
            </VStack>
        </Box>
    );
}
