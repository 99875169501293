import { combineReducers } from 'redux';

import { activityReducer } from '../../containers/question-container/store/question-reducer';
import {
    AppActions, AppState
} from './store.types';

/**
 * The rootReducer is the top level reducer of the application, the name is a convention
 * used throughout the Redux docs.
 *
 * All other reducers need to be composed into this as its the one that's used in createStore api.
 * eg through some method like "combineReducers" or "reduce-reducers".
 *
 * @see https://redux.js.org/usage/structuring-reducers/structuring-reducers
 * @see https://redux.js.org/usage/structuring-reducers/using-combinereducers
 * @see https://redux.js.org/usage/structuring-reducers/beyond-combinereducers#sharing-data-between-slice-reducers
 */
export const rootReducer = combineReducers<AppState, AppActions>({ activityState: activityReducer });
