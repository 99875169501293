import {
    Flex, HStack, SimpleGrid, Text, Tooltip, useOutsideClick, useTheme, Image
} from '@chakra-ui/react';
import React, {
    useRef, useState
} from 'react';

import closeIcon from '../../../../../../../assets/images/close-icon.svg';
import AppImage from '../../../../../../components/app-image/app-image';
import { useAudioEffectsContext } from '../../../../../../components/audio/provider/audio-effects-provider';
import { lokalisation } from '../../../../../../helpers/lokalisation';
import { InfoWrapper } from '../../summary.styled';
import { ratings } from '../../utils/util';
import { StarRating } from './star-rating';

export const StarTooltip = () => {
    const theme = useTheme();
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const { playClick } = useAudioEffectsContext();
    const handleTooltip = () => {
        playClick();
        setIsTooltipOpen(!isTooltipOpen);
    };
    const tooltipRef = useRef(null);

    useOutsideClick({
        ref: tooltipRef,
        handler: () => {
            setIsTooltipOpen(false);
        }
    });
    const { summaryTxt: { starRatings } } = lokalisation();
    const StarRatings = (
        <Flex data-testid={'tooltip'} align={'flex-start'}>
            <Flex direction={'column'} p={4} w={'min'}>
                <Text mb={2} mt={-1} color={theme.app.blue03} lineHeight={5} data-testid={'tooltip-text'}>
                    {starRatings}
                </Text>
                <SimpleGrid
                    templateColumns={'repeat(2, max-content)'}
                    templateRows={'repeat(3, max-content)'}
                    autoFlow={'column'}
                    spacingX={9}
                    spacingY={1}
                >
                    {ratings.map((rating, index) => {
                        return (
                            <HStack key={index} justify={'space-between'} data-testid={`tooltip-cell-${index}`}>
                                <HStack spacing={1} h={'24px'} data-testid={'stars'}>
                                    <StarRating rating={rating} />
                                </HStack>
                                <Text key={index}>{rating.from}-{rating.to}</Text>
                            </HStack>
                        );
                    }
                    )}
                </SimpleGrid>
            </Flex>
            <Image src={closeIcon} _hover={{ cursor: 'pointer' }} pointerEvents={'auto'} pt={7} pr={5} onClick={playClick} />
        </Flex>
    );

    return (
        <Tooltip
            hasArrow
            isOpen={isTooltipOpen}
            label={StarRatings}
            placement={'top-end'}
            arrowSize={15}
            arrowPadding={15}
            offset={[15, 15]}
            arrowShadowColor={`${theme.app.grey06}33`}
        >
            <InfoWrapper ref={tooltipRef} data-testid={'tooltip-button'} onClick={handleTooltip}>
                <AppImage
                    imageType={'svg'}
                    iconType={'infoIcon'}
                    className={'info-icon'}
                />
            </InfoWrapper>
        </Tooltip>

    );
};
