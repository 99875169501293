import {
    Content,
    QtcRenderer
} from '@3plearning/question-components-library';
import { Global } from '@emotion/react';
import React, { useEffect } from 'react';

import { getURLLocale } from '../../../src/i18n/utils/locale-utils';
import { getQueryParam } from '../../helpers/utils';
import {
    useNav,
    useQuestionSet
} from './preview.hooks';
import {
    PreviewCtnr,
    QuestionCtnr,
    StageCtnr
} from './preview.styled';
import { QuestionHeader } from './question-header';

export const Preview = () => {
    const questionSetId = getQueryParam('questionSetId') || '';
    const {
        questionSet,
        getQuestionSet
    } = useQuestionSet();
    const { questions } = questionSet;
    const {
        questionNumber,
        navigate
    } = useNav(questions.length);

    useEffect(() => {
        getQuestionSet(questionSetId, getURLLocale());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Global
                styles={{ body: { backgroundColor: 'transparent !important' } }}
            />
            <PreviewCtnr>
                <StageCtnr>
                    <QuestionHeader
                        navigate={navigate}
                        totalQuestions={questions.length}
                        questionNumber={questionNumber}
                    />
                    <QuestionCtnr>
                        <QtcRenderer
                            key={questionNumber}
                            callback={() => null}
                            question={questions[questionNumber] as Content}
                            questionNumber={questionNumber + 1}
                        />
                    </QuestionCtnr>
                </StageCtnr>
            </PreviewCtnr>
        </>
    );
};
