import {
    Button,
    Flex,
    VStack,
    Text,
    Image
} from '@chakra-ui/react';
import React from 'react';

import { lokalisation } from '../../../helpers/lokalisation';
import { useExitApp } from '../../../hooks/use-exit-app';
import { ModalLayout } from '../modal-layout';
import InternalErrorImage from './assets/Error.svg';
import { InternalErrorModalProps } from './internal-error.types';

const InternalErrorModal = ({
    isOpen, onClose
}: InternalErrorModalProps) => {
    const { redirect } = useExitApp();
    const onExit = () => {
        redirect();
    };
    const {
        buttonTxt, errorTxt
    } = lokalisation();

    return (
        <ModalLayout data-testid={'internal-error-modal'} onClose={onClose} isOpen={isOpen}>
            <Flex
                flexDirection={{
                    base: 'column',
                    break680: 'row'
                }}
                align={'start'}
            >
                <Flex
                    justify={'start'}
                    alignSelf={{
                        base: 'center',
                        break680: 'start'
                    }}
                    h={'100%'}
                    w={'100%'}
                    maxW={{
                        base: '380px',
                        breakTabletPortraitPhone: '420px',
                        break1270: '450px'
                    }}
                >
                    <Image alt={'Error Image'} w={'100%'} h={'100%'} src={InternalErrorImage} />
                </Flex>

                <VStack
                    spacing={0}
                    align={{
                        base: 'center',
                        break680: 'start'
                    }}
                    justifyContent={'center'}
                    w={'100%'}
                    h={'100%'}
                    minHeight={{
                        base: 'auto',
                        break680: '410px'
                    }}
                    pr={{
                        base: '0px',
                        break680: '30px',
                        lg: '40px'
                    }}
                    py={{
                        base: '16px',
                        break680: 0
                    }}
                >
                    <VStack
                        mx={{
                            base: '30px',
                            break680: 0
                        }}
                        align={'start'}
                        pb={{
                            base: '30px',
                            lg: '48px'
                        }}
                    >
                        <Text
                            fontWeight={'400'}
                            fontSize={{
                                base: '30px',
                                break680: '40px'
                            }}
                            lineHeight={{
                                base: '140%',
                                break680: '130%'
                            }}
                            letterSpacing={{
                                base: '-0.8px',
                                break680: '-1px'
                            }}
                            color={'neutral.900'}
                            textAlign={'start'}
                        >
                            {errorTxt.errorMessage}
                        </Text>
                    </VStack>
                    <Button
                        data-testid={'error-modal-close'}
                        w={{
                            base: 'calc(100% - 60px)',
                            break680: 'auto'
                        }}
                        minW={'160px'}
                        colorScheme={'primary'}
                        onClick={onExit}
                    >
                        {buttonTxt.home}
                    </Button>
                </VStack>
            </Flex>
        </ModalLayout>
    );
};

export default InternalErrorModal;
