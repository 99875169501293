import {
    QtcRenderer, Hint, Content, KeypadContext, KeypadContextType
} from '@3plearning/question-components-library';
import {
    Button, Text, VStack, HStack
} from '@chakra-ui/react';
import { ErrorBoundary } from '@sentry/react';
import React, {
    useState, useEffect, ReactEventHandler, useContext
} from 'react';
import { useDispatch } from 'react-redux';

import AppImage from '../../../../components/app-image/app-image';
import { useQuestionActivityAudio } from '../../../../components/audio/hooks/use-question-activity-audio';
import { useAudioEffectsContext } from '../../../../components/audio/provider/audio-effects-provider';
import { CorrectAnswer } from '../../../../components/correct-answer/correct-answer';
import CorruptedData from '../../../../components/corrupted-data/corrupted-data';
import { HintContent } from '../../../../components/hint/hint-content';
import WorkedSolution from '../../../../components/worked-solution/worked-solution';
import { MAX_ATTEMPTS } from '../../../../helpers/constants';
import {
    QuestionSetType, STUDENT_STATES, QuestionProgressState
} from '../../../../helpers/enums';
import { lokalisation } from '../../../../helpers/lokalisation';
import {
    transformSchema, getQueryParam
} from '../../../../helpers/utils';
import { Resources } from '../../../../resources/resources';
import { useProgressActivity } from '../../hooks/use-progress-activity';
import { QuestionProgressProps } from '../../question-container.types';
import { activateWorkedSolution } from '../../store/question-actions';
import QuestionFooter from '../question-footer/question-footer';
import {
    ActivityGuide,
    QuestionActivityStyled,
    QuestionBody,
    QuestionContent,
    WorkedSolutionCloseButton
} from './question-activity.styled';
import { QuestionActivityProps } from './question-activity.types';

export const QuestionActivity = ({
    activityState,
    isReadyToValidate,
    showNextQuestion,
    onUpdate,
    validateAnswer,
    onProgress,
    questionNumber,
    allQuestionsComplete,
    scrollableContainer
}: QuestionActivityProps) => {
    const dispatch = useDispatch();
    const {
        buttonTxt: {
            hint: hintBtnLabel, next, check, tryAnother, workedSolution, resume
        },
        labelTxt: { correct },
        questionComponentsLibraryTxt
    } = lokalisation();
    const {
        activity: questionData,
        validation,
        questionSaveInProgress,
        studentState,
        questName,
        conceptRefresh,
        isWorkedSolutionActivated,
        questionSetType,
        keyboardPreset,
        readAloudEnabled,
        readAloudLocale,
        questionProgressState
    } = activityState;
    const conceptRefreshContent = transformSchema(conceptRefresh);
    const conceptRefreshUrl = conceptRefreshContent && [...conceptRefreshContent[0].content]?.pop()?.text || '';
    const {
        hint,
        question,
        questionVariantId
    } = questionData || {};
    /*
     * PROGRESS ACTIVITY HOOK
     */
    const questionProgress: QuestionProgressProps = useProgressActivity();
    const {
        progressAttempt, questionAnswered, questionCorrect
    } = questionProgress;
    const checkButtonDisabled = questionSaveInProgress || !isReadyToValidate;
    const [footerBtnLabel, setFooterBtnLabel] = useState<string>();
    const [footerBtnColorScheme, setFooterBtnColorScheme] = useState<string>('primary');
    const {
        keypadRef, questionBodyRef, questionBodyElement, showKeypad, setShowKeypad, showKeypadBtn, isKeypadBtnDisabled
    } = useContext(KeypadContext) as KeypadContextType;
    const isDiagnostic = questionSetType !== QuestionSetType.questiconActivity;
    const isGuideImageRemoved = questionSetType === QuestionSetType.unitTest;
    const { playClick } = useAudioEffectsContext();
    const showCorrectAnswer = questionAnswered && !questionCorrect && !isWorkedSolutionActivated
        && questionProgressState !== QuestionProgressState.nextUnanswered;
    const { showCorrectAnswerScreen } = useQuestionActivityAudio({ showCorrectAnswer });
    const keypadPresetFromQueryParams = getQueryParam('keypadPreset');

    useEffect(() => {
        let label;
        let colorScheme = 'primary';

        const maxAttempts = isDiagnostic ? 1 : MAX_ATTEMPTS;

        if (questionAnswered) {
            if (questionCorrect || progressAttempt === maxAttempts) {
                label = next;
                if (questionCorrect) {
                    colorScheme = 'correct';
                }
            } else {
                label = tryAnother;
                colorScheme = 'incorrect';
            }
        } else {
            label = check;
        }
        if (allQuestionsComplete || questionSetType === QuestionSetType.unitTest) {
            label = next;
        }
        setFooterBtnColorScheme(colorScheme);
        setFooterBtnLabel(label);
    }, [allQuestionsComplete, progressAttempt, check, next, questionAnswered, questionCorrect, tryAnother, isDiagnostic, questionSetType]);

    useEffect(() => {
        if (isWorkedSolutionActivated && questionBodyElement) {
            questionBodyElement.scrollTo(0, 0);
        }
    }, [questionBodyElement, isWorkedSolutionActivated]);

    const assetsBasePath = window._env_.ASSETS_CDN_BASE_PATH;
    const showQuestion = studentState === STUDENT_STATES.question;
    const toggleKeypad: ReactEventHandler = (event) => {
        event.preventDefault();
        setShowKeypad(!showKeypad);
    };
    const readAloudConfig = {
        enabled: readAloudEnabled && !isWorkedSolutionActivated,
        gatewayUrl: window._env_.READ_ALOUD_GATEWAY_BASE_URL,
        token: Resources.env?.authToken,
        locale: readAloudLocale
    };
    const hintProps =  {
        tracking: () => {
            window.dataLayer.push({ event: 'hint' });
        },
        content: <HintContent readAloudConfig={readAloudConfig} content={(hint as Content) || []} />,
        hintBtnLabel
    };

    return (
        <>
            <QuestionActivityStyled
                showQuestion={showQuestion}
                isValidated={questionAnswered}
                isCorrect={questionCorrect}
                correctLabel={correct}
                isWorkedSolutionActivated={!!isWorkedSolutionActivated}
                isDiagnostic={isDiagnostic}
                isGuideImageRemoved={isGuideImageRemoved}
            >
                {questionSetType !== QuestionSetType.unitTest &&
                    <ActivityGuide className={'activity-guide'} iconType={'activityGuide'} imageType={'svg'} />}
                <VStack className={'question-header'} spacing={0}>
                    <div className={'question-header-title'}>
                        <Text className={'quest-name'}>
                            {questName}
                        </Text>
                        {studentState === STUDENT_STATES.resume && <Button
                            colorScheme={'primary'}
                            className={'show-question-button'}
                            onClick={onProgress}
                            data-testid={'show-question-button'}
                        >
                            {resume}
                        </Button>}
                    </div>
                    <div className={'question-header-hint'}>
                        <HStack pt={'16px'} pr={'24px'} pb={'8px'} minH={'30px'} spacing={'16px'}>
                            {
                                showKeypadBtn && (
                                    <Button
                                        h={'32px'}
                                        aria-label={'keypad toggle'}
                                        variant={'outline'}
                                        colorScheme={'white'}
                                        data-testid={'keypad-toggle'}
                                        onMouseDown={toggleKeypad}
                                        onClick={() => playClick()}
                                        isDisabled={isKeypadBtnDisabled}
                                        isActive={showKeypad && !isKeypadBtnDisabled}
                                        tabIndex={-1}
                                    >
                                        <AppImage iconType={'keypadIcon'}imageType={'svg'} />
                                    </Button>
                                )
                            }
                            {!isDiagnostic && <Hint {...hintProps}>{hintProps.content}</Hint>}
                        </HStack>
                    </div>
                </VStack>
                <div className={'question-holder'} data-testid={'question-holder'}>
                    {isWorkedSolutionActivated &&
                    <WorkedSolutionCloseButton
                        colorScheme={'white'}
                        variant={'outline'}
                        aria-label={'Worked Solution close button'}
                        onClick={() => {
                            playClick();
                            dispatch(activateWorkedSolution(false));
                        }}
                    />
                    }
                    <QuestionBody tabIndex={-1} isWorkedSolutionActivated={!!isWorkedSolutionActivated} ref={questionBodyRef}>
                        <QuestionContent
                            key={questionVariantId}
                            isWorkedSolutionActivated={!!isWorkedSolutionActivated}
                            className={'question-content'}
                        >
                            {keypadRef.current && questionVariantId && assetsBasePath !== null && assetsBasePath !== '' ?
                                <ErrorBoundary
                                    fallback={<CorruptedData />}
                                    onError={() => {
                                        onUpdate({
                                            isReady: true,
                                            answer: {}
                                        });
                                    }}
                                >
                                    <QtcRenderer
                                        keypadContainer={keypadRef.current}
                                        scrollableContainer={scrollableContainer}
                                        keypadPreset={
                                            (keypadPresetFromQueryParams ? Number(keypadPresetFromQueryParams) : null) ?? keyboardPreset
                                        }
                                        assetsBasePath={assetsBasePath}
                                        callback={onUpdate}
                                        question={transformSchema(question) as []}
                                        questionNumber={questionNumber}
                                        validation={validation}
                                        readAloudConfig={readAloudConfig}
                                        translations={questionComponentsLibraryTxt}
                                    />
                                </ErrorBoundary>: null}
                            {
                                showCorrectAnswerScreen &&
                                <CorrectAnswer question={question} validation={validation} />
                            }
                        </QuestionContent>
                        {isWorkedSolutionActivated && <WorkedSolution multiDocs={validation?.workedSolution} />}
                    </QuestionBody>
                    <QuestionFooter
                        conceptRefreshUrl={conceptRefreshUrl}
                        checkButtonDisabled={checkButtonDisabled}
                        showNextQuestion={showNextQuestion}
                        validateAnswer={validateAnswer}
                        validation={validation}
                        footerBtnLabel={footerBtnLabel}
                        workedSolution={workedSolution}
                        footerBtnColorScheme={footerBtnColorScheme}
                        isDiagnostic={isDiagnostic}
                        isWorkedSolutionActivated={isWorkedSolutionActivated}
                    />
                </div>
            </QuestionActivityStyled>
        </>
    );
};
