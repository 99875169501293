import { Box } from '@chakra-ui/react';
import React from 'react';

import { lokalisation } from '../../helpers/lokalisation';

const CorruptedData = () => {
    const { qtcRendererTxt: { corruptedData } } = lokalisation();

    return (
        <Box
            padding={'20px'}
            display={'flex'}
            justifyContent={'center'}
        >
            {corruptedData}
        </Box>
    );
};

export default CorruptedData;
