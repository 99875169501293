import styled from '@emotion/styled';

import {
    pickThemeFlex, pickThemeVar
} from '../../../../styles/theme/app-theme';

export const IntroScreenStyled = styled.div`
  width: 100%;
  background-color: white;
`;

export const HeaderTitle = styled.div`
  font-size: 30px;
  line-height: 140%;
  color: ${({ theme: { colors: { neutral } } }) => neutral['900']};
  letter-spacing: -0.8px;
  margin: 32px 32px 0 40px;

  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    font-size: 24px;
    margin: 32px 32px 0 32px;
  }

  @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
    margin: 32px 20px 0 20px;
  }
`;

export const QuestionSetTitle = styled.div`
  font-size: 44px;
  line-height: 57px;
  letter-spacing: -1.2px;
  margin: 24px 24px 0 40px;
  color: ${pickThemeVar('blue02')};

  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    font-size: 36px;
    line-height: 47px;
    letter-spacing: -1.15px;
    margin: 24px 24px 0 32px;
    color: ${pickThemeVar('blue02')}
  }

  @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
    font-size: 34px;
    line-height: 44px;
    margin: 24px 20px 0 20px;
    color: ${pickThemeVar('blue02')}
  }
`;

export const ExampleHeading = styled.div`
  font-size: 30px;
  line-height: 42px;
  margin: 24px 24px 0 40px;
  letter-spacing: -0.8px;
  color: ${({ theme: { colors: { neutral } } }) => neutral['900']};

  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    font-size: 24px;
    line-height: 34px;
    margin: 24px 24px 0 32px;
  }

  @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
    margin: 24px 20px 0 20px;
  }
`;

export const ExampleQuestionWrapper = styled.div`
  font-weight: 400;
  letter-spacing: -1px;

  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    letter-spacing: -0.8px;
  }

  @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
    font-weight: 700;
    letter-spacing: -0.8px;
  }
`;

export const Section = styled.div`
  ${pickThemeFlex('flexRowSpaced')};
  background: linear-gradient(90deg, rgba(211, 241, 254, 1) 60%, rgba(197, 231, 191, 1) 100%);
  margin-top: 40px;
  padding: 40px 40px 0 40px;

  .column {
    width: calc(50% - 20px);
  }

  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    margin-top: 32px;
    padding: 30px 32px 0 32px;
  }

  @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
    ${pickThemeFlex('flexColumn')};
    padding: 32px 20px 0 20px;
    
    .column {
      width: 100%;

      :last-of-type {
        margin-bottom: 30px;
      }
    }
  }
`;

export const Content = styled.div`
  ${pickThemeFlex('flexColumn')};

  .copy {
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 30px;
    color: ${({ theme: { colors: { neutral } } }) => neutral['700']};

    @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
      font-size: 14px;
      margin-bottom: 24px;
    }

    @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
        line-height: 140%;
    }
  }
`;

export const Heading = styled.div`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 12px;
  color: ${({ theme: { colors: { neutral } } }) => neutral['900']};
  letter-spacing: -0.002em;

  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    font-size: 18px;
    line-height: 21.6px;
    margin-bottom: 12px;
    letter-spacing: -0.004em;
  }
`;

export const ConceptRefreshImageBtn = styled.input`
  width: 100%;
  height: auto;
  margin-bottom: 25px;
  border-radius: 8px;
`;
