import { Flex } from '@chakra-ui/react';
import React, {
    FC, useState
} from 'react';

import { ProgressStarAnim } from './progress-star-anim';
import { ProgressStarRating } from './progress-star-rating';
import { ProgressStarsProps } from './star-rating.types';

export const ProgressStars: FC<ProgressStarsProps> = ({
    totalStars, playStarsAnim
}) => {
    const [playStarNum, setPlayStarNum] = useState<number>(0);

    return (
        <Flex
            boxShadow={'0px 0px 4.63216px 0.772026px rgba(11, 55, 83, 0.08);'}
            rounded={'2xl'}
            data-testid={'progress-stars'}
            w={{
                base: '100%',
                md: '264px',
                break1270: '296px'
            }}
            h={{
                base: '80px',
                break1270: '110px'
            }}
            pos={'relative'}
            overflow={'hidden'}
            alignItems={'center'}
            justifyContent={{ base: 'center' }}
        >
            <ProgressStarRating totalStars={playStarsAnim ? totalStars : 0} playStarNum={playStarNum} />

            {playStarsAnim &&
                <ProgressStarAnim
                    totalStars={totalStars}
                    setPlayStarNum={setPlayStarNum}
                />
            }
        </Flex>
    );
};
