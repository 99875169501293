import styled from '@emotion/styled';

import { pickThemeFlex } from '../../styles/theme/app-theme';

export const AppInitialisationStyled = styled.div`
  ${pickThemeFlex('flexChildCentered')};
  
  width: 100%;
  height: 100%;
`;
