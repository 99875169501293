import { useState } from 'react';

import { ExitType } from '../helpers/enums';
import { getQueryParam } from '../helpers/utils';
import { getURLLocale } from '../i18n/utils/locale-utils';
import { Resources } from '../resources/resources';

export const useExitApp = () => {
    const [exiting, setExiting] = useState(false);
    const redirect = () => {
        const backUrl = getQueryParam('backUrl');

        setExiting(true);

        if (backUrl) {
            window.location.href = backUrl;
        } else if (document.referrer == '') {
            location.reload();
        } else {
            window.close();
        }
    };
    const exitApp = async (questionSetType?: number, exitType?: number) => {
        const questionSetId = getQueryParam('questionSetId') || '';
        const assignmentId = getQueryParam('assignmentId');

        try {
            setExiting(true);
            const response = await Resources.exitQuestionSet?.post().withBody({
                questionSetId,
                assignmentId: assignmentId != null ? parseInt(assignmentId) : 0,
                questionSetTypeId: questionSetType,
                progressType: exitType ?? ExitType.normalExit,
                locale: getURLLocale()
            }).send();

            if (response.status > 399) {
                setExiting(false);
            }
        } catch (error) {
            setExiting(false);
            console.log(error);
        }

        redirect();
    };

    return {
        exiting,
        exitApp,
        redirect
    };
};
