import {
    Button, Circle
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
    pickThemeFlex, pickThemeVar
} from '../../../../styles/theme/app-theme';

export const QuestionFooterContainer = styled.div`
  ${pickThemeFlex('flexRowVerticalCenteredSpaced')};
  
  height: ${pickThemeVar('footerHeight')};
  box-shadow: 0px 0px 4.92526px 0.820876px rgba(11, 55, 83, 0.08);
  flex-shrink: 0;
  
  .left-footer {
    ${pickThemeFlex('flexRowVerticalCentered')};
    margin-left: 24px;
  }

  .right-footer {
    ${pickThemeFlex('flexRowVerticalCentered')};
    margin: 0 24px 0 0;
    flex: 1;
    justify-content: flex-end;
    margin-left: 10px;
  }

  .worked-solution-pulse {
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    transition: all 300ms ease-in-out;
  }


  @keyframes pulsing {
    0% {
      box-shadow: 0 0 0 0px rgba(153, 221, 246, 1);
    }
    100% {
      box-shadow: 0 0 0 30px rgba(153, 221, 246, 0);
    }
  }

  @media (max-width: ${pickThemeVar('breakPoint')}) {
    .left-footer {
      margin-left: 16px;
    }

    .right-footer {
      margin-right: 16px;
    }
  }

  @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
    height: ${pickThemeVar('footerHeight')};

    .right-footer {
      justify-content: flex-end;
      flex: 1;
    }
  }
`;

export const IconSupportButton = styled(Button)`
  width: 40px;
  height: 40px;
  font-size: 16px;
  border-radius: 100%;
  padding: 0; 

  // this is to offset the svg inside the button
  // so that it appears to be in the center
  > svg {
    margin-top: 2px;
  }

  ${(props) => props.isDisabled ? css`svg {
    path {
      fill: ${pickThemeVar('neutral300')(props)};
    }
  }` : ''}
`;

export const SupportButton = styled(Button)`
  width: 173px;
  height: 40px;
  font-size: 16px;

  ${(props) => props.isDisabled ? css`svg {
    path {
      fill: ${pickThemeVar('neutral300')(props)};
    }
  }` : ''}
  
  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    width: 145px;
    height: 32px;
    font-size: 14px;
  }
`;

export const SubmitButton = styled(Button)`
  max-width: 210px;
  width: 100%;
  height: 48px;
  border: none;
  margin-left: 20px;
  font-size: 20px;


  @media (max-width: ${pickThemeVar('breakPoint')}) {
    margin-left: 10px;
  }

  @media (max-width: ${pickThemeVar('breakTabletLandscape')}) {
    max-width: 160px;
    height: 40px;
    font-size: 16px;
  }

  @media (max-width: ${pickThemeVar('breakTabletPortraitPhone')}) {
    margin-left: 0;
    max-width: 130px;
  }
`;

export const CloseIcon = styled(Circle)(props => {
    const {
        primary600, breakTabletPortrait, breakTabletLandscape
    } = props.theme.app;

    return css`
      width: 24px;
      height: 24px;
      background-color: ${primary600};
      position: relative;

      @media (min-width: ${breakTabletPortrait}) and (max-width: ${breakTabletLandscape}) {
        width: 20px;
        height: 20px;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.78);

        path {
          stroke: white;
        }
      }
  `;
});
