/* istanbul ignore file */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
    MilestoneState, PROGRESS_STATE
} from '../../../../helpers/enums';
import {
    pickThemeFlex, ThemeProps, APP_THEME, pickThemeVar
} from '../../../../styles/theme/app-theme';
import { flex } from '../../../../styles/theme/flex-mixins';
import { app } from '../../../../styles/theme/variables';
import { ProgressMilestoneProps } from './progress-milestone';

const {
    fluency, reasoning, problemSolving
} = PROGRESS_STATE;
const pinIconPosition = (posProps: ProgressMilestoneProps) => {
    let leftPos: number | string = 0;
    let topPos: number | string = 0;

    switch (posProps.progressType) {
    case fluency:
        leftPos = 39;
        topPos = 506;
        break;
    case reasoning:
        leftPos = 124;
        topPos = 293;
        break;
    case problemSolving:
        leftPos = 71;
        topPos = 126;
        break;
    }
    leftPos = `${leftPos}px`;
    topPos = `${topPos}px`;

    return css`
      left: ${leftPos};
      top: ${topPos};
    `;
};
const rowDirection = (props: ProgressMilestoneProps & ThemeProps) => {
    const { progressType } = props;

    let direction, left;

    if (progressType === fluency) {
        direction = flex.flexRowVerticalCentered;
        left = '2px';
    } else {
        direction = flex.flexRowReverse;
        left = '-2px';
    }

    return css `
      ${direction};
      
      .pin-holder {
        left: ${left};
      }
    `;
};
const isActiveType = (props: ProgressMilestoneProps) => {
    const { milestoneState } = props;

    let bgColour = 'white', displayPin = 'flex', colour = APP_THEME.app.blue02, border, borderColor;

    switch (milestoneState) {
    case MilestoneState.notStarted:
        bgColour = 'rgba(0, 0, 0, 0.4)';
        displayPin = 'none';
        colour = 'white';
        break;
    case MilestoneState.inProgress:
        border = '2px solid';
        borderColor = app.blue;
        break;
    case MilestoneState.completed:
        border = '2px solid';
        borderColor = 'rgba(255, 255, 255, 0.63)';
        break;
    }

    return css `
      background-color: ${bgColour};
      border: ${border};
      border-color: ${borderColor};
      
      .pin-holder .pin-icon {
        display: ${displayPin};
      };

      .label {
        color: ${colour};
      }
    `;
};

export const ProgressMilestoneStyled = styled.div<ProgressMilestoneProps>`
  ${rowDirection};
  ${isActiveType};
  ${pinIconPosition};
  position: absolute;
  height: 24px;
  border-radius: ${props => props.milestoneState === MilestoneState.notStarted ? '14px' : '16px'};
  backdrop-filter: blur(8px);
  white-space: nowrap;
  padding: 2px;
  z-index: 1;

  .pin-holder {
    ${pickThemeFlex('flexColumnCentered')};
    position: relative;

    .pinIcon {
      position: absolute;
      bottom: 6px;
    }
    
    .tick {
      position: absolute;
      top: 2px;
      width: 11px;
      
      path {
        fill: ${pickThemeVar('darkBlue')}
      }
    }
  }
  
  .label {
    margin: 0 10px;
    position: relative;
    bottom: 1px;
  }

`;

const completedMilestoneDot = (props: ProgressMilestoneProps) => {
    let border = 'none', borderColor, boxShadow = '0 2px 8px rgba(0, 51, 111, 0.241668)';

    const { milestoneState } = props;

    if (milestoneState === MilestoneState.completed) {
        border = '1px solid';
        boxShadow = 'none';
        borderColor = app.darkBlue;
    }

    return css`
      box-shadow: ${boxShadow};
      border: ${border};
      border-color: ${borderColor};
    `;
};

export const WhiteDot = styled.div<ProgressMilestoneProps>`
  ${completedMilestoneDot};
  background: white;
  border-radius: 10px;
  width: 18px;
  height: 18px;
`;

