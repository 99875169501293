import {
    answerRecord, answerType, dispatchStopReadAloudEvent
} from '@3plearning/question-components-library';
import { useBreakpointValue } from '@chakra-ui/react';
import React, {
    useEffect, useState
} from 'react';
import {
    useDispatch, useSelector
} from 'react-redux';

import {
    SaveQuestionRequest, SaveQuestionResponse
} from '../../../api/types/__generated__/data-contracts';
import { AppState } from '../../app/store/store.types';
import { useAudioEffectsContext } from '../../components/audio/provider/audio-effects-provider';
import {
    QuestionSetType, STUDENT_STATES
} from '../../helpers/enums';
import { getQueryParam } from '../../helpers/utils';
import { getURLLocale } from '../../i18n/utils/locale-utils';
import {
    getFinalTotals, getStarRatingNum
} from '../../routes/milestone-transition/sub-components/summary/utils/util';
import { useMilestoneProgress } from '../../routes/question-console/hooks/use-milestone-progress';
import { QuestionContainerStyled } from './question-container.styled';
import { QuestionContainerProps } from './question-container.types';
import { getNextStudentState } from './question-container.util';
import {
    activateWorkedSolution,
    loadNextQuestion, resetActivity, saveQuestion, setStudentState
} from './store/question-actions';
import { ProgressActivity } from './sub-components/progress-activity/progress-activity';
import { QuestionActivity } from './sub-components/question-activity/question-activity';

export const QuestionContainer = ({
    answerValidationHandler, scrollableContainer
}: QuestionContainerProps) => {
    const dispatch = useDispatch();
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const {
        studentState, activity, updatedProgress, questionSetType, progress
    } = activityState;
    /*
     * CHECK COMPLETION AND QUESTION NUMBER
     */
    const {
        allQuestionsComplete, questionNumber,
        isMilestoneComplete, navigateToTransitionScreen
    } = useMilestoneProgress();
    const [userResponse, setUserResponse] = useState<answerType | null>(null);
    const breakPoint = useBreakpointValue({ breakPoint: 'breakPoint' });
    const {
        playClick, playCongratulations, playCompletionAYR, playCompletionUPT
    } = useAudioEffectsContext();
    const questionProgress = updatedProgress || progress;
    const handleProgress = () => {
        const nextStudentState = getNextStudentState(studentState);

        if (nextStudentState === STUDENT_STATES.summary) {
            const { finalTotal: { stars: totalStars } } = getFinalTotals({ progress: questionProgress });
            const { numRatingStarAnims } = getStarRatingNum(totalStars);

            if(questionSetType !== QuestionSetType.diagnostics && questionSetType !== QuestionSetType.unitTest) {
                numRatingStarAnims && playCongratulations(numRatingStarAnims);
            } else if(questionSetType === QuestionSetType.unitTest) {
                numRatingStarAnims && playCompletionUPT();
            } else if(questionSetType === QuestionSetType.diagnostics) {
                numRatingStarAnims && playCompletionAYR();
            }
        }
        if (studentState !== nextStudentState) {
            dispatch(setStudentState(nextStudentState));
        }
    };
    const onUpdate = (userResponse: answerType) => {
        setUserResponse(userResponse);
    };
    const showNextQuestion = async (isPlayClickSkipped = {}) => {
        if (!(typeof isPlayClickSkipped === 'boolean' && isPlayClickSkipped)) {
            playClick();
        }
        dispatchStopReadAloudEvent();
        setUserResponse(() => null);
        await dispatch(resetActivity());
        if (updatedProgress?.isQuestCompleted && studentState !== STUDENT_STATES.summary) {
            handleProgress();
        } else {
            dispatch(loadNextQuestion());
            dispatch(activateWorkedSolution(false));
            isMilestoneComplete && navigateToTransitionScreen();
        }
    };
    const validateAnswer = async () => {
        const questionSetId = getQueryParam('questionSetId');
        const assignmentId = getQueryParam('assignmentId');

        playClick();

        if (answerValidationHandler) {
            answerValidationHandler(userResponse?.answer as answerRecord);
        } else if (
            questionSetId &&
            activity &&
            activity.contentId &&
            activity.questionVariantId) {
            const {
                contentId, questionVariantId
            } = activity;
            const saveData: SaveQuestionRequest = {
                questionSetId,
                assignmentId: assignmentId != null ? parseInt(assignmentId) : 0,
                studentAnswer: userResponse?.answer,
                contentId,
                questionVariantId,
                locale: getURLLocale()
            };
            const response = await dispatch(saveQuestion(saveData));

            if (questionSetType === QuestionSetType.unitTest) {
                if((response as SaveQuestionResponse).progress?.isQuestCompleted) {
                    handleProgress();
                } else {
                    showNextQuestion(true);
                }
            }
        }
    };

    useEffect(() => {
        if (breakPoint === 'breakPoint' && studentState === STUDENT_STATES.resume) {
            handleProgress();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakPoint, studentState]);

    const handleActivityProgress = () => {
        playClick();
        handleProgress();
    };

    return (
        <QuestionContainerStyled>
            {questionSetType === QuestionSetType.questiconActivity &&
                <ProgressActivity
                    questionNumber={questionNumber}
                />
            }
            <QuestionActivity
                activityState={activityState}
                isReadyToValidate={userResponse?.isReady}
                showNextQuestion={showNextQuestion}
                onUpdate={onUpdate}
                validateAnswer={validateAnswer}
                onProgress={handleActivityProgress}
                allQuestionsComplete={allQuestionsComplete}
                questionNumber={questionNumber}
                scrollableContainer={scrollableContainer}
            />
        </QuestionContainerStyled>
    );
};
