import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    useTheme,
    Box
} from '@chakra-ui/react';
import React from 'react';

import AppImage from '../../../components/app-image/app-image';
import { useWithMobile } from '../../../hooks/use-with-mobile';
import { FluencyFailModalBkgd } from './fluency-fail-modal-bkgd';
import { FluencyFailModalContent } from './fluency-fail-modal-content';
import { useModalResponsiveStyles } from './fluency-fail-modal.hooks';
import { FluencyFailModalProps } from './fluency-fail-modal.types';

const FluencyFailModal = ({
    questionSetType,
    isOpen,
    onClose
}: FluencyFailModalProps) => {
    const theme = useTheme();
    const {
        fluffyResponsiveStyles,
        modalResponsiveStyles
    } = useModalResponsiveStyles();
    const {
        landscape,
        isMobile,
        isTablet
    } = useWithMobile();
    const isMobileLandscape = (isMobile && landscape) && !isTablet;

    return (
        <Modal
            size={'full'}
            data-testid={'exit-without-completing-modal'}
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent
                border={'8px'}
                borderColor={theme.app.whiteAlpha30}
                bg={'transparent'}
                borderRadius={'40px'}
                mt={{
                    tabletLandscape: '7rem',
                    md: isMobileLandscape ? '0px' : '200px',
                    base: isMobileLandscape ? '0px' : '75px'
                }}
                w={{ base: '904px' }}
                {...modalResponsiveStyles()}
            >
                <ModalBody
                    data-testid={'modal-body'}
                    p={0}
                    position={'relative'}
                >
                    <Box
                        position={'absolute'}
                        zIndex={10}
                        top={{
                            tabletLandscape: '-34px',
                            base: '-18px'
                        }}
                        left={{
                            tabletLandscape: '2px',
                            base: '-18px'
                        }}
                        width={'45%'}
                        {...fluffyResponsiveStyles()}
                    >
                        <AppImage imageType={'img'} iconType={'fluencyFailFluffy'} />
                    </Box>
                    <Box
                        position={'relative'}
                        overflow={'hidden'}
                        bg={'white'}
                        borderRadius={'34px'}
                    >
                        <FluencyFailModalContent
                            questionSetType={questionSetType}
                        />
                        <FluencyFailModalBkgd />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default FluencyFailModal;
