export const btnStyle = {
    px: {
        tabletLandscape: '32px',
        md: '16px'
    },
    py: {
        tabletLandscape: '8px',
        md: '10px'
    },
    w: {
        tabletLandscape: '220px',
        md: '200px',
        base: '156px'
    },
    h: {
        tabletLandscape: '62px',
        base: '40px'
    },
    fontSize: {
        tabletLandscape: '20px',
        base: '16px'
    },
    fontWeight: '500'
};
