import {
    Modal, ModalOverlay, type ModalProps
} from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

import { ModalContentContainer } from './modal-container';

type Props =
    & PropsWithChildren
    // Make properties optional on base type
    & Omit<ModalProps, 'onClose' | 'isOpen'>
    & {
    onClose: ModalProps['onClose']
    isOpen: boolean
};

export function ModalLayout ({
    children, ...restProps
}: Props)  {
    return (
        <Modal
            closeOnOverlayClick={false}
            variant={'modal-alert'}
            {...restProps}
        >
            <ModalOverlay
                bg={{
                    base: 'none',
                    breakTabletPortraitPhone: 'blackAlpha.600'
                }}
            />

            <ModalContentContainer
                h={{
                    base: 'inherit',
                    breakTabletPortraitPhone: 'auto'
                }}
                maxW={{
                    base: 'initial',
                    breakTabletPortraitPhone: '720px',
                    break1270: '900px'
                }}
                borderRadius={{
                    base: 0,
                    breakTabletPortraitPhone: '20px'
                }}
                top={'0 !important'}
                overflow={'auto'}
                my={'auto'}
            >
                {children}
            </ModalContentContainer>
        </Modal>
    );
}
