import React from 'react';
import { useIsOnline } from 'react-use-is-online';

import NoInternetModal from '../../components/modals/no-internet-modal/no-internet-modal';

const InternetConnectivity = () => {
    // Changed the old custom image detection to a more reliable hooks
    const { isOnline } = useIsOnline();

    return (
        <NoInternetModal
            isOpen={!isOnline}
        />
    );
};

export default InternetConnectivity;
