import { useDisclosure } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../app/store/store.types';
import { ServerErrorType } from '../../helpers/enums';
import { useServerErrorAggregator } from '../../hooks/use-server-error-aggregator';
import InternalErrorModal from '../modals/internal-error/internal-error';

export const AppError = () => {
    const { errorType } = useServerErrorAggregator();
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const { isOffline } = activityState;
    const {
        isOpen, onClose, onOpen
    } = useDisclosure();
    const chakraModalProps = {
        isOpen,
        onClose
    };

    useEffect(() => {
        if (errorType === ServerErrorType.other && !isOffline) {
            onOpen();
        } else {
            onClose();
        }
        if (isOffline) {
            onClose();
        }
    }, [onOpen, onClose, errorType, isOffline]);

    return <InternalErrorModal {...chakraModalProps}  />;
};
