/* istanbul ignore file */
import {
    useEffect, useState
} from 'react';
import {
    useDispatch, useSelector
} from 'react-redux';

import { AppState } from '../../../app/store/store.types';
import { setPlayCorrectStars } from '../../../containers/question-container/store/question-actions';
import { QuestionProgressState } from '../../../helpers/enums';
import { isDefined } from '../../../helpers/utils';
import { useAudioEffectsContext } from '../provider/audio-effects-provider';

type Props = {
    showCorrectAnswer?: boolean
};

export const useQuestionActivityAudio = ({ showCorrectAnswer }: Props) => {
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const [correctAudioCompleted, setCorrectAudioCompleted] = useState<boolean>(false);
    const [incorrectAudioCompleted, setIncorrectAudioCompleted] = useState<boolean>(false);
    const [showCorrectAnswerScreen, setShowCorrectAnswerScreen] = useState<boolean>(false);
    const {
        inCorrectSound, correctAnswerScreen, correctSound
    } = useAudioEffectsContext();
    const {
        validation, progress, questionProgressState
    } = activityState;
    const dispatch = useDispatch();

    useEffect(() => {
        if (questionProgressState === QuestionProgressState.correctAnswered) {
            correctSound.on('end', () => {
                setCorrectAudioCompleted(true);
            });
            correctSound.play();
        } else if (questionProgressState === QuestionProgressState.incorrectAnswered) {
            inCorrectSound.on('end', () => {
                setIncorrectAudioCompleted(true);
            });
            inCorrectSound.play();
        } else if (!isDefined(validation)) {
            setCorrectAudioCompleted(false);
            setIncorrectAudioCompleted(false);
        }
    }, [correctSound, inCorrectSound, questionProgressState, validation]);

    useEffect(() => {
        if (incorrectAudioCompleted && showCorrectAnswer) {
            setShowCorrectAnswerScreen(true);
            correctAnswerScreen.play();
        } else {
            setShowCorrectAnswerScreen(false);
        }
    }, [correctAnswerScreen, incorrectAudioCompleted, showCorrectAnswer]);

    useEffect(() => {
        if (correctAudioCompleted && progress) {
            dispatch(setPlayCorrectStars());
            setCorrectAudioCompleted(false);
        }
    }, [correctAudioCompleted, dispatch, progress]);

    return { showCorrectAnswerScreen };
};
