import { ContentSegment } from '@3plearning/question-components-library';
import {
    Button,
    Image,
    Container,
    Flex,
    HStack,
    Text,
    useTheme,
    UnorderedList,
    ListItem,
    Circle,
    Divider,
    VStack,
    useMediaQuery,
    Stack,
    StackDirection
} from '@chakra-ui/react';
import React, {
    useEffect, useRef, useState
} from 'react';
import { useHistory } from 'react-router-dom';

import SummaryMountain from '../../../../../assets/images/summary-mountain.svg';
import SummaryTick from '../../../../../assets/images/summary-tick.svg';
import { useAudioEffectsContext } from '../../../../components/audio/provider/audio-effects-provider';
import { MAX_QUESTION_SET_ATTEMPT } from '../../../../helpers/constants';
import {
    PROGRESS_STATE, TransitionScreen
} from '../../../../helpers/enums';
import { lokalisation } from '../../../../helpers/lokalisation';
import { getRandomArrayElement } from '../../../../helpers/utils';
import { ROUTES } from '../../../routes';
import { ProgressBarAndStarContainer } from './sub-components/progress-bar-and-star-container';
import {
    FeedbackProps,
    SummaryProps
} from './summary.types';
import { feedbackByScore } from './summary.utils';
import { getFinalTotals } from './utils/util';

export const Summary = (props: SummaryProps) => {
    const {
        progress, studentName, questName, praiseFeedback
    } = props;
    const summaryNextBtnRef = useRef<HTMLButtonElement>(null);
    const history = useHistory();
    const theme = useTheme();
    const {
        red06,
        yellow02,
        green03,
        red07,
        orange02,
        green04,
        fontGrey01,
        blue02,
        blue06,
        green02,
        orange01
    } = theme?.app;
    const [isLargerThan600] = useMediaQuery('(min-width: 600px)');
    const stackDirection: StackDirection = isLargerThan600 ? 'row' : 'column';
    const assetsBasePath = window._env_.ASSETS_CDN_BASE_PATH;
    const {
        buttonTxt, progressTxt, summaryTxt
    } = lokalisation();
    const {
        praiseStatement, praiseFeedback2, praiseFeedback3, feedbackByStrength
    } = summaryTxt;
    const { playClick } = useAudioEffectsContext();
    /*
     * Build a single TotalStats object, finalTotal
     */
    const {
        finalTotal: {
            correct: totalCorrect,
            stars: totalStars,
            questions: totalQuestions = 0
        }, sectionTotals
    } = getFinalTotals({ progress });
    const bgByStrength = [
        red06,
        yellow02,
        green03
    ];
    const emojiByStrength = [
        '👀',
        '🙌',
        '💪'
    ];
    const colorByStrength = [
        red07,
        orange02,
        green04
    ];
    const feedbacks = [
        feedbackByScore(praiseFeedback as FeedbackProps[] | undefined, totalCorrect * 10),
        [praiseFeedback2(totalCorrect)],
        [praiseFeedback3(totalCorrect)]
    ];
    const [selectedStatement, setSelectedStatement] = useState('');

    useEffect(() => {
        // prevent changing praise statement on rerender
        setSelectedStatement(getRandomArrayElement(praiseStatement(totalCorrect, studentName)));
        summaryNextBtnRef.current?.focus({ preventScroll: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // completedAttempts is incremented from BE after final question is completed. Show summary for completedAttempts: 1 - 5
    const exceededMaximumAttempts = (progress?.completedAttempts ?? 0) > MAX_QUESTION_SET_ATTEMPT;
    const handleNext = () => {
        playClick();
        if (exceededMaximumAttempts) {
            history.replace(`${ROUTES.milestoneTransition}/${TransitionScreen.exceededAttemptsFinishScreen}`);
        } else {
            history.replace(`${ROUTES.milestoneTransition}/${TransitionScreen.finishScreen}`);
        }
    };

    return (
        <Container
            m={0} p={0} margin={'auto'}
            data-testid={'summary-screen'}
            maxWidth={'100%'}
            bgColor={'white'}
        >

            <VStack
                px={{
                    base: '20px',
                    tabletLandscape: '40px'
                }}
                pt={8}
                pb={5}
                maxWidth={'100%'}
                alignItems={'stretch'}
                spacing={'20px'}
            >
                <Flex direction={'column'}>
                    <Text
                        fontSize={{
                            base: '24px',
                            tabletLandscape: '30px'
                        }}
                        mb={6}
                        data-testid={'praise-statement'}
                        color={'neutral.900'}
                    >
                        {selectedStatement} 🎉
                    </Text>

                    <ProgressBarAndStarContainer
                        totalCorrect={totalCorrect}
                        totalQuestions={totalQuestions}
                        totalStars={totalStars}
                    />

                </Flex>
                <Text fontSize={'20px'} color={blue02} data-testid={'quest-name'}>
                    {questName}
                </Text>
                <UnorderedList color={fontGrey01} spacing={4} pl={6}>
                    {feedbacks.filter((feedback) => feedback?.some(Boolean)).map((feedback, i) => (
                        <ListItem key={i} data-testid={`feedback-${i + 1}`}>
                            <ContentSegment assetsBasePath={assetsBasePath} content={feedback as []} />
                        </ListItem>
                    ))}
                </UnorderedList>
                <Stack
                    spacing={stackDirection === 'row' ? 0.5 : 5}
                    direction={stackDirection}
                    position={'relative'}
                >
                    {Object.keys(PROGRESS_STATE).filter(key => isNaN(Number(key))).map(
                        (state, value) => {
                            const strength = sectionTotals[value]?.strength;
                            const isFluency = value === PROGRESS_STATE.fluency;
                            const isProblemSolving = value === PROGRESS_STATE.problemSolving;

                            return (
                                <VStack
                                    key={state}
                                    bg={bgByStrength[strength]}
                                    h={'120px'}
                                    flexGrow={1}
                                    alignItems={'stretch'}
                                    justifyContent={'center'}
                                    pos={'relative'}
                                    borderLeftRadius={isFluency ? '20px' : undefined}
                                    borderRightRadius={isProblemSolving ? '20px' : undefined}
                                >
                                    <HStack
                                        p={'15px 10px'}
                                        align={'baseline'}
                                        pos={'absolute'}
                                        top={0}
                                        data-testid={`strength-${state}`}
                                    >
                                        <Text fontSize={'16px'}>{emojiByStrength[strength]}</Text>
                                        <Text
                                            fontSize={'16px'}
                                            color={colorByStrength[strength]}
                                            casing={'uppercase'}
                                            letterSpacing={'1px'}
                                        >
                                            {feedbackByStrength[strength]}
                                        </Text>
                                    </HStack>
                                    <HStack
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        zIndex={1}
                                        spacing={0}
                                    >
                                        <HStack
                                            border={'1px'}
                                            borderColor={blue06}
                                            borderRadius={'40px'}
                                            p={'5px'}
                                            bg={'white'}
                                            spacing={'4px'}
                                        >
                                            <Circle size={'20px'} border={'1px'} borderColor={blue06}>
                                                <Image src={SummaryTick} />
                                            </Circle>
                                            <Text as={'span'} color={blue02} fontSize={'14px'} data-testid={`label-${state}`}>
                                                {progressTxt[value]}
                                            </Text>
                                        </HStack>
                                        {sectionTotals[value]?.points?.map((pointsObj, i) => (
                                            <Circle
                                                key={i}
                                                bg={pointsObj.isCorrect ? green02 : orange01}
                                                size={'22px'}
                                                data-testid={`point-${state}-${i}`}
                                            >
                                                {!exceededMaximumAttempts &&
                                                    <Text color={'neutral.900'} fontSize={'12px'}>
                                                        {pointsObj.pointsEarned}
                                                    </Text>
                                                }
                                            </Circle>
                                        ))}
                                        {isProblemSolving &&
                                            <Image
                                                src={SummaryMountain}
                                                width={'24px'}
                                                height={'43px'}
                                                objectFit={'contain'}
                                                objectPosition={'top'}
                                                data-testid={`mountain-${state}`}
                                            />}
                                    </HStack>
                                    <Divider
                                        bg={'white'}
                                        height={'2px'}
                                        pos={'absolute'}
                                    />
                                    {stackDirection === 'row' &&
                                        <Divider
                                            bg={blue06}
                                            pos={'absolute'}
                                        />}
                                </VStack>
                            );
                        }
                    )}
                    {stackDirection === 'column' &&
                        <Divider
                            orientation={'vertical'}
                            bg={blue06}
                            pos={'absolute'}
                            top={'10%'}
                            h={'70%'}
                        />
                    }
                </Stack>
            </VStack>
            <Flex justify={'flex-end'} boxShadow={'xs'}>
                <Button
                    ref={summaryNextBtnRef}
                    colorScheme={'primary'}
                    mx={6} my={3}
                    w={{
                        base: '100%',
                        md: '180px',
                        tabletLandscape: '210px'
                    }}
                    h={'48px'}
                    fontSize={'20px'}
                    data-testid={'next-button'}
                    onClick={handleNext}
                >
                    {buttonTxt.next}
                </Button>
            </Flex>
        </Container>
    );
};
