import {
    Container, Box, Text, Flex, Button
} from '@chakra-ui/react';
import React, {
    useEffect,
    useRef
} from 'react';
import { useHistory } from 'react-router-dom';

import { useAudioEffectsContext } from '../../../../components/audio/provider/audio-effects-provider';
import { TransitionScreen } from '../../../../helpers/enums';
import { lokalisation } from '../../../../helpers/lokalisation';
import { returnToStudentPortal } from '../../../../helpers/utils';
import { ROUTES } from '../../../routes';
import { DiagnosticsSummaryProps } from './diagnostics-summary.types';

export const DiagnosticsSummary = ({
    studentName,
    totalCorrect,
    totalQuestions,
    maximumAttemptExceeded
}: DiagnosticsSummaryProps) => {
    const {
        diagnosticsSummaryTxt: {
            scoreStatement, diagnosticsPraises, youScored, scoreRetention, scoreRetentionAfterMaximumAttempt, prompt, noPointsEarn
        },
        buttonTxt: {
            next, finish
        }
    } = lokalisation();
    const history = useHistory();
    const diagnosticFinishBtnRef = useRef<HTMLButtonElement>(null);
    const { playClick } = useAudioEffectsContext();

    useEffect(() => {
        diagnosticFinishBtnRef.current?.focus({ preventScroll: true });
    }, []);

    function handleDiagnosticsFinish () {
        playClick();
        if (maximumAttemptExceeded || totalCorrect === 0) {
            returnToStudentPortal();
        } else {
            history.replace(`${ROUTES.milestoneTransition}/${TransitionScreen.finishScreen}`);
        }
    }

    return (
        <Container
            m={0} p={0} margin={'auto'}
            data-testid={'diagnostics-summary-screen'}
            maxWidth={'100%'}
            bgColor={'white'}
        >
            <Box
                px={{
                    base: '20px',
                    tabletLandscape: '40px'
                }}
                pt={{
                    base: '32px',
                    tabletLandscape: '40px'
                }}
                pb={{
                    base: '40px',
                    tabletLandscape: '48px'
                }}
                maxWidth={'100%'}
                color={'neutral.900'}
            >
                <Text
                    fontSize={{
                        base: '24px',
                        breakTabletLandscape: '30px'
                    }}
                    mb={{
                        base: '24px',
                        breakPoint: '32px'
                    }}
                >
                    {maximumAttemptExceeded ?
                        <>{noPointsEarn}</>
                        : <>{diagnosticsPraises(totalCorrect)}, <strong>{studentName}</strong>!</>}
                </Text>
                <Text
                    color={'primary.600'}
                    fontSize={18}
                    mb={2}
                >{youScored}</Text>
                <Text
                    color={'primary.600'}
                    fontSize={{
                        base: '40px',
                        breakTabletLandscape: '44px'
                    }}
                    mb={{
                        base: '24px',
                        breakPoint: '32px'
                    }}
                >{scoreStatement(totalCorrect, totalQuestions)}</Text>
                {!maximumAttemptExceeded && totalCorrect < 9 &&
                    <>
                        <Text
                            fontSize={{
                                base: '24px',
                                breakTabletLandscape: '30px'
                            }}
                            mb={6}
                        >{prompt}</Text>
                        <Text fontSize={18}>{scoreRetention}</Text>
                    </>
                }

                {(maximumAttemptExceeded || totalCorrect >= 9) &&
                    <Text fontSize={18}>{scoreRetentionAfterMaximumAttempt}</Text>
                }
            </Box>
            <Flex justify={'flex-end'} boxShadow={'xs'}>
                <Button
                    ref={diagnosticFinishBtnRef}
                    colorScheme={'primary'}
                    mx={6} my={3}
                    w={{
                        base: '100%',
                        md: '180px',
                        tabletLandscape: '210px'
                    }}
                    h={'48px'}
                    fontSize={'20px'}
                    data-testid={'finish-button'}
                    onClick={handleDiagnosticsFinish}
                >
                    {(maximumAttemptExceeded || totalCorrect === 0) ? finish : next}
                </Button>
            </Flex>
        </Container>
    );
};
