import { KeypadProvider } from '@3plearning/question-components-library';
import React, { PropsWithChildren } from 'react';

import { AppError } from '../components/app-error/app-error';
import { AudioEffectsProvider } from '../components/audio/provider/audio-effects-provider';
import { BuildNumber } from '../containers/build-number/build-number';
import FluencyFail from '../containers/fluency-fail/fluency-fail';
import InternetConnectivity from '../containers/internet-connectivity/internet-connectivity';
import SessionTimeout from '../containers/session-timeout/session-timeout';
import { I18nProvider } from '../i18n/i18n-provider';
import { AppRoutes } from '../routes/app-routes';
import { AppThemeProvider } from '../styles/app-theme-provider';
import { GlobalStyles } from '../styles/global-styles';
import { AppReduxProvider } from './store/app-redux-provider';

export function AppProviders ({ children }: PropsWithChildren<unknown>) {
    return (
        <AppReduxProvider>
            <AppThemeProvider >
                <I18nProvider>
                    <AudioEffectsProvider>
                        <GlobalStyles />
                        <SessionTimeout />
                        <InternetConnectivity />
                        <FluencyFail />
                        <BuildNumber />
                        <KeypadProvider>
                            <AppRoutes>
                                {children}
                            </AppRoutes>
                        </KeypadProvider>
                        <AppError />
                    </AudioEffectsProvider>
                </I18nProvider>
            </AppThemeProvider>
        </AppReduxProvider>
    );
}
