import {
    Circle, Image
} from '@chakra-ui/react';
import React, {
    useEffect, useState
} from 'react';

import starGrey from '../../../../../../../assets/images/Star-grey.svg';
import starHalfGold from '../../../../../../../assets/images/Star-half.svg';
import starGold from '../../../../../../../assets/images/Star.svg';
import { starStyle } from './progress-star-rating';
import { ProgressStarProps } from './star-rating.types';

export const ProgressStar = ({
    num, playStarNum, starType
}: ProgressStarProps) => {
    const [showAnim, setShowAnim] = useState<boolean>(false);

    useEffect(() => {
        if (num === playStarNum) {
            setShowAnim(true);
        }
    }, [num, playStarNum]);

    return (
        <Circle
            sx={starStyle}
            border={'1px'}
            borderColor={'#D9E0E7'}
            boxSizing={'border-box'}
            pos={'relative'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Image
                src={starGrey}
                pos={'absolute'}
                data-testid={`grey-star-${num}`}
                h={{
                    base: '28px',
                    break1270: '37px'
                }}
            />
            {starType &&
                <Image
                    src={starType === 'Half' ? starHalfGold : starGold}
                    pos={'absolute'}
                    data-testid={`gold-star-${num}`}
                    h={{
                        base: '35px',
                        break1270: '44px'
                    }}
                    display={showAnim ? 'block' : 'none'}
                />
            }
        </Circle>
    );
};
