import {
    useDisclosure,
    Flex,
    VStack,
    Text,
    TextProps,
    Button
} from '@chakra-ui/react';
import React from 'react';

import AppImage from '../../../components/app-image/app-image';
import { lokalisation } from '../../../helpers/lokalisation';
import { ModalLayout } from '../modal-layout';
import { para } from './session-timeout-modal.constants';
import { ModalErrorProps } from './session-timeout-modal.types';

const SessionTimeoutModal = ({ isOpen }: ModalErrorProps) => {
    const { onClose } = useDisclosure();
    const {
        sessionTimeoutTxt,
        buttonTxt
    } = lokalisation();
    const signInHandler = () => {
        window.open(`${window._env_.MATHLETICS_LOGIN}`, '_self');
    };

    return (
        <>
            <ModalLayout data-testid={'session-timeout-modal'} isOpen={isOpen} onClose={onClose}>
                <Flex
                    flexDirection={{
                        base: 'column',
                        break680: 'row'
                    }}
                    align={'start'}
                >
                    <Flex
                        justify={'centre'}
                        alignSelf={{
                            base: 'start',
                            breakTabletPortraitPhone: 'center'
                        }}
                        h={'100%'}
                        w={'100%'}
                        maxW={{
                            base: '380px',
                            breakTabletPortraitPhone: '420px',
                            break1270: '450px'
                        }}
                    >
                        <AppImage imageType={'img'} iconType={'sessionTimeout'} className={'sloth'} />
                    </Flex>

                    <VStack
                        spacing={0}
                        align={{
                            base: 'center',
                            break680: 'start'
                        }}
                        justifyContent={'center'}
                        w={'100%'}
                        h={'100%'}
                        minHeight={{
                            base: 'auto',
                            break680: '410px'
                        }}
                        pr={{
                            base: '0px',
                            breakTabletPortraitPhone: '30px',
                            lg: '24px',
                            break1270: '40px'
                        }}
                        py={{
                            base: '16px',
                            break680: 0
                        }}
                    >
                        <VStack
                            mx={{
                                base: '30px',
                                break680: 0
                            }}
                            align={'start'}
                            pb={{
                                base: '30px',
                                lg: '48px'
                            }}
                            pr={{
                                base: 0,
                                break680: '20px',
                                breakTabletPortraitPhone: 0
                            }}
                        >
                            <Text
                                color={'neutral.900'}
                                fontSize={{
                                    base: '32px',
                                    break680: '44px'
                                }}
                                lineHeight={'57.2px'}
                                letterSpacing={'-1.2px'}
                                fontWeight={'400'}
                                marginBottom={'12px'}
                            >
                                {sessionTimeoutTxt.header}
                            </Text>
                            <Text
                                {...para as TextProps}
                                color={'neutral.900'}
                                marginBottom={'12px'}
                            >
                                {sessionTimeoutTxt.content1}
                            </Text>
                            <Text
                                {...para as TextProps}
                            >
                                {sessionTimeoutTxt.content2}
                            </Text>
                        </VStack>
                        <Button
                            colorScheme={'primary'}
                            size={'lg'}
                            padding={'0 50px'}
                            onClick={signInHandler}
                            w={{
                                base: 'calc(100% - 60px)',
                                break680: 'auto'
                            }}
                        >
                            {buttonTxt.signIn}
                        </Button>
                    </VStack>
                </Flex>
            </ModalLayout>
        </>
    );
};

export default SessionTimeoutModal;
