import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';

import { useGetAnimationDetails } from '../hooks/use-get-animation-details';
import { ProgressBar } from './progress-bar/progress-bar';
import { StarRatingContainer } from './star-rating/star-rating-container';

export type ProgressBarAndStarContainerProps = {
    totalCorrect: number
    totalQuestions: number
    totalStars: number
    playStarAnimation?: boolean
};

export const ProgressBarAndStarContainer: FC<ProgressBarAndStarContainerProps> = ({
    totalCorrect, totalQuestions, totalStars
}) => {
    const {
        score,
        showGold,
        barRef,
        barGoldRef,
        goldBarStars,
        goldBrick,
        totalCorrectRef,
        scoreNum,
        playStarsAnim
    } = useGetAnimationDetails({
        totalCorrect,
        totalQuestions
    });

    return (
        <Flex
            justifyContent={'space-between'}
            wrap={'wrap'}
            alignItems={'flex-end'}
        >
            <ProgressBar
                score={score}
                totalCorrect={totalCorrect}
                totalQuestions={totalQuestions}
                showGold={showGold}
                barRef={barRef}
                barGoldRef={barGoldRef}
                goldBarStars={goldBarStars}
                goldBrick={goldBrick}
                totalCorrectRef={totalCorrectRef}
                scoreNum={scoreNum}
                isSummaryPage={true}
            />
            <StarRatingContainer
                playStarsAnim={playStarsAnim}
                totalQuestions={totalQuestions}
                totalStars={totalStars}
            />
        </Flex>
    );
};

