import { progressAnatomy as parts } from '@chakra-ui/anatomy';
import { ProgressProps } from '@chakra-ui/react';
import {
    PartsStyleFunction, SystemStyleFunction
} from '@chakra-ui/theme-tools';

const baseStyleFilledTrack: SystemStyleFunction = (props) => {
    const overrides: ProgressProps = {};

    if (props.colorScheme === 'green') {
        overrides.bg = props.theme.app.green02;
    } else if (props.colorScheme === 'gold') {
        overrides.bg = 'linear-gradient(132.84deg, #FEDF01 0%, #EAA400 100%)';
    }

    return overrides;
};
const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
    filledTrack: baseStyleFilledTrack(props),
    track: {
        bg: 'transparent',
        border: '1px solid #D1E8EF',
        borderRadius: '32px'
    }
});

export const Progress = { baseStyle };
