import { ModalClickviewVideo } from '@3plearning/question-components-library';
import { useDisclosure } from '@chakra-ui/react';
import React from 'react';

import conceptRefreshBtnSrc from '../../../../../assets/images/concept-refresh-btn.png';
import { useAudioEffectsContext } from '../../../../components/audio/provider/audio-effects-provider';
import { lokalisation } from '../../../../helpers/lokalisation';
import {
    ConceptRefreshImageBtn,
    Content, Heading
} from './intro-screen.styled';
import {
    ConceptRefreshSlideProps,
    ContentType
} from './intro-screen.types';

const ConceptRefreshSlide = ({ conceptRefresh }: ConceptRefreshSlideProps) => {
    const { ...chakraModalProps } = useDisclosure();
    const { introTxt } = lokalisation();
    const url = conceptRefresh && [...conceptRefresh[0].content]?.pop()?.text || '';
    const { playClick } = useAudioEffectsContext();

    return (
        <Content>
            <Heading>
                {introTxt.conceptRefresh}
            </Heading>
            <ConceptRefreshImageBtn
                type={'image'}
                src={conceptRefreshBtnSrc}
                tabIndex={0}
                onClick={() => {
                    window.dataLayer.push({ event: 'concept_refresh' });
                    playClick();
                    chakraModalProps.onOpen();
                }}
                data-testid={'concept-refresh-btn'}
            />
            <ModalClickviewVideo
                url={url}
                contentType={ContentType.conceptRefresh}
                {...chakraModalProps}
            />
        </Content>
    );
};

export default ConceptRefreshSlide;
