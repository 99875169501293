/* istanbul ignore file */
import { gsap } from 'gsap';

import { RefType } from '../question-container.types';

const getListRefTargets = (list: RefType[]) => list.map(item => item.current);

/*
* CANNONS TWEEN
* */

export const showStars = (stars: RefType[], starListRef: RefType) => {
    const starsRefs = getListRefTargets(stars);
    const list = starListRef.current;

    gsap.timeline()
        .set(list, { transformOrigin: '50% 50%' })
        .fromTo(starsRefs,
            {
                y: 10,
                opacity: 0
            },
            {
                duration: 0.5,
                opacity: 1,
                y: 0,
                ease: 'back.out(2)',
                stagger: 0.3
            })
        .to(list,
            {
                rotation: '360',
                duration: 2,
                ease: 'back.out(2)'
            });
};

type AnimateScore = {
    bar: HTMLDivElement
    barGold: HTMLDivElement
    score: number
    scoreNumTxt: HTMLDivElement
    callback: () => void
};

export const animateScore = ({
    bar,
    barGold,
    scoreNumTxt,
    score,
    callback
}: AnimateScore) => {
    const scorePercent = `${score}%`;
    const target = { val: 0 };
    const showBarGold = score >= 80;

    scoreNumTxt.innerHTML = '0';
    gsap.timeline()
        .add('start')
        .fromTo(bar, {
            duration: 1,
            width: '0px',
            ease: 'power1.in',
            delay: 1
        }, {
            duration: 2,
            width: `${scorePercent}`,
            stagger: 1,
            onComplete: () => {
                callback();
            }
        }, 'start')
        .to(target, {
            duration: 2,
            val: score,
            onUpdate: function () {
                scoreNumTxt.textContent = Math.round(target.val).toString();
            }
        }, 'start')
        .to(barGold, {
            opacity: showBarGold ? 1 : 0,
            duration: 1
        });
};
