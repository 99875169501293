import {
    ModalBody, ModalContent, type ModalContentProps
} from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

type Props =
    & PropsWithChildren
    & ModalContentProps;

export function ModalContentContainer ({
    children, ...restProps
}: Props) {
    return (
        <ModalContent
            mx={0}
            overflow={'hidden'}
            boxShadow={'0px 4px 4px 0px rgba(0, 0, 0, 0.25)'}
            outline={{
                base: 'none',
                breakTabletPortraitPhone: '8px solid rgba(255, 255, 255, 0.30)'
            }}
            w={'100%'}
            motionProps={{
                initial: {
                    opacity: 0,
                    top: -200
                },
                animate: {
                    opacity: 1,
                    top: -20,
                    transition: {
                        ease: 'backInOut',
                        duration: 0.6
                    }
                },
                exit: {
                    opacity: 0,
                    top: -200,
                    transition: {
                        ease: 'easeInOut',
                        duration: 0.15
                    }
                }
            }}
            {...restProps}
        >
            <ModalBody p={0} data-testid={'modal-body'}>
                {children}
            </ModalBody>
        </ModalContent>
    );
}
