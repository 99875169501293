import { StyleFunctionProps } from '@chakra-ui/theme-tools';

export const Popover = {
    variants: {
        hint: (props: StyleFunctionProps) => {
            const {
                theme: {
                    app: {
                        headerHeight,
                        headerHeightMobile,
                        footerHeight
                    }
                }
            } = props;
            const defaults = {
                content: {
                    top: '-102px',
                    maxH: `calc(100vh - (2 * ${headerHeight}))`,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    _focus: { outline: 'none' }
                },
                body: {
                    overflow: 'auto',
                    padding: '20px 24px 0px 24px',
                    marginBottom: '24px'
                }
            };
            const overrides = {
                popper: {
                    boxShadow: '0px 4px 16px 2px rgba(0, 25, 55, 0.32)',
                    display: 'flex',
                    inset: {
                        base: `${headerHeightMobile} 16px auto auto !important`,
                        md: `${headerHeight} 24px auto auto !important`
                    },
                    position: 'fixed !important',
                    top: {
                        base: `${headerHeightMobile} !important`,
                        md: `${headerHeight} !important`
                    },
                    right: {
                        base: '16px !important',
                        md: '24px !important'
                    },
                    left: 'auto !important',
                    transform: 'none !important',
                    minW: 'auto !important',
                    maxW: '484px',
                    w: {
                        base: 'calc(100% - 32px)',
                        md: 'calc(100% - 48px)'
                    },
                    maxH: {
                        base: `calc(100% - ${headerHeightMobile} - ${footerHeight})`,
                        lg: `calc(100% - ${headerHeight} - ${footerHeight} - 24px)`
                    },
                    overflow: 'hidden',
                    borderRadius: '20px'
                },
                content: { w: '100%' },
                body: {
                    fontSize: '18px',
                    padding: '16px 16px 8px 16px',
                    marginBottom: '8px',
                    overflow: 'auto'
                }
            };

            if (props.size === 'lg') {
                return defaults;
            }

            return overrides;
        }
    }
};
