/* istanbul ignore file */
import {
    Howl, Howler
} from 'howler';
import React, {
    type PropsWithChildren, createContext, useContext, useMemo
} from 'react';

import allStarsSpinFile from '../../../../assets/audio/allStarSpin.mp3';
import completionAYR from '../../../../assets/audio/AYR_Completion.mp3';
import beforeCollectingCoinsFile from '../../../../assets/audio/beforeCollectingCoins.mp3';
import clickFile from '../../../../assets/audio/click.mp3';
import collectCoinsFile from '../../../../assets/audio/collectCoins.mp3';
import congratulationsFile from '../../../../assets/audio/congratulations.mp3';
import correct1StarFile from '../../../../assets/audio/correct1Star.mp3';
import correct2StarFile from '../../../../assets/audio/correct2Star.mp3';
import correct3StarFile from '../../../../assets/audio/correct3Star.mp3';
import correctAnswerScreenFile from '../../../../assets/audio/correctAnswerScreen.mp3';
import correctSoundFile from '../../../../assets/audio/correctSound.mp3';
import inCorrectSoundFile from '../../../../assets/audio/inCorrectSound.mp3';
import scoreResults1StarFile from '../../../../assets/audio/scoreResults1Star.mp3';
import scoreResults2StarFile from '../../../../assets/audio/scoreResults2Star.mp3';
import scoreResults3StarFile from '../../../../assets/audio/scoreResults3Star.mp3';
import selfAssesmentEmojisFile_1 from '../../../../assets/audio/selfAssesmentEmojis_1.mp3';
import selfAssesmentEmojisFile_2 from '../../../../assets/audio/selfAssesmentEmojis_2.mp3';
import selfAssesmentEmojisFile_3 from '../../../../assets/audio/selfAssesmentEmojis_3.mp3';
import selfAssesmentEmojisFile_4 from '../../../../assets/audio/selfAssesmentEmojis_4.mp3';
import selfAssesmentEmojisFile_5 from '../../../../assets/audio/selfAssesmentEmojis_5.mp3';
import transitionScreenLoad from '../../../../assets/audio/transitionScreens.mp3';
import completionUPT from '../../../../assets/audio/UPT_Completion.mp3';

type AudioEffectsContextValue = {
    playCongratulations: (num: number) => void
    playClick: () => void
    correctAnswerScreen: Howl
    beforeCollectingCoins: Howl
    playCorrectStarsAudio: (num: number) => void
    inCorrectSound: Howl
    correctSound: Howl
    playAllStarsSpin: (num: number) => void
    playClickWithCollectCoins: () => void
    playTransitionScreenLoad: () => void
    playSelfAssesmentEmojisAudio: (feedbackType: number) => void
    playCompletionUPT: () => void
    playCompletionAYR: () => void
};
const Context = createContext<AudioEffectsContextValue>(null as never);

export function AudioEffectsProvider ({ children }: PropsWithChildren) {
    //Change global volume.
    Howler.volume(0.5);
    const allStarsSpin = useMemo(() => new Howl({
        src: [allStarsSpinFile],
        preload: true,
        html5: true
    }), []);
    const allStarsSpinClick = useMemo(() => new Howl({
        src: [clickFile],
        onend: () => allStarsSpin.play(),
        preload: true,
        html5: true
    }), [allStarsSpin]);
    const correctSound = useMemo(() => new Howl({
        src: [correctSoundFile],
        preload: true,
        html5: true
    }), []);
    const correctAnswerScreen = useMemo(() => new Howl({
        src: [correctAnswerScreenFile],
        preload: true,
        html5: true
    }), []);
    const inCorrectSound = useMemo(() => new Howl({
        src: [inCorrectSoundFile],
        preload: true,
        html5: true
    }), []);
    const collectCoins = useMemo(() => new Howl({
        src: [collectCoinsFile],
        preload: true,
        html5: true
    }), []);
    const collectCoinsClick = useMemo(() => new Howl({
        src: [clickFile],
        onend: () => collectCoins.play(),
        preload: true,
        html5: true
    }), [collectCoins]);
    const correct1Star = useMemo(() => new Howl({
        src: [correct1StarFile],
        preload: true,
        html5: true
    }), []);
    const correct2Star = useMemo(() => new Howl({
        src: [correct2StarFile],
        preload: true,
        html5: true
    }), []);
    const correct3Star = useMemo(() => new Howl({
        src: [correct3StarFile],
        preload: true,
        html5: true
    }), []);
    const scoreResults1Star = useMemo(() => new Howl({
        src: [scoreResults1StarFile],
        preload: true,
        html5: true
    }), []);
    const scoreResults2Star = useMemo(() => new Howl({
        src: [scoreResults2StarFile],
        preload: true,
        html5: true
    }), []);
    const scoreResults3Star = useMemo(() => new Howl({
        src: [scoreResults3StarFile],
        preload: true,
        html5: true
    }), []);
    const click = useMemo(() => new Howl({
        src: [clickFile],
        preload: true,
        html5: true
    }), []);
    const beforeCollectingCoins= useMemo(() => new Howl({
        src: [beforeCollectingCoinsFile],
        preload: true,
        html5: true
    }), []);
    const playCorrectStarsAudio = (num: number) => {
        const correctAudios: Howl[] = [correct1Star, correct2Star, correct3Star];
        const audio: Howl = correctAudios[num - 1];

        audio.play();
    };
    const playCorrectScoreResultsAudio = (num: number) => {
        const correctAudios: Howl[] = [scoreResults1Star, scoreResults2Star, scoreResults3Star];
        const audio: Howl = correctAudios[num - 1];

        setTimeout(() => {
            audio.play();
        }, 400);
    };
    const selfAssesmentEmojis1 = useMemo(() => new Howl({
        src: [selfAssesmentEmojisFile_1],
        preload: true,
        html5: true
    }), []);
    const selfAssesmentEmojis2 = useMemo(() => new Howl({
        src: [selfAssesmentEmojisFile_2],
        preload: true,
        html5: true
    }), []);
    const selfAssesmentEmojis3 = useMemo(() => new Howl({
        src: [selfAssesmentEmojisFile_3],
        preload: true,
        html5: true
    }), []);
    const selfAssesmentEmojis4 = useMemo(() => new Howl({
        src: [selfAssesmentEmojisFile_4],
        preload: true,
        html5: true
    }), []);
    const selfAssesmentEmojis5 = useMemo(() => new Howl({
        src: [selfAssesmentEmojisFile_5],
        preload: true,
        html5: true
    }), []);
    const playSelfAssesmentEmojisAudio = (feedbackType: number) => {
        const selfAssesmentEmojisAudios: Howl[] = [
            selfAssesmentEmojis1,
            selfAssesmentEmojis2,
            selfAssesmentEmojis3,
            selfAssesmentEmojis4,
            selfAssesmentEmojis5
        ];
        const audio: Howl = selfAssesmentEmojisAudios[feedbackType];

        audio.play();
    };
    const playClickWithCollectCoins = () => {
        collectCoinsClick.play();
    };
    const congratulations = useMemo(() => new Howl({
        src: [congratulationsFile],
        preload: true,
        html5: true
    }), []);
    const playClick = () => {
        click.play();
    };
    const playCongratulations = (num: number) => {
        congratulations.on('end', () => playCorrectScoreResultsAudio(num));
        congratulations.play();
    };
    const playAllStarsSpin = (num: number) => {
        if(num > 0) {
            allStarsSpinClick.play();
        }
    };
    const transitionScreen = useMemo(() => new Howl({
        src: [transitionScreenLoad],
        preload: true,
        html5: true
    }), []);
    const playTransitionScreenLoad = () => {
        transitionScreen.play();
    };
    const completionUPTPage = useMemo(() => new Howl({
        src: [completionUPT],
        preload: true,
        html5: true
    }), []);
    const playCompletionUPT = () => {
        completionUPTPage.play();
    };
    const completionAYRPage = useMemo(() => new Howl({
        src: [completionAYR],
        preload: true,
        html5: true
    }), []);
    const playCompletionAYR = () => {
        completionAYRPage.play();
    };
    const value: AudioEffectsContextValue = {
        correctAnswerScreen,
        beforeCollectingCoins,
        playClick,
        playCorrectStarsAudio,
        correctSound,
        inCorrectSound,
        playCongratulations,
        playAllStarsSpin,
        playClickWithCollectCoins,
        playTransitionScreenLoad,
        playSelfAssesmentEmojisAudio,
        playCompletionUPT,
        playCompletionAYR
    };

    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    );
}

export function useAudioEffectsContext () {
    return useContext(Context);
}

