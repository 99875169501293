import {
    i18n, Messages
} from '@lingui/core';
import {
    Content,
    FunctionArg,
    Octothorpe,
    parse,
    PlainArg,
    Select
} from '@messageformat/parser';
import { en } from 'make-plural/plurals';

import { APP_CONFIG } from '../../config';

export const DEFAULT_LOCALE = 'en-AU';

export const PSEUDO_LOCALE = 'en-PL';

export const DEFAULT_LOCALE_DATA = {
    [DEFAULT_LOCALE]: { plurals: en },
    [PSEUDO_LOCALE]: { plurals: en }
};

type LoadI18NOptions = {
    locale?: string
    shouldLoadLocaleFromCDN?: boolean
};

/**
 * Handle loading of the localisation json data manually or from CDN or locally for development.
 * In the APP_CONFIG this can be based on whether this is a production build.
 */
const loadLocalLingui = async (locale: string) => {
    console.debug('loadI18n() loading from local bundle !!');

    const catalog = await import(`../../../locale/${locale}/messages`);

    dynamicActivate({
        locale,
        messages: catalog.messages
    });
};

export async function loadI18n ({
    locale = DEFAULT_LOCALE, shouldLoadLocaleFromCDN = APP_CONFIG.shouldLoadLocaleFromCDN
}: LoadI18NOptions) {
    if (shouldLoadLocaleFromCDN) {
        console.debug('loadI18n() loading from cdn', locale, shouldLoadLocaleFromCDN);

        try {
            const baseUrl:string = window._env_.LOCALISATION_CDN_BASE_URL;

            if (typeof baseUrl !== 'string' || baseUrl.length === 0) {
                // if we are in production and this doesn't work there is no bundled fallback.
                console.error('loadI18n()', locale, shouldLoadLocaleFromCDN);

                throw new Error('loadI18n() unable to fetch LocalisationCdnBaseUrl from configuration');
            }
            console.debug('loadI18n() LocalisationCdnBaseUrl', baseUrl);

            await loadMessageCatalog({
                locale,
                baseUrl
            });
        } catch {
            await loadLocalLingui(locale);
        }
    } else {
        await loadLocalLingui(locale);
    }
}

type ActivateOptions = {
    locale: string
    messages: Messages
};

/**
 * This is based off the official recommendation in lingui to dynamically load locale data
 * and the existing code snippet in 3p projects in the function loadCatalog.
 *
 * @see https://lingui.js.org/guides/dynamic-loading-catalogs.html
 */
export function dynamicActivate ({
    locale, messages
}: ActivateOptions) {
    i18n.load(locale, messages);
    i18n.loadLocaleData(DEFAULT_LOCALE_DATA);
    i18n.activate(locale);
}

type LoadLocaleDataOptions = {
    locale: string
    baseUrl: string
};

async function loadMessageCatalog (options: LoadLocaleDataOptions) {
    try {
        await activateRemoteLocaleData(options).catch((error: unknown) => tryLoadFallbackLocale(options, error));
    } catch (error) {
        await tryLoadFallbackLocale(options, error);
    }
}

function tryLoadFallbackLocale (options: LoadLocaleDataOptions, sourceError: unknown) {
    const failureMessage = `tryLoadFallbackLocale() - received an error loading ${options.locale} no possible replacement.`;

    if (options.locale !== DEFAULT_LOCALE) {
        try {
            console.debug('tryLoadFallbackLocale() loading fallback due to error', sourceError);

            return activateRemoteLocaleData({
                ...options,
                locale: DEFAULT_LOCALE
            }).catch((error: unknown) => {
                console.error(failureMessage, error);

                throw new Error(failureMessage);
            });
        } catch (error) {
            const message = `tryLoadFallbackLocale() - the fallback to ${DEFAULT_LOCALE} failed with error...`;

            console.error(message, error);
            throw new Error(message);
        }
    } else {
        throw new Error(failureMessage);
    }
}

const isString = (s: unknown) => {
    return typeof s === 'string';
};

type Token = Content | PlainArg | FunctionArg | Select | Octothorpe;

function processTokens (tokens: Token[]) {
    if (!tokens.filter((token) => {
        return !isString(token);
    }).length) {
        return tokens.join('');
    }

    return tokens.map((token) => {
        if (token.type === 'content') {
            return token.value;
        } else if (token.type === 'octothorpe') {
            return '#';
        } else if (token.type === 'argument') {
            return [token.arg];
        } else if (token.type === 'function') {
            const param = token.param;

            return [token.arg, token.key, Array.isArray(param) ? param[0] : param].filter(Boolean);
        }

        const formatProps: Record<string, unknown> = {};

        token.cases.forEach((item) => {
            const parsedToken = processTokens(item.tokens);

            formatProps[item.key.replace('=', '')] = Array.isArray(parsedToken) && parsedToken.length === 1 ? parsedToken[0] : parsedToken;
        });

        return [token.arg, token.type, { ...formatProps }];
    });
}

export function compile (message: string) {
    try {
        const parsedToken = processTokens(parse(message));

        return Array.isArray(parsedToken) && parsedToken.length === 1 && typeof parsedToken[0] === 'string' ? parsedToken[0] : parsedToken;
    } catch (e) {
        console.error('Message cannot be parsed due to syntax errors: '.concat(message));

        return message;
    }
}

/**
 * Load a message catalog from the CDN with the given baseUrl and locale.
 *
 * The baseUrl is typically given through a 3p config eg key "LocalisationCdnBaseUrl".
 * The data is provided through integration between app.lokalise & webhooks with bitbucket html-locales.
 *
 * @see https://app.lokalise.com/projects
 * @see https://bitbucket.org/mathletics/html-locales
 */
async function activateRemoteLocaleData ({
    baseUrl, locale
}: LoadLocaleDataOptions): Promise<Messages> {
    // TODO replace with 3p-resource?
    const response = await fetch(`${baseUrl}/${locale}.json`);
    const messages: Messages = await response.json();

    console.debug('loadCatalog() - translations =', JSON.stringify(messages, null, 2));

    const compiledMessages: Messages = {};

    Object.keys(messages).forEach((message) => {
        const translatedMessage = messages[message];

        if (typeof translatedMessage === 'string') {
            const compiledMessage = compile(translatedMessage);

            compiledMessages[message] =  compiledMessage as Messages[number];
        }
    });

    dynamicActivate({
        locale,
        messages: compiledMessages
    });

    return messages;
}

/**
 * Get the current locale based on the URL search params eg &locale=en-AU
 * or fallback to the DEFAULT_LOCALE.
 */
export function getURLLocale (): string {
    return new URLSearchParams(window?.location?.search).get('locale') || DEFAULT_LOCALE;
}
