import { STUDENT_STATES } from '../../helpers/enums';

export const getNextStudentState = (studentState: STUDENT_STATES) => {
    switch (studentState) {
    case STUDENT_STATES.intro:
        return STUDENT_STATES.fluencyIntro;

    case STUDENT_STATES.resume:
    case STUDENT_STATES.progress:
    case STUDENT_STATES.fluencyIntro:
        return STUDENT_STATES.question;

    case STUDENT_STATES.question:
        return STUDENT_STATES.summary;

    default:
        return STUDENT_STATES.progress;
    }
};
