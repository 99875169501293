import {
    Button, Image, useDisclosure
} from '@chakra-ui/react';
import { deviceType } from 'detect-it';
import React, {
    useEffect, useState
} from 'react';
import { useSelector } from 'react-redux';

import zoomIn from '../../../assets/images/zoom-in.svg';
import { AppState } from '../../app/store/store.types';
import { useAudioEffectsContext } from '../../components/audio/provider/audio-effects-provider';
import { ZoomOutModal } from '../../components/modals/zoom-out/zoom-out';

const SHOW_MODAL_DELAY = 3000;
const SHOW_MODAL_LIMIT = 2;
const MIN_WIDTH = 1400;
const SESSION_MODAL_SHOWN_KEY = 'zoomOutSessionModalShown';
const MODAL_SHOWN_COUNT_KEY = 'zoomOutModalShownCount';

export const ZoomOut = () => {
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const { progress } = activityState;
    const { ...chakraModalProps } = useDisclosure();
    const { playClick } = useAudioEffectsContext();
    const isIntroOrFirstQuestion = progress?.stepsCompleted === 0 && progress.attempt === 1;
    const isCorrectDevice = deviceType === 'mouseOnly';
    const hasShownSessionModal = Boolean(sessionStorage.getItem(SESSION_MODAL_SHOWN_KEY));
    const modalShownCount = Number(localStorage.getItem(MODAL_SHOWN_COUNT_KEY) ?? 0);
    const exceededModalShownCount = modalShownCount >= SHOW_MODAL_LIMIT;
    const shouldZoomOut = () => {
        let result = false;

        if (!isCorrectDevice || innerWidth > MIN_WIDTH) {
            return result;
        }
        // inner/outerWidth is the same in FF for most zoom levels
        if (navigator.userAgent.includes('Firefox')) {
            if (devicePixelRatio > 1) {
                const HiDPIsBelow100P = [
                    // retina
                    1.33, 1.58, 1.82,
                    // windows scale
                    // 125% scale at 90% zoom
                    1.13
                    // 150% scale at 90% zoom = 125% scale at 110% zoom = 1.3636363636363635
                    // 150% scale at 80% zoom = 100% scale at 120% zoom = 1.2
                ];

                if (devicePixelRatio < 2 && !HiDPIsBelow100P.includes(Math.round(devicePixelRatio * 100) / 100)) {
                    result = true;
                } else if (devicePixelRatio >= 2) {
                    result = true;
                }
            }
            // dpr for safari unchanged on zoom
        } else if (navigator.userAgent.includes('Safari')) {
            if (outerWidth >= innerWidth && devicePixelRatio > 1) {
                result = true;
            } else if (outerWidth > innerWidth && devicePixelRatio === 1) {
                result = true;
            }
        } else if (outerWidth >= innerWidth && devicePixelRatio > 1) {
            result = true;
        }

        return result;
    };
    const getShowButtonState = () => {
        return shouldZoomOut() && exceededModalShownCount && !hasShownSessionModal;
    };
    const [showButton, setShowButton] = useState(getShowButtonState);
    const onClose = () => {
        chakraModalProps.onClose();
        setShowButton(getShowButtonState());
    };
    const showModal = () => {
        chakraModalProps.onOpen();
        setShowButton(false);
    };

    useEffect(() => {
        let timeoutID: ReturnType<typeof setTimeout>;

        const handleZoomChange = () => {
            setShowButton(getShowButtonState());
        };

        if (isIntroOrFirstQuestion
            && !exceededModalShownCount
            && !hasShownSessionModal
            && shouldZoomOut()
        ) {
            timeoutID = setTimeout(() => {
                showModal();
                sessionStorage.setItem(SESSION_MODAL_SHOWN_KEY, 'true');
                localStorage.setItem(MODAL_SHOWN_COUNT_KEY, (modalShownCount + 1).toString());
            }, SHOW_MODAL_DELAY);
        }
        addEventListener('resize', handleZoomChange);

        return () => {
            removeEventListener('resize', handleZoomChange);
            clearTimeout(timeoutID);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {showButton && isIntroOrFirstQuestion &&
                <Button
                    variant={'round-btn'}
                    data-testid={'zoom-button'}
                    onClick={() => {
                        playClick();
                        showModal();
                    }}
                    tabIndex={-1}
                >
                    <Image src={zoomIn} />
                </Button>
            }
            <ZoomOutModal {...chakraModalProps} onClose={onClose} />
        </>
    );
};
