import React, {
    useEffect, useState
} from 'react';

import AppImage from '../../components/app-image/app-image';
import { Header } from '../../components/header/header';
import { QuestionContainer } from '../../containers/question-container/question-container';
import { AnswerValidationHandlerProps } from '../../containers/question-container/question-container.types';
import { STUDENT_STATES } from '../../helpers/enums';
import {
    ProgressMountainStyled, QuestionConsoleStyled
} from '../question-console/question-console.styled';
import { QuestionSetProps } from './single-variant.types';

export const QuestionSet = ({
    studentState,
    validation,
    isWorkedSolutionActivated,
    validateSingleVariant
}: QuestionSetProps) => {
    const [minHeight, setMinHeight] = useState(0);

    useEffect(() => {
        // wait for animation to finish before detecting height
        setTimeout(() => {
            if (isWorkedSolutionActivated) {
                const workedSolution = document.getElementsByClassName('worked-solution-wrapper')[0] as HTMLElement;

                setMinHeight(workedSolution?.offsetHeight + 217);
            } else {
                const questionContent = document.getElementsByClassName('question-content')[0] as HTMLElement;

                setMinHeight(questionContent?.offsetHeight + 280);
            }
        }, 1700);
    }, [isWorkedSolutionActivated, validation]);

    return (
        <QuestionConsoleStyled
            showQuestion={studentState === STUDENT_STATES.question}
            className={'root-container single-variant-preview-container'}
            minHeight={minHeight}
        >
            <Header showReadAloudBtn showExitBtn />
            <div className={'content'}>
                <AppImage imageType={'img'} iconType={'leftMountain'} className={'left-mountain'} />
                <ProgressMountainStyled>
                    <AppImage imageType={'img'} iconType={'progressMountain'} className={'progress-mountain'} />
                </ProgressMountainStyled>
                <QuestionContainer
                    answerValidationHandler={validateSingleVariant as AnswerValidationHandlerProps}
                    scrollableContainer={document.getElementsByClassName('application')[0]}
                />
                <AppImage imageType={'img'} iconType={'rightFgMountain'} className={'right-mountain'} />
            </div>
        </QuestionConsoleStyled>
    );
};
