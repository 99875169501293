import React, {
    PropsWithChildren, useMemo
} from 'react';
import { Provider } from 'react-redux';
import {
    applyMiddleware, compose, createStore
} from 'redux';
import thunk from 'redux-thunk';

import { APP_CONFIG } from '../../config';
import { rootReducer } from './root-reducer';

/**
 * The main Redux Context Provider for the application.
 */
export function AppReduxProvider ({ children }: PropsWithChildren<unknown>) {
    // We only want to create the store once and keep it memoized
    const store = useMemo(configureStore, []);

    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
}

/**
 * Standard helper to create a new Redux store.
 *
 * @see https://redux.js.org/usage/configuring-your-store#the-solution-configurestore
 */
function configureStore () {
    const composeEnhancers: unknown =
        typeof window === 'object' &&
        // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
            // @ts-ignore
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                name: APP_CONFIG.appName,
                trace: process.env.NODE_ENV === 'development'
            }) : compose;

    let enhancer;

    if (typeof composeEnhancers === 'function') {
        enhancer = composeEnhancers(
            applyMiddleware(
                // Add context here for dependency injection to thunks, eg history.push
                // @see https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
                thunk.withExtraArgument({})
            )
        );
    }

    return createStore(
        rootReducer,
        enhancer
    );
}
