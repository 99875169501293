import {
    Box, HStack, SystemStyleObject
} from '@chakra-ui/react';
import React from 'react';

import { MAX_ATTEMPTS } from '../../../../../../helpers/constants';
import { getStarType } from '../../utils/util';
import { ProgressStar } from './progress-star';
import { ProgressStarRatingProps } from './star-rating.types';

export const starStyle: SystemStyleObject = {
    width: {
        base: '48px',
        break1270: '64px'
    },
    h: {
        base: '48px',
        break1270: '64px'
    }
};

export const ProgressStarRating = ({
    totalStars, playStarNum
}: ProgressStarRatingProps) => {
    const result = [];
    const wholeStars = MAX_ATTEMPTS - 1;

    for (let i = 0; i <= wholeStars; ++i) {
        const num = i + 1;
        const starType = getStarType({
            totalStars,
            index: i
        });

        result.push(
            <Box
                sx={starStyle}
                key={`greyStar-${i}`}
                data-testid={`whole-star-${i}`}
            >
                <ProgressStar
                    num={num}
                    starType={starType}
                    playStarNum={playStarNum}
                />
            </Box>
        );
    }

    return (
        <HStack
            data-testid={'ProgressStarRating H stack'}
            spacing={{
                base: '40px',
                md: '30px',
                break1270: '26px'
            }}
            h={'100%'}
            boxSizing={'border-box'}
            alignItems={'center'}
        >
            {result}
        </HStack>
    );
};
