import { Content } from '@3plearning/question-components-library';

export type QuestionSetProps = {
    questName: string
    keypadPreset: number
    questions: Content[]
};

export type NavButtonsProps = {
    navigate: (type: number) => void
};

export type QuestionHeaderProps = {
    questionNumber: number
    totalQuestions: number
    navigate: (type: number) => void
};

export enum Nav {
    forward,
    backward
}
