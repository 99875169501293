import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppState } from '../app/store/store.types';
import { isDefined } from '../helpers/utils';
import { ROUTES } from '../routes/routes';

export const useRouteChecker = (): { questionSetType: AppState['activityState']['questionSetType'] } => {
    const history = useHistory();
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const {
        questName, progress, questionSetType
    } = activityState;

    useEffect(() => {
        if (!isDefined(questName) && !isDefined(progress)) {
            history.replace(ROUTES.appInitialisation);
        }
    }, [history, progress, questName]);

    return { questionSetType };
};
