// existing app sass variables
// This was generated from existing scss https://sass-export.plentycode.com:

export type ThemeVariables = typeof app;

export const app = {
    appBgColour: '#0BB3F4',
    questionConsoleBgColour: '#3c2491',
    font: '#525352',
    fontGrey01: '#5C6C7F',
    fontPrimary: '#0063A7',
    fontLight: '#278bcb',
    white01: '#f3f3f3',
    white02: '#d8d8d8',
    white03: '#E3E9F7',
    whiteAlpha30: '#FFFFFF4D',
    whiteAlpha800: '#FFFFFFCC',
    blue: '#00ABE8',
    darkBlue: '#0071c0',
    primary50: '#EEF8FE',
    primary100: '#D0F1FD',
    primary200: '#BCEAFD',
    primary300: '#99DDF6',
    primary600: '#007ACC',
    primary700: '#0063A7',
    primary900: '#00336F',
    error600: '#E22837',
    error300: '#F6BFC3',
    error700: '#C51B29',
    neutral50: '#F4F8FA',
    neutral100: '#EDF1F4',
    neutral200: '#DBE0E6',
    neutral300: '#CBD3DC',
    neutral600: '#5C6C7F',
    neutral900: '#001937',
    grey01: '#bcbcbc',
    grey02: '#ADB9C7',
    grey03: '#DBE0E6',
    grey04: '#33475F',
    grey05: '#808C9B',
    grey06: '#C4C4C4',
    blue01: '#0DB5F6',
    blue02: '#007ACC',
    blue03: '#001937',
    blue04: '#73D7FC',
    blue05: '#01AEEE',
    blue06: '#4DC4EF',
    red01: '#f63e6b',
    red02: '#CC461B',
    red03: '#EB6973',
    red04: '#C51B29',
    red05: '#FCB290',
    red06: '#FCEAEB',
    red07: '#BA3409',
    orange01: '#FA7E46',
    orange02: '#AB5C00',
    orange03: '#5F0008',
    green01: '#29B539',
    green02: '#58D25B',
    green03: '#EAF8EB',
    green04: '#148235',
    green05: '#003C18',
    green50: '#F6FEF4',
    green200: '#BCEDBD',
    green300: '#9BE49D',
    green600: '#1D9B37',
    yellow01: '#FFCF00',
    yellow02: '#FFFAEE',

    break680: '680px',
    phoneLandscape: '620px',
    breakTabletPortrait: '768px',
    breakTabletPortraitPhone: '767px',
    breakTabletLandscape: '1024px',
    breakPoint: '950px',
    transitionBreakPoint: '850px',
    transitionBreakPointLarge: '1350px',
    lg: '992px',
    xl: '1200px',
    break1270: '1270px',
    headerHeight: '90px',
    headerHeightMobile: '100px',
    footerHeight: '72px',
    questionHeaderHeight: '46px',
    questionHeaderHeightCollapsed: '64px',
    questionBodyPadding: '24px',
    questionBodyPaddingMobile: '20px',
    questionNumberWidth: '32px',
    maxContentWidth: 'min(100% - 24px, 1270px)',
    minContentWidth: '960px',
    questionPanelMaxWidth: '910px',
    questionPanelMinWidth: '672px',
    progressPanelWidth: '266px',
    progressPanelHeight: '558px',
    progressPanelLeftMargin: '32px',
    rightBkWidth: '1368px',
    borderRadius: '40px',

    guideWidth: '183px',
    guideHeight: '232px',
    guideWidthAndSpacer: '209px',

    guideWidthLandscape: '152px',
    guideHeightLandscape: '192px',
    guideWidthLandscapeAndSpacer: '182px',

    guideWidthPortrait: '164px',
    guideHeightPortrait: '120px',

    guideWidthPortraitPhone: '104px',
    guideHeightPortraitPhone: '76px',

    messageBoxWidth: '648px',
    messageBoxWidthLandscape: '552px',
    messageBoxWidthPortrait: '720px',

    messageBoxSummaryWidth: '1016px',

    stickyHintContainerHeight: '51px',
    testsStageMaxWidth: '967px',
    questionContainerMargin: '32px'
};
