import {
    useMediaQuery,
    useTheme
} from '@chakra-ui/react';
import {
    isMobile,
    isTablet
} from 'react-device-detect';
import useWindowOrientation from 'use-window-orientation';

export const useWithMobile = () => {
    const theme = useTheme();
    const [breakMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const { landscape } = useWindowOrientation();

    return {
        isMobile,
        breakMobile,
        landscape,
        isTablet
    };
};
