import {
    Box, Divider, Text
} from '@chakra-ui/react';
import React from 'react';

import { lokalisation } from '../../../../helpers/lokalisation';
import { DiagnosticIntroProps } from './diagnostics-intro.types';

export const DiagnosticsIntro: React.FC<DiagnosticIntroProps> = ({
    firstName,
    activityName,
    maximumAttemptExceeded
}) => {
    const {
        introTxt,
        messageBoxTxt: {
            questions,
            totalPoints
        },
        diagnosticIntroTxt
    } = lokalisation();

    return (
        <Box className={'message-content'}>
            <Box className={'message-body'}>
                <Text
                    fontSize={{
                        base: '24px',
                        breakTabletLandscape: '30px'
                    }}
                    color={'neutral.900'}
                    lineHeight={{
                        base: '33.6px',
                        breakTabletLandscape: '39px'
                    }}
                    letterSpacing={{
                        base: '-0.2px',
                        breakTabletLandscape: '-0.8px'
                    }}
                >
                    <strong>{introTxt.hi} {firstName},</strong> {introTxt.diagnosticGoal(maximumAttemptExceeded)}
                </Text>
                <Text
                    fontSize={{
                        base: '24px',
                        breakTabletLandscape: '30px'
                    }}
                    color={'primary.600'}
                    lineHeight={{
                        base: '31.2px',
                        md: '33.6px',
                        breakTabletLandscape: '39px'
                    }}
                    letterSpacing={{
                        base: '-0.2px',
                        breakTabletLandscape: '-0.8px'
                    }}
                    mt={'32px'}
                >{activityName}</Text>
                <Box className={'milestone-summary'} mb={'16px !important'}>
                    <div className={'num-stack'}>
                        <div className={'num-stack_content'} data-testid={'questions'}>
                            <div className={'num-label'}>10</div>
                            <div className={'num-description'}>{questions}</div>
                        </div>
                    </div>
                    <Divider
                        borderColor={'neutral.100'}
                        h={'calc(100% - 2 * 20px)'}
                        my={'20px'}
                        opacity={1}
                        orientation={'vertical'}
                    />
                    <div className={'num-stack'}>
                        <div className={'num-stack_content'} data-testid={'total-points'}>
                            <div className={'num-label'} data-testid={'total-points-value'}>{maximumAttemptExceeded ? 0 : 100}</div>
                            <div className={'num-description'}>{totalPoints}</div>
                        </div>
                    </div>
                </Box>
                {maximumAttemptExceeded &&
                    <Text
                        color={'primary.600'}
                        mb={4}
                        fontSize={20}
                        lineHeight={1.4}
                    >{diagnosticIntroTxt.noPointsEarn}</Text>
                }
            </Box>
        </Box>
    );
};
