import {
    Box, Flex, useTheme
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { QuestionSetType } from '../../../src/helpers/enums';
import { AppThemeType } from '../../../src/styles/theme/app-theme';
import { AppState } from '../../app/store/store.types';
import { Header } from '../../components/header/header';
import { QuestionContainer } from '../../containers/question-container/question-container';
import { AnswerValidationHandlerProps } from '../../containers/question-container/question-container.types';
import backgroundImg from '../diagnostics/assets/AYR_Background.svg';
import unitTestBackgroundImg from '../diagnostics/assets/UPT_Background.svg';
import { DiagnosticsProps } from './single-variant.types';

export const Diagnostics = ({ validateSingleVariant }: DiagnosticsProps) => {
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const theme: AppThemeType = useTheme();
    const scrollableContainer = useRef<HTMLDivElement>(null);

    return (
        <Box
            bgImage={`url(${activityState.questionSetType === QuestionSetType.diagnostics ? backgroundImg : unitTestBackgroundImg})`}
            bgRepeat={'no-repeat'}
            bgSize={'cover'}
            w={'100vw'}
            h={'100%'}
            boxSizing={'border-box'}
            overflow={'hidden'}
            ref={scrollableContainer}
            className={'root-container single-variant-preview-tests-container'}
        >
            <Flex
                direction={'column'}
                w={'100vw'}
                h={'100%'}
            >
                <Box>
                    <Header showReadAloudBtn showExitBtn />
                </Box>
                <Box
                    maxW={`calc(${theme.app.testsStageMaxWidth} + (2 * ${theme.app.questionContainerMargin}))`}
                    mx={'auto'}
                    position={'relative'}
                    flexGrow={1}
                    w={'100%'}
                    h={{
                        base: 'auto',
                        lg: 'calc(100% - 100px)'
                    }}
                >
                    <QuestionContainer
                        answerValidationHandler={validateSingleVariant as AnswerValidationHandlerProps}
                        scrollableContainer={scrollableContainer.current}
                    />
                </Box>
            </Flex>
        </Box>
    );
};
