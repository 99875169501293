import React from 'react';

import { lokalisation } from '../../helpers/lokalisation';
import { CorrectAnswerContent } from './correct-answer-content';
import {
    CorrectAnswerBody,
    CorrectAnswerContentWrapper,
    CorrectAnswerGuide,
    CorrectAnswerHeader,
    CorrectAnswerWrapper
} from './correct-answer.styled';
import { CorrectAnswerProps } from './correct-answer.types';

export const CorrectAnswer: React.FC<CorrectAnswerProps> = ({
    question, validation
}) => {
    const { labelTxt: { correctAnswerHeader } } = lokalisation();

    return (
        <CorrectAnswerWrapper
            data-testid={'correct-answer-wrapper'}
            className={'correct-answer-wrapper'}
        >
            <CorrectAnswerGuide imageType={'img'} iconType={'activityGuide'} />
            <CorrectAnswerBody>
                <CorrectAnswerHeader>{correctAnswerHeader}</CorrectAnswerHeader>
                <CorrectAnswerContentWrapper>
                    <CorrectAnswerContent question={question} validation={validation} />
                </CorrectAnswerContentWrapper>
            </CorrectAnswerBody>
        </CorrectAnswerWrapper>
    );
};
