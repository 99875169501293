import React, {
    PropsWithChildren, useEffect
} from 'react';
import { event } from 'react-ga';
import { useDispatch } from 'react-redux';
import {
    BrowserRouter, Route, Switch
} from 'react-router-dom';

import { useAudioEffectsContext } from '../components/audio/provider/audio-effects-provider';
import { loadReadAloudLocale } from '../containers/question-container/store/question-actions';
import { getQueryParam } from '../helpers/utils';
import useGoogleAnalytics from '../hooks/use-google-analytics';
import Intercept3pResourcesError from '../resources/intercept-3p-rescources-error';
import { AppInitialisation } from './app-initialisation/app-initialsation';
import { Diagnostics } from './diagnostics/diagnostics';
import { MilestoneTransition } from './milestone-transition/milestone-transition';
import { Preview } from './preview/preview';
import { QuestionConsole } from './question-console/question-console';
import { ROUTES } from './routes';
import { SingleVariant } from './single-variant/single-variant';

// When executing on demo, QA or production, the host app will inject a property
// called _env_ into the global window variable.  This will provide the
// base name of the app.  Most of the time that will be an empty string, but in
// case it is not, we will extract it and pass it to the router, below.
const BASE_NAME = window?._env_?.appConfig?.BaseName || '';

export function AppRoutes ({ children }: PropsWithChildren<unknown>) {
    const dispatch = useDispatch();
    const { playClick } = useAudioEffectsContext();
    const gtmInitialised = useGoogleAnalytics();

    useEffect(() => {
        if (gtmInitialised) {
            event({
                category: 'Questicon system new',
                action: 'Questicon Categories',
                label: 'Questicon Categories loaded'
            });
        }
        Intercept3pResourcesError.addResourceResponseErrorInterceptor(dispatch);
    // eslint-disable-next-line
    },[]);

    useEffect(() => {
        const defaultReadAloudLocale = getQueryParam('locale') || 'en-AU';

        // This should be in the header but <Header /> is a child of each Route so it renders multiple times throughout the app
        dispatch(loadReadAloudLocale(defaultReadAloudLocale as string));
    }, [dispatch]);

    useEffect(() => {
        window.addEventListener('playClick', playClick);

        return () => {
            window.removeEventListener('playClick', playClick);
        };
    }, [playClick]);

    return (
        <BrowserRouter basename={BASE_NAME}>
            <Switch>
                <Route
                    exact={true}
                    path={ROUTES.appInitialisation}
                    component={AppInitialisation}
                />
                <Route
                    exact={true}
                    path={ROUTES.questionConsole}
                    component={QuestionConsole}
                />
                <Route
                    exact={true}
                    path={`${ROUTES.milestoneTransition}/:type`}
                    component={MilestoneTransition}
                />
                <Route
                    exact={true}
                    path={ROUTES.diagnostics}
                    component={Diagnostics}
                />
                <Route
                    exact={true}
                    path={ROUTES.singleVariant}
                    component={SingleVariant}
                />
                <Route
                    exact={true}
                    path={ROUTES.preview}
                    component={Preview}
                />
            </Switch>

            {children}
        </BrowserRouter>
    );
}
